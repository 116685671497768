import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import { Styles } from 'components/Tools';
import React from 'react';
import { useThunkDispatch, useTypedSelector } from 'hooks';
import { getDevice } from '../../redux/actions/users.actions';
import { DateTime } from 'luxon';

export function DeviceDetailsUser({ user }: { user: string }) {
  const classes = Styles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const dispatch = useThunkDispatch();
  const users = useTypedSelector((u) => u.users);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(getDevice(user));
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <div
        className={classes.paper}
        style={{
          paddingTop: '0px',
          paddingBottom: '0px',
        }}
      >
        <Button
          aria-describedby={id}
          variant="contained"
          color="primary"
          onClick={handleClick}
        >
          +
        </Button>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper className={classes.paper}>
          <div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Paper className={classes.paper} style={{ color: 'blue' }}>
                  Número de teléfono
                </Paper>
                <Paper className={classes.paper}>{users.userDevice?.device.number}</Paper>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Paper className={classes.paper} style={{ color: 'blue' }}>
                  Dispositivo
                </Paper>
                <Paper className={classes.paper}>{users.userDevice?.device.imei}</Paper>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Paper className={classes.paper} style={{ color: 'blue' }}>
                  Ultima conexión
                </Paper>
                <Paper className={classes.paper}>
                  {DateTime.fromISO(
                    users.userDevice?.startDate ? users.userDevice?.startDate : '',
                  ).toISODate()}
                </Paper>
              </div>
            </div>
          </div>
        </Paper>
      </Popover>
    </div>
  );
}
