export const WorkshopTypesList = [
  { number: 'Created', color: 'white', type: 'Taller', fix: 'inerit' },
  { number: 'Migrated', color: 'orange', type: 'Taller', fix: 'white' },
  { number: 'Profiled', color: 'gray', type: 'Taller Perfilado', fix: 'white' },
  {
    number: 'ProfiledCompleted',
    color: 'black',
    type: 'Taller Perfilado Completo',
    fix: 'white',
  },
  { number: 'TaCreated', color: 'red', type: 'TA', fix: 'white' },
  { number: 'TaProfiled', color: 'yellow', type: 'TA Perfilado', fix: 'inerit' },
  {
    number: 'TaProfiledCompleted',
    color: 'green',
    type: 'TA Perfilado Completo',
    fix: 'white',
  },
];
