import { Action } from 'redux';
import { AppThunk } from 'redux/store';

export const RESET_STORE = 'RESET_STORE';

export const resetStore = (): AppThunk<Action<typeof RESET_STORE>> => {
  return async (dispatch) => {
    dispatch({ type: RESET_STORE, isLoading: true });
  };
};
