import { Action } from 'redux';
import { Municipio, Department } from 'types/api/responses';
import { UpdateAction } from './update.action';

export enum DepartmentActions {
  departmentUpdate = 'department/update',
  departmentSetValue = 'department/set',
  departmentChange = 'department/change',
  departmentGet = 'department/get',
}

export type DepartmentUpdate = UpdateAction<DepartmentActions.departmentUpdate> & {
  departmentId: string;
};

export type DepartmentSet = {
  departmentId: string;
  municipios: Municipio[];
} & Action<DepartmentActions.departmentSetValue>;

export type DepartmentChange = {
  departmentId: string;
} & Action<DepartmentActions.departmentChange>;

export type DepartmentGet = {
  departmentId: string;
  departments: Department[];
} & Action<DepartmentActions.departmentGet>;

export type DepartmentAction =
  | DepartmentUpdate
  | DepartmentSet
  | DepartmentChange
  | DepartmentGet;
