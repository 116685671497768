import { ApiData, ApiMetaData } from 'types/api';
import { AppThunk } from 'redux/store';
import { endpoint } from 'api/endpoints';
import { HistoryHandler } from 'utils/history.handler';
import { isSuccessResult } from 'utils/guards';
import { LocalStorageHandler } from 'utils/Local.storage.handler';
import { Device } from 'types/api/responses';
import { Routes } from 'config/routes';
import { apiGet, apiPatch, apiPost } from 'api/karthus.client';
import { DevicesAction, DevicesActions } from 'types/redux/actions/devices.action';
import { FilterDevice } from 'types/redux/state/devices.state';

type DevicesPaginationResponce = {
  size: number;
  page: number;
};

export const allDevices = (
  params: {
    page: number;
    size: number;
  },
  filter: FilterDevice,
): AppThunk<DevicesAction> => {
  return async (dispatch) => {
    dispatch({ type: DevicesActions.devicesUpdate, isLoading: true });
    const response = await apiGet<ApiMetaData<Device[]>>(endpoint.devices, {
      ...params,
      filter,
    });
    if (isSuccessResult(response.result)) {
      dispatch({
        type: DevicesActions.devicesSetValue,
        devices: {
          devices: response.result.data,
          meta: response.result.meta,
        },
      });
      dispatch({ type: DevicesActions.devicesSetFilter, filter: filter });
    } else {
      dispatch({
        type: DevicesActions.devicesUpdate,
        isLoading: false,
        error: response.result[0],
      });
    }
  };
};

export const newDevice = (
  params: Device,
  handleSaveUser?: () => void,
): AppThunk<DevicesAction> => {
  return async (dispatch) => {
    dispatch({ type: DevicesActions.devicesUpdate, isLoading: true });
    const response = await apiPost<ApiData<Device>>(endpoint.devices, params);
    if (isSuccessResult(response.result)) {
      dispatch({
        type: DevicesActions.devicesNewValue,
        newDevice: response.result.data,
      });
      if (handleSaveUser) {
        handleSaveUser();
      }
    } else {
      dispatch({
        type: DevicesActions.devicesUpdate,
        isLoading: false,
        error: response.result[0],
      });
    }
  };
};

export const updateDevice = (params: Device): AppThunk<DevicesAction> => {
  return async (dispatch) => {
    dispatch({ type: DevicesActions.devicesUpdate, isLoading: true });
    const response = await apiPatch<ApiData<Device>>(endpoint.devices, params);
    if (isSuccessResult(response.result)) {
      dispatch({
        type: DevicesActions.devicesNewValueUpdate,
        newDevice: response.result.data,
      });
    } else {
      dispatch({
        type: DevicesActions.devicesUpdate,
        isLoading: false,
        error: response.result[0],
      });
    }
  };
};

export const resetNewDevice = (): AppThunk<DevicesAction> => {
  return async (dispatch) => {
    dispatch({ type: DevicesActions.devicesNewValue, newDevice: null });
  };
};
