import { AppThunk } from 'redux/store';
import { endpoint } from 'api/endpoints';
import { FilterWorkshoptype } from 'types/redux/state/workshopTypes.state';
import { isSuccessResult } from 'utils/guards';
import { WorkshopType } from 'types/api/responses';
import { ApiData, ApiMetaData } from 'types/api';
import { apiGet, apiPatch, apiPost } from 'api/karthus.client';
import {
  WorkshopTypesAction,
  WorkshopTypesActions,
} from 'types/redux/actions/workshopTypes.action';

type WorkshopTypesPaginationResponce = {
  size: number;
  page: number;
};

export const allWorkshopTypes = (
  params: WorkshopTypesPaginationResponce,
  filter: FilterWorkshoptype,
): AppThunk<WorkshopTypesAction> => {
  return async (dispatch) => {
    dispatch({ type: WorkshopTypesActions.workshopTypesUpdate, isLoading: true });
    const response = await apiGet<ApiMetaData<WorkshopType[]>>(endpoint.workshopTypes, {
      ...params,
      filter,
    });
    if (isSuccessResult(response.result)) {
      dispatch({
        type: WorkshopTypesActions.workshopTypesSetValue,
        workshopTypes: {
          workshopTypes: response.result.data,
          meta: response.result.meta,
        },
      });
      dispatch({ type: WorkshopTypesActions.workshopTypesSetFilter, filter: filter });
    } else {
      dispatch({
        type: WorkshopTypesActions.workshopTypesUpdate,
        isLoading: false,
        error: response.result[0],
      });
    }
  };
};

export const newWorkshopType = (
  workshopType: WorkshopType,
  after?: () => void,
): AppThunk<WorkshopTypesAction> => {
  return async (dispatch) => {
    dispatch({ type: WorkshopTypesActions.workshopTypesUpdate, isLoading: true });
    const response = await apiPost<ApiData<WorkshopType>>(endpoint.workshopTypes, {
      ...workshopType,
    });
    if (isSuccessResult(response.result)) {
      dispatch({
        type: WorkshopTypesActions.workshopTypeNewValue,
        workshopType: response.result.data,
      });
      if (after) {
        after();
      }
    } else {
      dispatch({
        type: WorkshopTypesActions.workshopTypesUpdate,
        isLoading: false,
        error: response.result[0],
      });
    }
  };
};

export const updateWorkshopType = (
  workshopType: WorkshopType,
): AppThunk<WorkshopTypesAction> => {
  return async (dispatch) => {
    dispatch({ type: WorkshopTypesActions.workshopTypesUpdate, isLoading: true });
    const response = await apiPatch<ApiData<WorkshopType>>(endpoint.workshopTypes, {
      ...workshopType,
    });
    if (isSuccessResult(response.result)) {
      dispatch({
        type: WorkshopTypesActions.workshopTypeNewValueUpdate,
        workshopType: response.result.data,
      });
    } else {
      dispatch({
        type: WorkshopTypesActions.workshopTypesUpdate,
        isLoading: false,
        error: response.result[0],
      });
    }
  };
};

export const resetNewWorkshopType = (): AppThunk<WorkshopTypesAction> => {
  return async (dispatch) => {
    dispatch({ type: WorkshopTypesActions.workshopTypeNewValue, workshopType: null });
  };
};
