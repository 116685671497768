import { Action } from 'redux';
import { Metadata, Users, NewUserSuccess, Office, UserDevice } from 'types/api/responses';

export enum UsersActions {
  usersUpdate = 'user/users/update',
  usersSetValue = 'user/users/set',
  usersNewValue = 'user/users/newUser',
  usersSetOffice = 'user/office/set',
  usersSetFilter = 'user/users/filter',
  usersNewValueUpdate = 'user/users/newUserUpdate',
  userSetDevice = 'user/device/setDevice',
  usersStatusUpdate = 'user/users/StatusUserUpdate',
}

type UsersUpdate = {
  isLoading: boolean;
  error?: Error;
} & Action<typeof UsersActions.usersUpdate>;

type UsersSetValue = {
  users: {
    users: Users[];
    meta: Metadata;
  };
} & Action<typeof UsersActions.usersSetValue>;

type UsersSetOffice = {
  branchOffices: {
    offices: Office[];
    meta: Metadata;
  };
} & Action<typeof UsersActions.usersSetOffice>;

type UsersSetFilter = {
  filter: {
    NameContains: string | null;
    ZoneId: number | null;
    OfficeId: string | null;
    Status: string | null;
    //Device: string | null;
  };
} & Action<typeof UsersActions.usersSetFilter>;

type UsersNewValue = {
  newUser: NewUserSuccess | null;
  error?: Error;
} & Action<typeof UsersActions.usersNewValue>;

type UsersNewValueUpdate = {
  newUser: Users;
} & Action<typeof UsersActions.usersNewValueUpdate>;

type UserSetDevice = {
  userDevice: UserDevice;
} & Action<typeof UsersActions.userSetDevice>;

type UsersStatusUpdate = {
  status: boolean;
  userName: string;
} & Action<typeof UsersActions.usersStatusUpdate>;

export type UsersAction =
  | UsersUpdate
  | UsersSetValue
  | UsersNewValue
  | UsersSetOffice
  | UsersSetFilter
  | UsersNewValueUpdate
  | UserSetDevice
  | UsersStatusUpdate;
