import { Action } from 'redux';
import { Profile } from 'types/api/responses';

export enum ProfileActions {
  profileUpdate = 'workshop/profile/update',
  profileSetValue = 'workshop/profile/set',
  resetProfile = 'workshop/profile/reset',
}

type ProfileUpdate = {
  isLoading: boolean;
  error?: Error;
} & Action<typeof ProfileActions.profileUpdate>;

type ProfileSetValue = {
  profile?: Profile;
} & Action<typeof ProfileActions.profileSetValue>;

type ResetProfile = {
  profile?: Profile;
} & Action<typeof ProfileActions.resetProfile>;

export type ProfileAction = ProfileUpdate | ProfileSetValue | ResetProfile;
