import { ApiData } from 'types/api';
import { AppThunk } from 'redux/store';
import { endpoint } from 'api/endpoints';
import { HistoryHandler } from 'utils/history.handler';
import { isSuccessResult } from 'utils/guards';
import { LocalStorageHandler } from 'utils/Local.storage.handler';
import { LoginResponse } from 'types/api/responses';
import { Routes } from 'config/routes';
import { apiPost, apiPatch } from 'api/karthus.client';
import { SessionAction, SessionActions } from 'types/redux/actions/session.action';

export const login = (userName: string, password: string): AppThunk<SessionAction> => {
  return async (dispatch) => {
    dispatch({ type: SessionActions.loginUpdate, isLoading: true });
    const response = await apiPost<ApiData<LoginResponse>>(endpoint.auth.login, {
      userName,
      password,
    });
    if (isSuccessResult(response.result)) {
      const { accessToken, refreshToken, user } = response.result.data;
      if (user.role.accessLevel < 4) {
        dispatch({
          type: SessionActions.loginUpdate,
          isLoading: false,
          error: {
            name: '1000',
            message: 'Debe ser al menos Coordinador para acceder a esta plataforma',
          },
        });
      } else {
        LocalStorageHandler.setToken(accessToken);
        LocalStorageHandler.setRefreshToken(refreshToken);
        dispatch({ type: SessionActions.loginSetValue, user });
        HistoryHandler.goTo(Routes.home);
      }
    } else {
      dispatch({
        type: SessionActions.loginUpdate,
        isLoading: false,
        error: response.result[0],
      });
    }
  };
};

export const logout = (): AppThunk<SessionAction> => {
  return async (dispatch) => {
    dispatch({ type: SessionActions.loginUpdate, isLoading: true });
    const refreshToken = LocalStorageHandler.getRefreshToken();
    const response = await apiPatch<ApiData<string>>(endpoint.auth.revoke, {
      refreshToken,
    });
    let error: Error | undefined;
    if (!isSuccessResult(response.result)) {
      error = response.result[0];
    }
    dispatch({ type: SessionActions.logoutSetValue, error });
    LocalStorageHandler.clear();
    HistoryHandler.goTo(Routes.login);
  };
};

export const updateMyUser = (name: string, lastName: string): AppThunk<SessionAction> => {
  return async (dispatch) => {
    dispatch({
      type: SessionActions.userUpdate,
      name: name,
      lastName: lastName,
    });
  };
};
