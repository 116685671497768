import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DepartmentSelect } from './DepartmentSelect';
import EditIcon from '@material-ui/icons/Edit';
import { EditWorkshopRequest } from 'types/api/request';
import { Environment } from 'utils/environment';
import Grid from '@material-ui/core/Grid';
import { NumericInput } from './NumericInput';
import Paper from '@material-ui/core/Paper';
import { ReadOnlyField } from './ReadOnlyField';
import { SelectInput } from './SelectInput';
import { shouldReload } from 'utils/state.handlers';
import { Styles } from '../Tools';
import { TextInput } from './TextInput';
import { updateWorkshop } from 'redux/actions/workshop.actions';
import { useFormik } from 'formik';
import {
  fetchOffices,
  fetchProviders,
  fetchWorkshopTypes,
} from 'redux/actions/catalog.actions';
import { FormControlLabel, Switch } from '@material-ui/core';
import { getVerification, toIsoDate, workshopValues } from 'utils';
import { getWorkshopStatus, Workshop } from 'types/api/responses/workshop';
import { Municipio, Office } from 'types/api/responses/zones';
import { Provider, WorkshopType } from 'types/api/responses';
import React, { useState, useEffect } from 'react';
import { useThunkDispatch, useTypedSelector } from 'hooks';
import { Verification, verifications, Kind, kinds } from 'config';
import * as yup from 'yup';
import './gridDetails.css';

const validationSchema = {
  name: yup.string().required('Nombre es requerido'),
  branchOfficeId: yup.string().required('Requerido'),
  municipioId: yup.string().required('Debe seleccionar un municipio'),
  clientAccount: yup
    .string()
    .length(7, 'Debe tener exactamente 7 digitos')
    .matches(new RegExp(/[0-9]{7}/), 'Formato invalido')
    .nullable(),
  potential: yup.string().required('Requerido'),
  providerId: yup
    .number()
    .min(1, 'Debe seleccionar proveedor')
    .required('Debe seleccionar proveedor'),
  seller: yup.string().nullable(),
  ownerCellphone: yup
    .string()
    .matches(new RegExp(/^\d{8}$/), 'Debe tener exactamente 8 digitos')
    .nullable()
    .required('Requerido'),
  buyer: yup.string().nullable().required('Requerido'),
  buyerLastName: yup.string().nullable().required('Requerido'),
  buyerCellphone: yup
    .string()
    .matches(new RegExp(/^\d{8}$/), 'Debe tener exactamente 8 digitos')
    .nullable()
    .required('Requerido'),
  email: yup.string().nullable().email('Formato invalido'),
  address: yup.string().nullable().required('Requerido'),
  mechanics: yup.number().min(workshopValues.mechanics.min),
  workshopTypeId: yup
    .number()
    .min(1, 'Debe seleccionar proveedor')
    .required('Debe seleccionar proveedor'),
  diagnostics: yup.boolean().required('Requerido'),
  tools: yup.boolean().required('Requerido'),
  kind: yup.string().nullable().required('Requerido'),
} as const;

export type Result = 'success' | 'error' | undefined;

type WorkshopDetailsProps = {
  shop: Workshop;
  onClose: (result: Result) => void;
};

export function DetailsTaller({ shop, onClose }: WorkshopDetailsProps) {
  const classes = Styles();
  const dispatch = useThunkDispatch();
  const { workshopTypes, offices, providers } = useTypedSelector((s) => s.catalog);
  const { currentId, departments } = useTypedSelector((s) => s.departments);
  const isLoading = useTypedSelector((w) => w.workshop.isLoading);
  const profile = useTypedSelector((u) => u.profile.profile);
  const myRole = useTypedSelector((s) => s.session.user.role.accessLevel);
  const allowProviders = providers.data.filter((p) => p.active || p.id === shop.provider?.id);
  const allowTypes = workshopTypes.data.filter(
    (t) => t.active || t.id === shop.workShopType?.id,
  );
  const municipio = departments.find((d) => d.id === currentId)?.municipios.data || [];

  const formik = useFormik<EditWorkshopRequest>({
    initialValues: {
      ...shop,
      branchOfficeId: shop.branchOffice.id,
      municipioId: shop.municipio?.id,
      providerId: shop.provider?.id,
      workshopTypeId: shop.workShopType?.id,
    },
    validateOnChange: false,
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema: yup.object({
      ...validationSchema,
      longitude: yup.number().when('workshopTypeId', {
        is: (val: number | undefined) => {
          const found = workshopTypes.data.find((t) => t.id === val);
          return val && found?.external;
        },
        then: (schema) => schema.equals([0], 'Debe ser cero'),
        otherwise: (schema) =>
          schema
            .min(-90.1397, 'Minimo -90.1397')
            .max(-87.7112, 'Maximo -87.7112')
            .required('Longitud requerida'),
      }),
      latitude: yup.number().when('workshopTypeId', {
        is: (val: number | undefined) => {
          const found = workshopTypes.data.find((t) => t.id === val);
          return val && found?.external;
        },
        then: (schema) => schema.equals([0], 'Debe ser cero'),
        otherwise: (schema) =>
          schema
            .min(13.14, 'Minimo 13.14')
            .max(14.4179, 'Maximo 14.4179')
            .required('Latitud requerida'),
      }),
      weeklyCars: yup.number().when('workshopTypeId', {
        is: (val: number | undefined) => {
          const found = workshopTypes.data.find((t) => t.id === val);
          return val && found?.external;
        },
        then: (schema) => schema.min(1).max(100).required('Requerido'),
      }),
    }),
    onSubmit: (values) => {
      dispatch(updateWorkshop(values))
        .then(() => onClose('success'))
        .catch(() => onClose('error'));
    },
  });
  const external = workshopTypes.data.find((t) => t.id === formik.values.workshopTypeId);
  const baseURL = Environment.baseUrl;
  const isCoordinator = myRole !== 5;
  const [edit, setEdit] = useState(false);
  useEffect(() => {
    if (shouldReload(offices) && edit) {
      dispatch(fetchOffices());
    }
  }, [edit, offices, dispatch]);

  useEffect(() => {
    if (shouldReload(providers) && edit) {
      dispatch(fetchProviders());
    }
  }, [edit, providers, dispatch]);

  useEffect(() => {
    if (shouldReload(workshopTypes)) {
      dispatch(fetchWorkshopTypes());
    }
  }, [workshopTypes, dispatch]);

  useEffect(() => {
    const isZero = formik.values.longitude === 0 && formik.values.latitude === 0;
    if (!isZero && external?.external) {
      formik.setFieldValue('longitude', 0, false);
      formik.setFieldValue('latitude', 0, false);
    }
  }, [external, formik]);

  const handleEdit = () => {
    if (edit) {
      onCloseEdit();
      return;
    }
    setEdit(true);
  };

  const onCloseEdit = () => {
    formik.resetForm();
    setEdit(false);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container xl={true} spacing={3}>
        <div className={classes.flexRowCenter}>
          <Paper
            className={classes.paper}
            style={{
              backgroundColor: getWorkshopStatus(shop.status)?.color,
              color: getWorkshopStatus(shop.status)?.fix,
              width: '100%',
            }}
          >
            {shop.name}
          </Paper>
          {isCoordinator && (
            <Button
              variant="outlined"
              onClick={handleEdit}
              style={{ color: edit ? 'Red' : 'Blue', marginRight: '5px' }}
            >
              <EditIcon />
            </Button>
          )}
          <Button
            variant="outlined"
            onClick={() => onClose(undefined)}
            style={{ color: 'red', marginRight: '5px' }}
          >
            <CancelIcon />
          </Button>
        </div>
      </Grid>
      <div className="form-container">
        <img
          className="image-grid-workshop"
          src={baseURL + (shop.pictureUrl || 'images/empty.jpg')}
          alt={shop.name}
        />
        <div className="form-container-inner">
          <TextInput
            unique
            label="Nombre Comercial"
            className="half"
            shopKey="name"
            form={formik}
            inputProps={{ maxLength: workshopValues.name, readOnly: !edit }}
          />
          <ReadOnlyField
            className="half"
            value={profile?.commercialName}
            label="Nombre S/ Registro"
          />
          <TextInput
            label="Cuenta"
            profile={profile}
            shopKey="clientAccount"
            form={formik}
            inputProps={{ maxLength: workshopValues.clientAccount, readOnly: !edit }}
          />
          <ReadOnlyField label="Cuenta" as400 value={profile?.clientAccount} />
          <ReadOnlyField label="Carnet" as400 value={profile?.license} />
          <ReadOnlyField label="Segmento" as400 value={profile?.program} />
          <NumericInput
            label="Longitud"
            numKey="longitude"
            form={formik}
            step={0.005}
            inputProps={{ readOnly: !edit }}
          />
          <NumericInput
            label="Latitud"
            numKey="latitude"
            form={formik}
            step={0.005}
            inputProps={{ readOnly: !edit }}
          />
          <ReadOnlyField label="Ultima Actualizacion" value={toIsoDate(shop.lastSync)} />
          <SelectInput<Kind>
            none=""
            fieldId="kind"
            label="Jerarquía"
            form={formik}
            options={kinds}
            readonlyValue={kinds.find((k) => k.id === shop.kind)?.name}
            keyValue="name"
            readonly={!edit}
          />
        </div>
      </div>
      <div className="form-container">
        <TextInput
          label="Direccion del Taller"
          form={formik}
          className="form-read-only-full"
          shopKey="address"
          profile={profile}
          inputProps={{
            maxLength: workshopValues.address,
            readOnly: !edit,
          }}
        />
        <ReadOnlyField
          className="form-read-only-full"
          label="Direccion del Taller"
          as400
          value={profile?.address}
        />
        <DepartmentSelect value={shop.municipio?.department} disable={!edit} />
        <SelectInput<Municipio>
          none=""
          label="Municipio"
          form={formik}
          readonly={!edit}
          readonlyValue={shop.municipio?.name}
          fieldId="municipioId"
          keyValue="name"
          options={municipio}
        />
        <SelectInput<WorkshopType>
          none={0}
          label="Tipo de Taller"
          form={formik}
          readonly={!edit}
          readonlyValue={shop.workShopType?.name}
          fieldId="workshopTypeId"
          keyValue="name"
          options={allowTypes}
        />
        <ReadOnlyField label="Tipo de Taller" as400 value={profile?.workshopType} />
        <TextInput
          label="Nombre Comprador"
          shopKey="buyer"
          form={formik}
          profile={profile}
          inputProps={{ maxLength: workshopValues.buyer, readOnly: !edit }}
        />
        <ReadOnlyField label="Nombre Comprador" as400 value={profile?.buyer} />
        <TextInput
          label="Apellido Compador"
          shopKey="buyerLastName"
          form={formik}
          profile={profile}
          inputProps={{ maxLength: workshopValues.buyer, readOnly: !edit }}
        />
        <ReadOnlyField label="Apellido Comprador" as400 value={profile?.buyerLastName} />
        <TextInput
          unique
          label="Email"
          form={formik}
          shopKey="email"
          inputProps={{ maxLength: workshopValues.email, readOnly: !edit }}
        />
        <SelectInput<Provider>
          none={0}
          fieldId="providerId"
          label="Proveedor principal"
          form={formik}
          options={allowProviders}
          keyValue="name"
          readonly={!edit}
          readonlyValue={shop.provider?.name}
        />
        <TextInput
          unique
          label="Vendedor"
          profile={profile}
          shopKey="seller"
          form={formik}
          inputProps={{ maxLength: 2, readOnly: !edit }}
        />
        <ReadOnlyField label="Vendedor" as400 value={profile?.seller} />
        <TextInput
          label="Celular Comprador"
          profile={profile}
          shopKey="buyerCellphone"
          form={formik}
          inputProps={{ maxLength: workshopValues.phone, readOnly: !edit }}
        />
        <ReadOnlyField label="Celular Comprador" as400 value={profile?.buyerCellphone} />
        <TextInput
          label="Celular Propietario"
          profile={profile}
          shopKey="ownerCellphone"
          form={formik}
          inputProps={{ maxLength: workshopValues.phone, readOnly: !edit }}
        />
        <ReadOnlyField label="Celular Propietario" as400 value={profile?.ownerCellphone} />
        <NumericInput
          label="Número de Mecanicos"
          numKey="mechanics"
          form={formik}
          profile={profile}
          inputProps={{ max: 100, readOnly: !edit, min: 1 }}
        />
        <ReadOnlyField label="Número de Mecanicos" as400 value={profile?.mechanics} />
        <NumericInput
          label="Capacidad Instalada"
          numKey="capacity"
          form={formik}
          profile={profile}
          inputProps={{ max: 100, readOnly: !edit, min: 1 }}
        />
        <ReadOnlyField label="Capacidad Instalada" as400 value={profile?.capacity} />
        <SelectInput<Office>
          fieldId="branchOfficeId"
          label="Sucursal"
          form={formik}
          options={offices.data}
          keyValue="name"
          readonly={!edit}
          readonlyValue={shop.branchOffice.name}
        />
        <ReadOnlyField label="Sucursal" as400 value={profile?.branchOfficeName} />
        <SelectInput<Verification>
          fieldId="potential"
          label="Verificacion"
          form={formik}
          options={verifications}
          keyValue="name"
          readonly={!edit}
          readonlyValue={getVerification(shop.potential)}
        />
        <ReadOnlyField
          label="Verificacion"
          as400
          value={getVerification(profile?.potential)}
        />
        {external?.external && (
          <NumericInput
            label="Carros semanales"
            numKey="weeklyCars"
            form={formik}
            inputProps={{ min: 1, max: 100, readOnly: !edit }}
          />
        )}
      </div>
      <div className="form-container">
        <FormControlLabel
          control={
            <Switch
              id="tools"
              checked={formik.values.tools}
              onChange={formik.handleChange}
              disabled={!edit}
            />
          }
          label="Herraminetas"
        />
        <FormControlLabel
          control={
            <Switch
              id="diagnostics"
              checked={formik.values.diagnostics}
              onChange={formik.handleChange}
              disabled={!edit}
            />
          }
          label="Eq. Diagnostico"
        />
      </div>
      <div className="form-bottom-control">
        {isLoading && <CircularProgress />}
        {edit && (
          <>
            <Button
              variant="outlined"
              type="submit"
              className={classes.submit}
              style={{ color: 'Blue', marginRight: '5px' }}
              disabled={isLoading}
            >
              Guardar
            </Button>
            <Button
              variant="outlined"
              onClick={onCloseEdit}
              className={classes.submit}
              style={{ color: 'Red' }}
              disabled={isLoading}
            >
              Cancelar
            </Button>
          </>
        )}
      </div>
    </form>
  );
}
