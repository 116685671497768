import { ApiData } from 'types/api';
import { apiGet } from 'api/karthus.client';
import { AppThunk } from 'redux/store';
import { DepartmentWithMunicipio } from 'types/redux/state/department.state';
import { endpoint } from 'api/endpoints';
import { isSuccessResult } from 'utils';
import { Municipio } from 'types/api/responses';
import { DepartmentAction, DepartmentActions } from 'types/redux/actions/department.action';

export const changeDepartment = (department: DepartmentWithMunicipio): DepartmentAction => {
  return {
    type: DepartmentActions.departmentChange,
    departmentId: department.id,
  };
};

export const fetchMunicipios = (departmentId: string): AppThunk<DepartmentAction> => {
  return async (dispatch) => {
    dispatch({ type: DepartmentActions.departmentUpdate, departmentId, isLoading: true });
    const response = await apiGet<ApiData<Municipio[]>>(
      endpoint.municipios + `/${departmentId}/municipio`,
    );
    if (isSuccessResult(response.result)) {
      dispatch({
        type: DepartmentActions.departmentSetValue,
        municipios: response.result.data,
        departmentId,
      });
      return;
    }
    dispatch({
      type: DepartmentActions.departmentUpdate,
      departmentId,
      isLoading: false,
      error: response.result[0],
    });
  };
};

export const fetchDepartments = (country: string): AppThunk<DepartmentAction> => {
  return async (dispatch) => {
    dispatch({ type: DepartmentActions.departmentUpdate, departmentId: '', isLoading: true });
    const response = await apiGet<ApiData<Municipio[]>>(endpoint.municipios);
    if (isSuccessResult(response.result)) {
      dispatch({
        type: DepartmentActions.departmentGet,
        departments: response.result.data,
        departmentId: '',
      });
      return;
    }
    dispatch({
      type: DepartmentActions.departmentUpdate,
      departmentId: '',
      isLoading: false,
      error: response.result[0],
    });
  };
};
