import { Loadable, Loadable2 } from 'types/redux/state';

export const setLoadable = (loadable: Loadable, isLoading: boolean, error?: Error): void => {
  loadable.isDirty = true;
  loadable.isLoading = isLoading;
  loadable.error = error;
};

export const resetLoadable = (loadable: Loadable, isLoading: boolean, error?: Error): void => {
  loadable.isDirty = false;
  loadable.isLoading = isLoading;
  loadable.error = error;
};

export const shouldReload = (loadable: Loadable): boolean => {
  return !(loadable.isLoading || loadable.isDirty || loadable.error);
};

export const initLoadable = <T>(data: T): Loadable2<T> => {
  return {
    error: undefined,
    isDirty: false,
    isLoading: false,
    data,
  };
};
