import { Department } from 'types/api/responses/workshop';
import FormControl from '@material-ui/core/FormControl';
import { shouldReload } from 'utils/state.handlers';
import { fetchMunicipios, changeDepartment } from 'redux/actions/department.actions';
import { InputLabel, Select, MenuItem } from '@material-ui/core';
import React, { ChangeEvent, useEffect } from 'react';
import { useTypedSelector, useThunkDispatch } from 'hooks';

export type DepartmentSelectProps = {
  disable: boolean;
  value?: Department;
};

export const DepartmentSelect = ({ value, disable }: DepartmentSelectProps) => {
  // [1] La Libertad default
  const { currentId, departments } = useTypedSelector((s) => s.departments);
  const initVal = value?.id || currentId;
  const dispatch = useThunkDispatch();
  const municipios = departments.find((d) => d.id === currentId)?.municipios;

  const onDepartmentChange = (evt: ChangeEvent<{ value: unknown }>) => {
    const newVal = evt.target.value;
    const newDept = departments.find((d) => d.id === newVal);
    if (newDept) {
      dispatch(changeDepartment(newDept));
    }
  };

  useEffect(() => {
    if (value?.id && value.id !== currentId) {
      const newDept = departments.find((d) => d.id === value.id);
      if (newDept) {
        dispatch(changeDepartment(newDept));
      }
    }
  }, []);

  useEffect(() => {
    if (municipios && shouldReload(municipios)) {
      dispatch(fetchMunicipios(currentId));
    }
  }, [municipios, dispatch, currentId]);

  return (
    <div className="form-read-only">
      <FormControl>
        <InputLabel id="department-select-label">Departamento</InputLabel>
        <Select
          defaultValue={initVal}
          labelId="department-select-label"
          onChange={onDepartmentChange}
          disabled={disable}
        >
          {departments.map((d) => (
            <MenuItem key={d.id} value={d.id}>
              {d.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
