import { Action } from 'redux';
import { Provider, Metadata } from 'types/api/responses';

export enum ProvidersActions {
  providersUpdate = 'session/providers/update',
  providersSetValue = 'session/providers/set',
  providersSetFilter = 'session/providers/setFilter',
  providersSetNew = 'session/providers/setNew',
  providersSetUpdate = 'session/providers/setNewUpdate',
}

type PorvidersUpdate = {
  isLoading: boolean;
  error?: Error;
} & Action<typeof ProvidersActions.providersUpdate>;

type ProvidersSetValue = {
  providers: {
    providers: Provider[];
    meta: Metadata;
  };
} & Action<typeof ProvidersActions.providersSetValue>;

type ProvidersSetFilter = {
  filter: {
    NameContains: string | null;
    Status: string | null;
  };
} & Action<typeof ProvidersActions.providersSetFilter>;

type ProvidersSetNew = {
  newProvider: Provider | null;
  error?: Error;
} & Action<typeof ProvidersActions.providersSetNew>;

type ProvidersSetUpdate = {
  newProvider: Provider;
} & Action<typeof ProvidersActions.providersSetUpdate>;

export type ProvidersAction =
  | PorvidersUpdate
  | ProvidersSetValue
  | ProvidersSetFilter
  | ProvidersSetNew
  | ProvidersSetUpdate;
