import { ApiData, ApiMetaData } from 'types/api';
import { AppThunk } from 'redux/store';
import { endpoint } from 'api/endpoints';
import { HistoryHandler } from 'utils/history.handler';
import { isSuccessResult } from 'utils/guards';
import { LocalStorageHandler } from 'utils/Local.storage.handler';
import { Provider } from 'types/api/responses';
import { Routes } from 'config/routes';
import { apiGet, apiPatch, apiPost } from 'api/karthus.client';
import { ProvidersAction, ProvidersActions } from 'types/redux/actions/providers.action';
import { FilterProvider } from 'types/redux/state/providers.state';

export const allProviders = (
  params: {
    page: number;
    size: number;
  },
  filter: FilterProvider,
): AppThunk<ProvidersAction> => {
  return async (dispatch) => {
    dispatch({ type: ProvidersActions.providersUpdate, isLoading: true });
    const response = await apiGet<ApiMetaData<Provider[]>>(endpoint.providers, {
      ...params,
      filter,
    });
    if (isSuccessResult(response.result)) {
      dispatch({
        type: ProvidersActions.providersSetValue,
        providers: {
          providers: response.result.data,
          meta: response.result.meta,
        },
      });
      dispatch({ type: ProvidersActions.providersSetFilter, filter: filter });
    } else {
      dispatch({
        type: ProvidersActions.providersUpdate,
        isLoading: false,
        error: response.result[0],
      });
    }
  };
};

export const updateProvider = (params: Provider): AppThunk<ProvidersAction> => {
  return async (dispatch) => {
    dispatch({ type: ProvidersActions.providersUpdate, isLoading: true });
    const response = await apiPatch<ApiData<Provider>>(endpoint.providers, {
      ...params,
    });
    if (isSuccessResult(response.result)) {
      dispatch({
        type: ProvidersActions.providersSetUpdate,
        newProvider: response.result.data,
      });
    } else {
      dispatch({
        type: ProvidersActions.providersUpdate,
        isLoading: false,
        error: response.result[0],
      });
    }
  };
};

export const newProvider = (name: string, after?: () => void): AppThunk<ProvidersAction> => {
  return async (dispatch) => {
    dispatch({ type: ProvidersActions.providersUpdate, isLoading: true });
    const response = await apiPost<ApiData<Provider>>(endpoint.providers, {
      name,
    });
    if (isSuccessResult(response.result)) {
      dispatch({
        type: ProvidersActions.providersSetUpdate,
        newProvider: response.result.data,
      });
      if (after) {
        after();
      }
      //llamar funcion de despacho :v
    } else {
      dispatch({
        type: ProvidersActions.providersUpdate,
        isLoading: false,
        error: response.result[0],
      });
    }
  };
};

export const resetProvider = (): AppThunk<ProvidersAction> => {
  return async (dispatch) => {
    dispatch({ type: ProvidersActions.providersSetNew, newProvider: null });
  };
};
