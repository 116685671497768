import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import { login } from 'redux/actions/session.actions';
import { Styles } from 'components/Tools';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useFormik } from 'formik';
import { useThunkDispatch, useTypedSelector } from 'hooks';
import * as yup from 'yup';
import { Environment } from 'utils/environment';

const validationSchema = yup.object({
  username: yup
    // @ts-ignore
    .string('Enter your username')
    .min(3, 'username should be of minimum 3 characters length')
    .max(50, 'username should be of maximun 50 characters length')
    .required('username is required'),
  password: yup
    // @ts-ignore
    .string('Enter your password')
    .max(50, 'username should be of maximun 50 characters length')
    .required('Password is required'),
});

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      2021
    </Typography>
  );
}

export const Login = () => {
  const classes = Styles();
  const mySession = useTypedSelector((s) => s.session);
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(login(values.username, values.password));
    },
  });
  // JESMORENO
  // RT2(O0viE7

  const dispatch = useThunkDispatch();

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <div className={classes.avatar}>
          {!Environment.hideMarks && (
            <img
              src={process.env.PUBLIC_URL + 'Logo2.png'}
              id="outlined-basic"
              width={'100%'}
            />
          )}
        </div>
        {mySession.isLoading && <CircularProgress />}
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <TextField
            onChange={formik.handleChange}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Nombre de Usuario"
            name="username"
            autoComplete="username"
            inputProps={{ maxLength: 50 }}
            autoFocus
            value={formik.values.username}
            error={
              (formik.touched.username && Boolean(formik.errors.username)) ||
              Boolean(mySession.error?.name === '1008')
            }
            helperText={
              (formik.touched.username && formik.errors.username) ||
              (Boolean(mySession.error?.name === '1008') && 'Nombre de usuario no valido')
            }
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            name="password"
            inputProps={{ maxLength: 50 }}
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            //onClick={onCLick}
          >
            INGRESAR
          </Button>
        </form>
        {mySession.error?.name && (
          <p style={{ whiteSpace: 'pre-line' }}>Error {mySession.error.message}</p>
        )}
      </div>
      <div>
        <Copyright />
      </div>
    </Container>
  );
};
