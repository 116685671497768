import { Styles } from 'components/Tools';
import { useThunkDispatch, useTypedSelector } from 'hooks';
import { NewUser } from 'types/api/responses';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { FilterZoneOffice } from 'components/ListTaller/FilterZoneOffice';
import InputLabel from '@material-ui/core/InputLabel';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { userAll, userOffices } from 'redux/actions/users.actions';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button/Button';
const INITPAGE = 1,
  SIZE = 9;

export function FilterUserComponent({
  handleChangeModal,
  successReset,
}: {
  handleChangeModal: ({ user, flag }: { user: NewUser; flag: boolean }) => void;
  successReset: () => void;
}) {
  const classes = Styles();
  const dispatch = useThunkDispatch();
  const zones = useTypedSelector((z) => z.zones);
  const users = useTypedSelector((z) => z.users);

  const [openStatus, setOpenStatus] = React.useState(false);
  const [nameSearch, setNameSearch] = React.useState(users.filter.NameContains);
  const [theStatus, setTheStatus] = React.useState(
    users.filter.Status != null ? users.filter.Status : '0',
  );
  //const [openStatusDevice, setOpenStatusDevice] = React.useState(false);
  //const [theStatusDevice, setTheStatusDevice] = React.useState('0');

  //evento de cambio en nombre a buscar
  const handleChangeNameSearch = (event: React.ChangeEvent<{ value: unknown }>) => {
    setNameSearch(event.target.value as string);
  };

  //evento de busqueda segun nombre
  const handleSearch = () => {
    //alert(nameSearch + 'F');
    dispatch(
      userAll(
        { page: INITPAGE, size: SIZE },
        {
          NameContains: nameSearch,
          Status: users.filter.Status,
          ZoneId: users.filter.ZoneId,
          OfficeId: users.filter.OfficeId,
          //Device: users.filter.Device,
        },
      ),
    );
  };

  const handleSearchReset = () => {
    //alert(nameSearch + 'F');
    dispatch(
      userAll(
        { page: INITPAGE, size: SIZE },
        {
          NameContains: null,
          Status: null,
          ZoneId: null,
          OfficeId: null,
          //Device: users.filter.Device,
        },
        successReset,
      ),
    );
  };

  const changeOffice = ({ reset, value }: { reset: boolean; value: string }) => {
    if (!reset) {
      dispatch(
        userAll(
          { page: INITPAGE, size: SIZE },
          {
            NameContains: users.filter.NameContains,
            Status: users.filter.Status,
            ZoneId: users.filter.ZoneId,
            OfficeId: value,
            //Device: users.filter.Device,
          },
        ),
      );
    } else {
      dispatch(
        userAll(
          { page: INITPAGE, size: SIZE },
          {
            NameContains: users.filter.NameContains,
            Status: users.filter.Status,
            ZoneId: users.filter.ZoneId,
            OfficeId: null,
            //Device: users.filter.Device,
          },
        ),
      );
    }
  };

  const changeZone = ({ reset, value }: { reset: boolean; value: number }) => {
    if (!reset) {
      dispatch(userOffices({ ZoneId: value }));
      dispatch(
        userAll(
          { page: INITPAGE, size: SIZE },
          {
            NameContains: users.filter.NameContains,
            Status: users.filter.Status,
            ZoneId: value,
            OfficeId: null,
            //Device: users.filter.Device,
          },
        ),
      );
    } else {
      dispatch(userOffices({ ZoneId: null }));
      dispatch(
        userAll(
          { page: INITPAGE, size: SIZE },
          {
            NameContains: users.filter.NameContains,
            Status: users.filter.Status,
            ZoneId: null,
            OfficeId: null,
            //Device: users.filter.Device,
          },
        ),
      );
    }
  };

  const pagineOffice = ({ page, zone }: { page: number; zone: number | null }) => {
    //dispatch(workshopOffices({ size: SIZE0, page: page }, { ZoneId: zone }));
  };

  const handleOpenStatus = () => {
    setOpenStatus(true);
  };

  const handleClosedStatus = () => {
    setOpenStatus(false);
  };

  const handleChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;
    setTheStatus(value);
    dispatch(
      userAll(
        { page: INITPAGE, size: SIZE },
        {
          NameContains: users.filter.NameContains,
          Status: value === '0' ? null : value,
          ZoneId: users.filter.ZoneId,
          OfficeId: users.filter.OfficeId,
          //Device: users.filter.Device,
        },
      ),
    );
  };
  /*const handleOpenStatusDevice = () => {
    setOpenStatusDevice(true);
  };

  const handleClosedStatusDevice = () => {
    setOpenStatusDevice(false);
  };

  const handleChangeStatusDevice = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;
    setTheStatusDevice(value);
    dispatch(
      userAll(
        { page: INITPAGE, size: SIZE },
        {
          NameContains: users.filter.NameContains,
          Status: users.filter.Status,
          ZoneId: users.filter.ZoneId,
          OfficeId: users.filter.OfficeId,
          Device: value === '0' ? null : value,
        },
      ),
    );
  };*/

  return (
    <form className={classes.rootForm} noValidate autoComplete="off">
      <Paper className={classes.paper}>
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
          <TextField
            id="outlined-read-only-input"
            label="Nombre"
            inputProps={{ maxLength: 150 }}
            value={nameSearch}
            onChange={handleChangeNameSearch}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                ev.preventDefault();
                handleSearch();
              }
            }}
            variant="outlined"
            required
          />
          <FilterZoneOffice
            changeZone={changeZone}
            changeOffice={changeOffice}
            filter={{
              zone: users.filter.ZoneId ? users.filter.ZoneId : 0,
              office: users.filter.OfficeId ? users.filter.OfficeId : '0',
            }}
            offices={users.branchOffices}
            pagineOffice={pagineOffice}
          />
          <FormControl
            variant="outlined"
            style={{ marginLeft: '10px' }}
            className={classes.formControl}
          >
            <InputLabel id="select-filter-staus">Estado</InputLabel>
            <Select
              labelId="select-filter-status"
              id="filter-controlled-open-select-status"
              open={openStatus}
              onClose={handleClosedStatus}
              onOpen={handleOpenStatus}
              value={theStatus}
              onChange={handleChangeStatus}
            >
              <MenuItem value={'0'}>Todos</MenuItem>
              <MenuItem value={'Disabled'}>Inactivos</MenuItem>
              <MenuItem value={'Active'}>Activos</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="outlined"
            onClick={handleSearchReset}
            className={classes.submit}
            style={{ color: 'Blue', marginRight: '5px' }}
          >
            Reset
          </Button>
          <BottomNavigation
            showLabels
            onClick={() => {
              handleChangeModal({
                user: {
                  userName: '',
                  name: '',
                  lastName: '',
                  active: true,
                  accessLevel: 1,
                  branchOfficeId: '',
                  zoneId: 1,
                },
                flag: true,
              });
            }}
          >
            <BottomNavigationAction
              label="Usuario"
              icon={<PersonAddIcon />}
              style={{ color: 'green' }}
            />
          </BottomNavigation>
          {users.isLoading ? (
            <div>
              <CircularProgress />
            </div>
          ) : (
            <div />
          )}
        </div>
      </Paper>
    </form>
  );
}
