import { ApiData } from 'types/api';
import { apiGet } from 'api/karthus.client';
import { AppThunk } from 'redux/store';
import { endpoint } from 'api/endpoints';
import { isSuccessResult } from 'utils';
import { CatalogActions, CatalogAction } from 'types/redux/actions/catalog.action';
import { Office, Provider, WorkshopType } from 'types/api/responses';

export const fetchOffices = (): AppThunk<CatalogAction> => {
  return async (dispatch) => {
    dispatch({ type: CatalogActions.officeUpdate, isLoading: true });
    const response = await apiGet<ApiData<Office[]>>(endpoint.branchOffcie, {
      size: 0,
    });
    if (isSuccessResult(response.result)) {
      dispatch({
        type: CatalogActions.officeValue,
        offices: response.result.data,
      });
    } else {
      dispatch({
        type: CatalogActions.officeUpdate,
        isLoading: false,
        error: response.result[0],
      });
    }
  };
};

export const fetchProviders = (): AppThunk<CatalogAction> => {
  return async (dispatch) => {
    dispatch({ type: CatalogActions.providerUpdate, isLoading: true });
    const response = await apiGet<ApiData<Provider[]>>(endpoint.providers, {
      size: 0,
    });
    if (isSuccessResult(response.result)) {
      dispatch({
        type: CatalogActions.providerValue,
        providers: response.result.data,
      });
    } else {
      dispatch({
        type: CatalogActions.providerUpdate,
        isLoading: false,
        error: response.result[0],
      });
    }
  };
};

export const fetchWorkshopTypes = (): AppThunk<CatalogAction> => {
  return async (dispatch) => {
    dispatch({ type: CatalogActions.typesUpdate, isLoading: true });
    const response = await apiGet<ApiData<WorkshopType[]>>(endpoint.workshopTypes, {
      size: 0,
    });
    if (isSuccessResult(response.result)) {
      dispatch({
        type: CatalogActions.typesValue,
        types: response.result.data,
      });
    } else {
      dispatch({
        type: CatalogActions.typesUpdate,
        isLoading: false,
        error: response.result[0],
      });
    }
  };
};
