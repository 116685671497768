import CircularProgress from '@material-ui/core/CircularProgress';
import { DateTime } from 'luxon';
import { Filters } from '../Details/Filters';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Pagination from '@material-ui/lab/Pagination';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import { shouldReload } from 'utils/state.handlers';
import Snackbar from '@material-ui/core/Snackbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { DetailsTaller, Result } from '../Details';
import { getProfile, resetProfile } from 'redux/actions/profile.actions';
import React, { useEffect, useState } from 'react';
import {
  Styles,
  myTheme,
  getModalStyle,
  StyledTableCell,
  StyledTableRow,
  Alert,
} from '../Tools';
import { useThunkDispatch, useTypedSelector } from 'hooks';
import { Workshop, getWorkshopStatus } from 'types/api/responses/workshop';
import { workshopAll } from 'redux/actions/workshop.actions';

const SIZE = 10,
  INITPAGE = 1;

export const MouseOverPopover = ({ element }: { element: string }) => {
  const classes = Styles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div
      style={{
        width: 'fit-content',
        justifyContent: 'center',
        display: 'flex',
      }}
      id={'popover'}
    >
      <Paper
        className={classes.paper}
        style={{
          backgroundColor: getWorkshopStatus(element)?.color,
          color: getWorkshopStatus(element)?.color,
          width: 'fit-content',
        }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        status
      </Paper>
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>{getWorkshopStatus(element)?.type}</Typography>
      </Popover>
    </div>
  );
};

function ListTaller() {
  const classes = Styles();
  const [modalStyle] = useState(getModalStyle(5, 5));
  const [shop, setShop] = useState<Workshop | undefined>();
  const [res, setRes] = useState<Result>(undefined);
  const dispatch = useThunkDispatch();
  const workshop = useTypedSelector((w) => w.workshop);
  const session = useTypedSelector((s) => s.session);
  const [page, setPage] = useState(1);
  const [modalActiveExcel, setmodalActiveExcel] = useState(false);
  const handleCloseExcel = (_: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setmodalActiveExcel(false);
  };

  useEffect(() => {
    if (shouldReload(workshop)) {
      dispatch(
        workshopAll({
          params: { page: INITPAGE, size: SIZE },
          filterN: { ...workshop.filter },
        }),
      );
    }
  }, [workshop]);

  const TableBodyCustom = (
    <TableBody>
      {workshop.workshops.workshops.map((item: Workshop) => {
        return (
          <StyledTableRow key={item.id}>
            <StyledTableCell component="th" scope="row">
              {item.clientAccount}
            </StyledTableCell>
            <StyledTableCell align="center">{item.program}</StyledTableCell>
            <StyledTableCell align="center">{item.name}</StyledTableCell>
            <StyledTableCell align="center">{item.branchOffice.name}</StyledTableCell>
            <StyledTableCell align="center">{item.branchOffice.zone.name}</StyledTableCell>
            <StyledTableCell align="center">
              {DateTime.fromISO(item.lastSync ? item.lastSync : '').toISODate()}
            </StyledTableCell>
            <StyledTableCell align="center">
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Paper
                  className={classes.paper}
                  style={{
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    color: 'white',
                    backgroundColor: 'blue',
                  }}
                  onClick={() => handleOpen(item)}
                >
                  +
                </Paper>
                <MouseOverPopover element={item.status} />
              </div>
            </StyledTableCell>
          </StyledTableRow>
        );
      })}
    </TableBody>
  );

  const handleOpen = (clickedShop: Workshop) => {
    dispatch(resetProfile());
    setShop(clickedShop);
    if (clickedShop.clientAccount) {
      dispatch(getProfile(clickedShop.clientAccount));
    }
  };

  const handleClose = () => {
    setShop(undefined);
    setRes(undefined);
  };

  const handleRes = (result: Result) => {
    setRes(result);
    setShop(undefined);
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
    dispatch(
      workshopAll({
        params: { page: newPage, size: SIZE },
        filterN: workshop.filter,
      }),
    );
  };

  return (
    <ThemeProvider theme={myTheme}>
      <Modal
        disableBackdropClick
        open={!!shop}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.papermodal}>
          <div id={'modal-container'} style={{ height: '100%' }}>
            <DetailsTaller shop={shop as Workshop} onClose={handleRes} />
          </div>
        </div>
      </Modal>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Grid item xs={11} style={{ width: '100%' }}>
          <Filters />
        </Grid>
        <Grid item xs={11} style={{ width: '100%', marginTop: '2em' }}>
          <TableContainer style={{ maxHeight: '70vh' }}>
            <Table className={classes.table} stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Cuenta</StyledTableCell>
                  <StyledTableCell align="center">TA</StyledTableCell>
                  <StyledTableCell align="center">Nombre Comercial</StyledTableCell>
                  <StyledTableCell align="center">Sucursal</StyledTableCell>
                  <StyledTableCell align="center">Gerente Zona</StyledTableCell>
                  <StyledTableCell align="center">Fecha Modificacion</StyledTableCell>
                  <StyledTableCell align="center">Estado</StyledTableCell>
                </TableRow>
              </TableHead>
              {TableBodyCustom}
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={11} style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {workshop.isLoading ? (
              <CircularProgress />
            ) : (
              <Pagination
                count={workshop.workshops.meta?.pages}
                variant="outlined"
                shape="rounded"
                page={page}
                onChange={handleChangePage}
              />
            )}
          </div>
        </Grid>
        <Snackbar
          open={Boolean(res)}
          autoHideDuration={5000}
          onClose={() => setRes(undefined)}
        >
          <Alert onClose={() => setRes(undefined)} severity={res}>
            {res === 'success' ? (
              <p>Taller actualizado correctamente</p>
            ) : (
              <p>Error de actualiazacion</p>
            )}
          </Alert>
        </Snackbar>
        <Snackbar open={modalActiveExcel} autoHideDuration={15000} onClose={handleCloseExcel}>
          {session.isLoading ? (
            <Alert onClose={handleCloseExcel} severity="info">
              <div>Cargando...</div>
            </Alert>
          ) : (
            <div>
              {session.error || workshop.error ? (
                <Alert onClose={handleCloseExcel} severity="error">
                  <div>
                    ocurrio un error {session.error?.message} {workshop.error?.message}
                  </div>
                </Alert>
              ) : (
                <Alert onClose={handleCloseExcel} severity="success">
                  <div>La descarga esta en proceso</div>
                </Alert>
              )}
            </div>
          )}
        </Snackbar>
      </div>
    </ThemeProvider>
  );
}

export { ListTaller };
