import { apiGet } from 'api/karthus.client';
import { ApiMetaData } from 'types/api';
import { AppThunk } from 'redux/store';
import { endpoint } from 'api/endpoints';
import { isSuccessResult } from 'utils/guards';
import { Zone } from 'types/api/responses';
import { ZonesAction, ZonesActions } from 'types/redux/actions/zones.action';

export const allZones = (): AppThunk<ZonesAction> => {
  return async (dispatch) => {
    dispatch({ type: ZonesActions.zonesUpdate, isLoading: true });
    const response = await apiGet<ApiMetaData<Zone[]>>(endpoint.zones, { size: 100, page: 1 });
    if (isSuccessResult(response.result)) {
      dispatch({
        type: ZonesActions.zonesSetValue,
        zones: response.result.data,
        meta: response.result.meta,
      });
    } else {
      dispatch({
        type: ZonesActions.zonesUpdate,
        isLoading: false,
        error: response.result[0],
      });
    }
  };
};
