import { Action } from 'redux';
import { Device, Metadata } from 'types/api/responses';

export enum DevicesActions {
  devicesUpdate = 'session/devices/update',
  devicesSetValue = 'session/devices/set',
  devicesSetFilter = 'session/devices/setFilter',
  devicesNewValue = 'session/devices/new',
  devicesNewValueUpdate = 'session/devices/newUpdate',
}

export type DevicesUpdate = {
  isLoading: boolean;
  error?: Error;
} & Action<typeof DevicesActions.devicesUpdate>;

type DevicesSetValue = {
  devices: {
    devices: Device[];
    meta: Metadata;
  };
} & Action<typeof DevicesActions.devicesSetValue>;

type DevicesSetFilter = {
  filter: {
    Status: string | null;
    ImeiSW: string | null;
    NumberSW: string | null;
  };
} & Action<typeof DevicesActions.devicesSetFilter>;

type DevicesNewValue = {
  newDevice: Device | null;
} & Action<typeof DevicesActions.devicesNewValue>;

type DevicesNewValueUpdate = {
  newDevice: Device;
} & Action<typeof DevicesActions.devicesNewValueUpdate>;

export type DevicesAction =
  | DevicesUpdate
  | DevicesSetValue
  | DevicesNewValue
  | DevicesSetFilter
  | DevicesNewValueUpdate;
