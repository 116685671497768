import produce from 'immer';
import { setLoadable } from 'utils/state.handlers';
import { DepartmentAction, DepartmentActions } from 'types/redux/actions/department.action';
import { initDepartmentState, DepartmentState } from 'types/redux/state/department.state';

export const departmentReducer = (
  state = initDepartmentState(),
  action: DepartmentAction,
): DepartmentState =>
  produce(state, (clone) => {
    const current = clone.departments.find((d) => d.id === action.departmentId);
    if (!current) return;
    switch (action.type) {
      case DepartmentActions.departmentUpdate:
        setLoadable(current.municipios, action.isLoading, action.error);
        break;
      case DepartmentActions.departmentSetValue:
        setLoadable(current.municipios, false);
        current.municipios.data = action.municipios;
        break;
      case DepartmentActions.departmentChange:
        clone.currentId = action.departmentId;
        break;
      case DepartmentActions.departmentGet:
        setLoadable(current.municipios, false);
        current.municipios.data = action.departments;
        break;
    }
  });
