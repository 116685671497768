import React from 'react';
import { useThunkDispatch, useTypedSelector } from 'hooks';
import { Styles, getModalStyle } from '../Tools';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { Provider } from '../../types/api/responses';
import Container from '@material-ui/core/Container';

import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

import { updateProvider, resetProvider, newProvider } from 'redux/actions/providers.actions';
import CircularProgress from '@material-ui/core/CircularProgress';

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .min(3, 'username should be of minimum 3 characters length')
    .max(50, 'username should be of maximun 50 characters length')
    .required('username is required'),
});

export function NewProvider({
  provider,
  isNew,
  handleClose,
  updateList,
}: {
  provider: Provider;
  isNew: boolean;
  handleClose: (flag: true) => void;
  updateList: () => void;
}) {
  const classes = Styles();
  const dispatch = useThunkDispatch();
  const [modalStyle] = React.useState(getModalStyle(40, 20));
  const providers = useTypedSelector((u) => u.providers);

  const formik = useFormik({
    initialValues: {
      name: provider.name,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleClose(true);
      if (isNew) {
        dispatch(newProvider(values.name.trim(), updateList));
      } else {
        dispatch(
          updateProvider({
            id: provider.id,
            name: values.name.trim(),
            active: provider.active,
          }),
        );
      }
    },
  });

  const saveIn = (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      className={classes.submit}
    >
      Guardar
    </Button>
  );

  return (
    <Container className={classes.paper}>
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        {!isNew ? (
          <Paper className={classes.paper}>Editando Proveedor: {provider.name}</Paper>
        ) : (
          <Paper className={classes.paper}>Creando Proveedor</Paper>
        )}
        <TextField
          onChange={formik.handleChange}
          label="nombre"
          margin="normal"
          inputProps={{ maxLength: 50 }}
          required
          fullWidth
          id="name"
          name="name"
          autoFocus
          value={formik.values.name}
          error={
            (formik.touched.name && Boolean(formik.errors.name)) ||
            Boolean(providers.error?.name === '1009')
          }
          helperText={
            (formik.touched.name && formik.errors.name) ||
            (Boolean(providers.error?.name === '1009') && 'Nombre ya registrado')
          }
          variant="outlined"
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>{saveIn}</div>
      </form>
    </Container>
  );
}
