import { CSSProperties } from 'react';
import { createMuiTheme, createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const Styles = makeStyles((theme: Theme) =>
  createStyles({
    rootForm: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    flexWrap: {
      display: 'flex',
      justifyContent: 'center',
      gap: '1em',
      width: '100%',
      flexFlow: 'wrap',
    },
    papermodal: {
      position: 'absolute',
      height: '85%',
      width: '85%',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(12, 1fr)',
      gridGap: theme.spacing(3),
    },
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingLeft: '5px',
      paddingRight: '5px',
      textAlign: 'center',
      color: theme.palette.text.secondary,
      whiteSpace: 'nowrap',
      marginBottom: theme.spacing(1),
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      margin: '5px',
    },
    paperModal: {
      position: 'absolute',
      height: '85%',
      width: '85%',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    paperModalNewUser: {
      position: 'absolute',
      height: '70%',
      width: '50%',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    paperModalNewProvider: {
      position: 'absolute',
      height: '40%',
      minHeight: '220px',
      width: '50%',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    divider: {
      textAlign: 'justify',
      minWidth: '1000px',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    centerer: {
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center',
      alignSelf: 'center',
      alignItems: 'center',
      justifyItems: 'center',
      width: '100%',
    },
    buttons: {
      width: 'fit-content',
      backgroundColor: 'inherit',
      color: 'inherit',
    },
    avatar: {
      margin: theme.spacing(1),
      '& img': {
        width: '100%',
      },
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
    },
    table: {
      minWidth: 700,
    },
    submit: {
      margin: theme.spacing(1, 0, 0),
      marginLeft: '5px',
    },
    gridList: {
      width: '100%',
      height: '100%',
    },
    gridListUser: {
      width: '100%',
      height: '100%',
    },
    flexColumnCenter: {
      height: 'fit-content',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    flexRowCenter: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

export const myTheme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#1976d2',
    },
    secondary: {
      // Purple and green play nicely together.
      main: '#11cb5f',
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiBottomNavigationAction: {
      // Name of the rule
      root: {
        // Some CSS
        color: 'white',
      },
    },
    MuiBottomNavigation: {
      // Name of the rule
      root: {
        // Some CSS
        color: 'inerit',
        backgroundColor: 'inerit',
      },
    },
  },
});

export function getModalStyle(top: number, left: number): CSSProperties {
  //const top = 5;
  //const left = 5;

  return {
    //top: `${top}%`,
    //left: `${left}%`,
    transform: `translate(${top}%, ${left}%)`,
    overflowY: 'scroll',
  };
}
