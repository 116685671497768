import { LocalStorageHandler } from 'utils/Local.storage.handler';
import { Navigation } from 'components/Navigation';
import React from 'react';
import { RouteProps } from 'react-router';
import { Routes } from 'config/routes';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { Route, Redirect } from 'react-router-dom';

type AppRouteProps = {
  accessLevel: number;
} & Omit<RouteProps, 'render'>;

type AppRoutePropsList = {
  accessLevel: number[];
} & Omit<RouteProps, 'render'>;

export const LoginAcces = ({ children, ...props }: RouteProps) => {
  const user = useTypedSelector((s) => s.session.user);

  const render = (): React.ReactNode => {
    if (user.role.id !== 0) {
      return <Redirect to={Routes.home} />;
    } else {
      return children;
    }
  };

  return (
    <>
      <Route {...props}>{render}</Route>
    </>
  );
};

export const AppRoute = ({ accessLevel, children, ...props }: AppRouteProps) => {
  const user = useTypedSelector((s) => s.session.user);
  const token = LocalStorageHandler.getToken();

  const render = (): React.ReactNode => {
    if (!token) {
      return <Redirect to={Routes.login} />;
    }
    if (user.role.accessLevel < accessLevel) {
      return <Redirect to={Routes.login} />;
    }
    return children;
  };
  return (
    <>
      <Navigation />
      <Route {...props}>{render}</Route>
    </>
  );
};

export const AppRouteList = ({ accessLevel, children, ...props }: AppRoutePropsList) => {
  const user = useTypedSelector((s) => s.session.user);
  const token = LocalStorageHandler.getToken();

  const render = (): React.ReactNode => {
    if (!token) {
      return <Redirect to={Routes.login} />;
    }
    let flag = false;
    accessLevel.forEach((e) => {
      if (e == user.role.accessLevel) {
        flag = true;
      }
    });
    if (!flag) {
      return <Redirect to={Routes.home} />;
    }
    return children;
  };
  return (
    <>
      <Navigation />
      <Route {...props}>{render}</Route>
    </>
  );
};
