import { initSessionState } from 'redux/initial.state';
import produce from 'immer';
import { SessionState } from 'types/redux/state/session.state';
import { setLoadable } from 'utils/state.handlers';
import { SessionAction, SessionActions } from 'types/redux/actions/session.action';

export const sessionReducer = (
  state = initSessionState(),
  action: SessionAction,
): SessionState =>
  produce(state, (clone) => {
    switch (action.type) {
      case SessionActions.loginUpdate:
        setLoadable(clone, action.isLoading, action.error);
        break;
      case SessionActions.loginSetValue:
        setLoadable(clone, false);
        clone.user = action.user;
        break;
      case SessionActions.logoutSetValue:
        const newState = initSessionState();
        newState.error = action.error;
        return newState;
      case SessionActions.userUpdate:
        setLoadable(clone, false);
        clone.user.name = action.name;
        clone.user.lastName = action.lastName;
        break;
    }
  });
