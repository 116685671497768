import { Action } from 'redux';
import { UpdateAction } from './update.action';
import { Office, Provider, WorkshopType } from 'types/api/responses';

export enum CatalogActions {
  officeUpdate = 'catalog/update/office',
  officeValue = 'catalog/set/office',
  providerUpdate = 'catalog/update/provider',
  providerValue = 'catalog/set/provider',
  typesUpdate = 'catalog/update/types',
  typesValue = 'catalog/set/types',
}

export type OfficeUpdate = UpdateAction<typeof CatalogActions.officeUpdate>;

export type OfficeSet = {
  offices: Office[];
} & Action<typeof CatalogActions.officeValue>;

export type ProviderUpdate = UpdateAction<typeof CatalogActions.providerUpdate>;

export type ProviderSet = {
  providers: Provider[];
} & Action<typeof CatalogActions.providerValue>;

export type TypesUpdate = UpdateAction<typeof CatalogActions.typesUpdate>;

export type TypesSet = {
  types: WorkshopType[];
} & Action<typeof CatalogActions.typesValue>;

export type CatalogAction =
  | OfficeUpdate
  | OfficeSet
  | ProviderUpdate
  | ProviderSet
  | TypesUpdate
  | TypesSet;
