import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Styles } from 'components/Tools';
import { useThunkDispatch, useTypedSelector } from 'hooks';
import React from 'react';
import { NewUser, Users } from 'types/api/responses';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import EditIcon from '@material-ui/icons/Edit';
import { getDevice, updateUserStatus } from 'redux/actions/users.actions';

export function AdminUserComponent({
  item,
  handleChangeModal,
  handleAction,
}: {
  item: Users;
  handleChangeModal: ({ user, flag }: { user: NewUser; flag: boolean }) => void;
  handleAction: ({ active }: { active: boolean }) => void;
}) {
  const classes = Styles();
  const dispatch = useThunkDispatch();
  const users = useTypedSelector((u) => u.users);
  const myRole = useTypedSelector((u) => u.session).user.role.accessLevel;
  const handleDesactive = ({ active }: { active: boolean }) => {
    handleAction({ active: true });
    dispatch(updateUserStatus(item.userName, active));
  };

  return (
    <div className={classes.flexWrap}>
      <BottomNavigation
        showLabels
        onClick={() => {
          dispatch(getDevice(item.userName));
          handleChangeModal({
            user: {
              userName: item.userName,
              name: item.name,
              lastName: item.lastName,
              active: item.active,
              accessLevel: item.role.accessLevel,
              branchOfficeId: item.branchOffice?.id ? item.branchOffice?.id : null,
              zoneId: item.branchOffice?.zone.id ? item.branchOffice?.zone.id : null,
            },
            flag: false,
          });
        }}
        className={classes.buttons}
      >
        <BottomNavigationAction
          label="Editar"
          icon={<EditIcon />}
          style={{ color: 'blue', margin: '0px' }}
        />
      </BottomNavigation>
      {users.isLoading ? (
        <div>
          <CircularProgress />
        </div>
      ) : (
        <div>
          {item.active ? (
            <BottomNavigation
              showLabels
              onClick={() => {
                handleDesactive({ active: false });
              }}
              className={classes.buttons}
            >
              <BottomNavigationAction
                label="bloquear"
                icon={<LockIcon />}
                style={{ color: 'red' }}
              />
            </BottomNavigation>
          ) : (
            <BottomNavigation
              showLabels
              onClick={() => {
                handleDesactive({ active: true });
              }}
              className={classes.buttons}
            >
              <BottomNavigationAction
                label="desbloquear"
                icon={<LockOpenIcon />}
                style={{ color: 'blue' }}
              />
            </BottomNavigation>
          )}
        </div>
      )}
    </div>
  );
}
