import { catalogReducer } from './catalog.reducer';
import { combineReducers } from 'redux';
import { departmentReducer } from './department.reducer';
import { devicesReducer } from './devices.reducer';
import { profileReducer } from './profile.reducer';
import { providersReducer } from './providers.reducer';
import { RESET_STORE } from '../actions/types.actions';
import { sessionReducer } from './session.reducer';
import { usersReducer } from './users.reducer';
import { workshopReducer } from './worshop.reducer';
import { workshopTypesReducer } from './workshopTypes.reducer';
import { zonesReducer } from './zones.reducer';

const appReducer = combineReducers({
  catalog: catalogReducer,
  devices: devicesReducer,
  departments: departmentReducer,
  profile: profileReducer,
  providers: providersReducer,
  session: sessionReducer,
  users: usersReducer,
  workshop: workshopReducer,
  workshopTypes: workshopTypesReducer,
  zones: zonesReducer,
});

export const rootReducer = (state: any, action: any) => {
  if (action.type === RESET_STORE) {
    state = undefined;
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
