import { initLoadable } from 'utils/state.handlers';
import { Loadable2 } from './index';
import { Provider, WorkshopType, Office } from 'types/api/responses';

export type CatalogState = {
  providers: Loadable2<Provider[]>;
  workshopTypes: Loadable2<WorkshopType[]>;
  offices: Loadable2<Office[]>;
};

export const initCatalogState = (): CatalogState => ({
  offices: initLoadable([]),
  providers: initLoadable([]),
  workshopTypes: initLoadable([]),
});
