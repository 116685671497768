import classnames from 'classnames';
import { EditWorkshopRequest } from 'types/api/request';
import { FormikForm } from 'types/common';
import { Profile } from 'types/api/responses';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';
import TextField from '@material-ui/core/TextField';
import WarningIcon from '@material-ui/icons/Warning';
import { FormikTouched, FormikErrors } from 'formik/dist/types';
import React, { ChangeEvent } from 'react';

type ShopStringKey = keyof {
  [P in keyof EditWorkshopRequest as EditWorkshopRequest[P] extends string | null
    ? P
    : never]: P;
};

export type TextInputProps = {
  label: string;
  form: FormikForm<EditWorkshopRequest>;
  profile?: Profile | null;
  shopKey: ShopStringKey;
  unique?: boolean;
  className?: string;
  inputProps: StandardInputProps['inputProps'];
};

export const TextInput = ({
  label,
  form,
  profile,
  className,
  inputProps,
  shopKey,
  unique = false,
}: TextInputProps) => {
  const errors = form.errors as FormikErrors<EditWorkshopRequest>;
  const touched = form.touched as FormikTouched<EditWorkshopRequest>;
  const value: string = form.values[shopKey] || '';
  const profileValue = profile?.[shopKey as keyof Profile] || '';
  const isNotSame =
    value.localeCompare(profileValue.toString(), undefined, {
      sensitivity: 'base',
    }) !== 0 && !unique;

  const hasError = Boolean(errors[shopKey]) && touched[shopKey];
  const message = hasError && errors[shopKey];
  const classes = classnames('form-read-only', className);

  const onChange = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input = evt.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    evt.target.value = evt.target.value.toUpperCase();
    form.handleChange(evt);
    input.selectionStart = start;
    input.selectionEnd = end;
  };

  return (
    <div className={classes}>
      <TextField
        id={shopKey}
        name={shopKey}
        label={label}
        variant="outlined"
        value={value}
        inputProps={inputProps}
        error={hasError}
        helperText={message}
        InputProps={{
          endAdornment: isNotSame && <WarningIcon style={{ color: 'orange' }} />,
        }}
        onChange={onChange}
      />
    </div>
  );
};
