import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import { Alert, Styles } from 'components/Tools';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useFormik } from 'formik';
import { useThunkDispatch, useTypedSelector } from 'hooks';
import * as yup from 'yup';
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import { changeMePassword, updateUser } from '../../redux/actions/users.actions';
import { updateMyUser } from '../../redux/actions/session.actions';
import Snackbar from '@material-ui/core/Snackbar';

const validationSchemaProfile = yup.object({
  name: yup
    // @ts-ignore
    .string('Enter your username')
    .min(3, 'username should be of minimum 3 characters length')
    .max(50, 'username should be of maximun 50 characters length')
    .required('username is required'),
  lastName: yup
    // @ts-ignore
    .string('Enter your username')
    .min(3, 'username should be of minimum 3 characters length')
    .max(50, 'username should be of maximun 50 characters length')
    .required('username is required'),
});

const validationSchemaPassword = yup.object({
  password: yup
    // @ts-ignore
    .string('Enter your password')
    .max(50, 'username should be of maximun 50 characters length'),
  passwordN: yup
    // @ts-ignore
    .string('Enter your password')
    .min(6, 'debe tener al menos 6 caracteres')
    .matches(/[a-z]+/, 'Debe tener al menos una minuscula')
    .matches(/[A-Z]+/, 'Debe tener al menos una mayuscula')
    .matches(/[0-9]+/, 'Debe tener al menos un numero')
    .max(50, 'username should be of maximun 50 characters length'),
  passwordV: yup
    // @ts-ignore
    .string('Enter your password')
    .oneOf([yup.ref('passwordN'), null], 'Contraseña No Coincide')
    .max(50, 'username should be of maximun 50 characters length'),
});

const EditProfile = ({ handleActive }: { handleActive: () => void }) => {
  const classes = Styles();
  const session = useTypedSelector((s) => s.session);
  const dispatch = useThunkDispatch();
  const formik = useFormik({
    initialValues: {
      name: session.user.name,
      lastName: session.user.lastName,
    },
    validationSchema: validationSchemaProfile,
    onSubmit: (values) => {
      handleActive();
      dispatch(
        updateUser(
          session.user.userName,
          {
            //userName: session.user.userName,
            name: values.name,
            lastName: values.lastName,
            accessLevel: session.user.role.accessLevel,
            branchOfficeId: session.user.branchOffice?.id
              ? session.user.branchOffice?.id
              : null,
            deviceImei: null,
            //password: '123456',
            //active: true,
          },
          handleUpdateMyProfile,
        ),
      );
    },
  });

  const handleUpdateMyProfile = (name: string, lastName: string) => {
    dispatch(updateMyUser(name, lastName));
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        {session.isLoading && <CircularProgress />}
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <TextField
            onChange={formik.handleChange}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Nombre"
            name="name"
            inputProps={{ maxLength: 50 }}
            autoComplete="name"
            autoFocus
            value={formik.values.name}
            error={
              (formik.touched.name && Boolean(formik.errors.name)) ||
              Boolean(session.error?.name === '1008')
            }
            helperText={
              (formik.touched.name && formik.errors.name) ||
              (Boolean(session.error?.name === '1008') && 'Nombre de usuario no valido')
            }
          />
          <TextField
            onChange={formik.handleChange}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="lastName"
            label="Apellido"
            name="lastName"
            autoComplete="lastName"
            autoFocus
            inputProps={{ maxLength: 50 }}
            value={formik.values.lastName}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Guardar Datos
          </Button>
        </form>
      </div>
    </Container>
  );
};

function EditPassword({
  handleChangeSuccess,
  handleActive,
}: {
  handleChangeSuccess: (flag: boolean) => void;
  handleActive: () => void;
}) {
  const classes = Styles();
  const session = useTypedSelector((s) => s.session);
  const dispatch = useThunkDispatch();
  const formik = useFormik({
    initialValues: {
      password: '',
      passwordN: '',
      passwordV: '',
    },
    validationSchema: validationSchemaPassword,
    onSubmit: (values) => {
      //alert('save password');
      handleActive();
      dispatch(
        changeMePassword(
          {
            oldPassword: values.password,
            newPassword: values.passwordN,
          },
          handleChangeSuccess,
        ),
      );
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        {session.isLoading && <CircularProgress />}
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            required
            onChange={formik.handleChange}
            value={formik.values.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            name="password"
            inputProps={{ maxLength: 50 }}
            label="Contraseña antigua"
            type="password"
            id="password"
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            required
            onChange={formik.handleChange}
            value={formik.values.passwordN}
            error={formik.touched.passwordN && Boolean(formik.errors.passwordN)}
            helperText={formik.touched.passwordN && formik.errors.passwordN}
            name="passwordN"
            inputProps={{ maxLength: 50 }}
            label="Nueva Contraseña"
            type="password"
            id="passwordN"
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            required
            onChange={formik.handleChange}
            value={formik.values.passwordV}
            error={formik.touched.passwordV && Boolean(formik.errors.passwordV)}
            helperText={formik.touched.passwordV && formik.errors.passwordV}
            name="passwordV"
            inputProps={{ maxLength: 50 }}
            label="Verificar Nueva Contraseña"
            type="password"
            id="passwordV"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Guardar Contraseña
          </Button>
        </form>
        {session.error?.name && <p>Error {session.error.message}</p>}
      </div>
    </Container>
  );
}

export const Profile = () => {
  const classes = Styles();
  const session = useTypedSelector((s) => s.session);
  const users = useTypedSelector((s) => s.users);
  const [editUser, setEditUser] = React.useState(false);
  const [editPassword, setEditPassword] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [modalActive, setmodalActive] = React.useState(false);
  const dispatch = useThunkDispatch();

  const handleChangeSuccess = (flag: boolean) => {
    setSuccess(true);
  };

  const handleChangeEditUser = () => {
    setEditUser(true);
  };
  const handleChangeEditPassword = () => {
    setEditPassword(true);
  };

  const handleReset = () => {
    setEditUser(false);
    setEditPassword(false);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setmodalActive(false);
    handleReset();
  };

  const handleActive = () => {
    setmodalActive(true);
    handleReset();
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        {session.isLoading && <CircularProgress />}
        {!editPassword && !editUser ? (
          <div className={classes.flexColumnCenter}>
            <Paper className={classes.paper}>{session.user.name}</Paper>
            <Paper className={classes.paper}>{session.user.lastName}</Paper>
            <div className={classes.flexColumnCenter} style={{ alignItems: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleChangeEditUser}
              >
                Editar Perfil
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleChangeEditPassword}
              >
                Editar Contraseña
              </Button>
            </div>
          </div>
        ) : (
          <div>
            {editUser ? (
              <EditProfile handleActive={handleActive} />
            ) : (
              <EditPassword
                handleChangeSuccess={handleChangeSuccess}
                handleActive={handleActive}
              />
            )}
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleReset}
            >
              Cancelar
            </Button>
          </div>
        )}
        <Snackbar open={modalActive} autoHideDuration={15000} onClose={handleClose}>
          {session.isLoading ? (
            <Alert onClose={handleClose} severity="info">
              <div>Editando Tipo de Taller...</div>
            </Alert>
          ) : (
            <div>
              {session.error || users.error ? (
                <Alert onClose={handleClose} severity="error">
                  <div>
                    ocurrio un error {session.error?.message} {users.error?.message}
                  </div>
                </Alert>
              ) : (
                <Alert onClose={handleClose} severity="success">
                  <div>Cambios guardados con exito</div>
                </Alert>
              )}
            </div>
          )}
        </Snackbar>
      </div>
    </Container>
  );
};
