export const endpoint = {
  auth: {
    login: '/auth/login',
    revoke: '/auth/revoke',
  },
  workshop: '/workshop',
  municipios: '/department',
  users: '/user',
  zones: '/zone',
  branchOffcie: '/office',
  providers: '/provider',
  devices: '/device',
  workshopTypes: '/workshopType',
  profile: '/profile',
  reportExcel: '/report/diff',
} as const;
