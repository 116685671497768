import { AccessLevels } from '../../config/access.levels';
import { ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, { useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { userAll, userOffices } from 'redux/actions/users.actions';

import { useThunkDispatch, useTypedSelector } from 'hooks';
import { shouldReload } from '../../utils/state.handlers';

import { myTheme, Styles, getModalStyle, StyledTableCell, StyledTableRow } from '../Tools';
import { NewUsers } from './NewUsers';
import Modal from '@material-ui/core/Modal';

import { NewUser } from '../../types/api/responses';
import Pagination from '@material-ui/lab/Pagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FilterUserComponent } from './FilterUserComponent';
import { AdminUserComponent } from './AdminUserComponent';
import { Alert, RolesList } from '../Tools';
import { DeviceDetailsUser } from './DeviceDetailsUser';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
const SIZE = 9;

export function NameRole({ name }: { name: string }) {
  const item = RolesList.find((w) => w.id === name);
  const classes = Styles();
  return <Paper className={classes.paper}>{item?.name}</Paper>;
}

export function ListUser() {
  const classes = Styles();
  const users = useTypedSelector((u) => u.users);
  const myRole = useTypedSelector((u) => u.session).user.role.accessLevel;
  const [page, setPage] = React.useState(1);

  //modal
  const [modalStyle] = React.useState(getModalStyle(40, 20));
  const [openModal, setOpenModal] = React.useState({
    open: false,
    loading: false,
  });
  const [theUser, setTheUser] = React.useState<NewUser>({
    userName: '',
    name: '',
    lastName: '',
    active: true,
    accessLevel: 0,
    branchOfficeId: '',
    zoneId: 0,
  });
  //const [userCurrent, setUserCurrent] = React.useState<Users | undefined>(undefined);
  const [isNew, setIsNew] = React.useState(true);
  const [modalActive, setmodalActive] = React.useState(false);
  const dispatch = useThunkDispatch();

  const [resetFilter, setResetFilter] = React.useState(false);
  const succesRestet = () => {
    setResetFilter(true);
    setResetFilter(false);
  };

  useEffect(() => {
    if (shouldReload(users)) {
      dispatch(
        userAll(
          { size: SIZE, page: 1 },
          {
            NameContains: null,
            Status: null,
            ZoneId: null,
            OfficeId: null,
            //Device: null,
          },
        ),
      );
    }
  }, [users]);

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    dispatch(userAll({ size: SIZE, page: newPage }, users.filter));
    setPage(newPage);
  };

  const handleOpenModal = () => {
    setOpenModal({
      open: true,
      loading: false,
    });
  };

  const handleCloseModal = (loading: boolean | null, error?: boolean) => {
    if (loading === null && !openModal.loading) {
      if (users.filter.ZoneId !== 0) {
        if (users.filter.ZoneId !== users.branchOffices.offices[0]?.zone.id) {
          dispatch(userOffices({ ZoneId: users.filter.ZoneId }));
        }
      }
      setOpenModal({
        open: false,
        loading: openModal.loading,
      });
    } else if (loading) {
      setOpenModal({
        open: openModal.open,
        loading: loading,
      });
    } else if (loading === false) {
      if (error) {
        setOpenModal({
          open: true,
          loading: false,
        });
      } else {
        setOpenModal({
          open: false,
          loading: false,
        });
      }
    }
  };

  const handleChangeModal = ({ user, flag }: { user: NewUser; flag: boolean }) => {
    setTheUser(user);
    setIsNew(flag);
    handleOpenModal();
  };

  const handleDesactive = ({ active }: { active: boolean }) => {
    setmodalActive(false);
    setmodalActive(true);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setmodalActive(false);
  };

  return (
    <ThemeProvider theme={myTheme}>
      <Modal
        open={openModal.open}
        onClose={() => {
          handleCloseModal(null);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paperModalNewUser}>
          <div id={'modal-container'} style={{ height: '100%' }}>
            <NewUsers
              isNew={isNew}
              user={theUser}
              //userCurrent={userCurrent}
              handleClose={handleCloseModal}
            />
          </div>
        </div>
      </Modal>
      <Snackbar open={modalActive} autoHideDuration={6000} onClose={handleClose}>
        {users.isLoading ? (
          <Alert onClose={handleClose} severity="info">
            <div>Editando Usuario...</div>
          </Alert>
        ) : (
          <div>
            {users.error ? (
              <Alert onClose={handleClose} severity="error">
                <div>ocurrio un error {users.error.message}</div>
              </Alert>
            ) : (
              <Alert onClose={handleClose} severity="success">
                <div>Se edito con exito el Usuario</div>
              </Alert>
            )}
          </div>
        )}
      </Snackbar>
      <div className={classes.divider}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Grid item xs={10} style={{ width: '100%' }}>
            <Grid item xs={11} style={{ width: '100%' }}>
              {resetFilter ? null : (
                <FilterUserComponent
                  handleChangeModal={handleChangeModal}
                  successReset={succesRestet}
                />
              )}
            </Grid>
          </Grid>
          <TableContainer style={{ maxHeight: '75vh', maxWidth: '95vw', minWidth: '1000px' }}>
            <Table className={classes.table} stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>userName</StyledTableCell>
                  <StyledTableCell align="center">Rol</StyledTableCell>
                  <StyledTableCell align="center">Nombre</StyledTableCell>
                  <StyledTableCell align="center">Apellido</StyledTableCell>
                  <StyledTableCell align="center">Sucursal</StyledTableCell>
                  <StyledTableCell align="center">Zona</StyledTableCell>
                  <StyledTableCell align="center">Detalles</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.users.users.map((item, flag) => {
                  return (
                    <StyledTableRow key={item.userName}>
                      <StyledTableCell component="th" scope="row">
                        {item.userName}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {RolesList.find((w) => w.id === item.role.name)?.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                      <StyledTableCell align="center">{item.lastName}</StyledTableCell>
                      <StyledTableCell align="center">
                        {item.role.accessLevel < AccessLevels.ZoneManager
                          ? item.branchOffice?.name
                          : null}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item.role.accessLevel < AccessLevels.Coordinator
                          ? item.branchOffice?.zone.name
                          : null}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div className={classes.flexWrap}>
                          {item.role.accessLevel < AccessLevels.Coordinator ? (
                            <DeviceDetailsUser user={item.userName} />
                          ) : (
                            <div style={{ width: '85px' }} />
                          )}
                          {item.role.accessLevel < myRole &&
                          !(
                            myRole == AccessLevels.HelpDesk &&
                            item.role.accessLevel == AccessLevels.CommercialManager
                          ) ? (
                            <div>
                              <AdminUserComponent
                                item={item}
                                handleChangeModal={handleChangeModal}
                                handleAction={handleDesactive}
                              />
                            </div>
                          ) : (
                            <div />
                          )}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {users.isLoading ? (
              <CircularProgress />
            ) : (
              <Pagination
                count={users.users.meta?.pages}
                variant="outlined"
                shape="rounded"
                page={page}
                onChange={handleChangePage}
              />
            )}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
