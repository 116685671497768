import { Action } from 'redux';
import { Users } from 'types/api/responses';

export enum SessionActions {
  loginUpdate = 'session/login/update',
  loginSetValue = 'session/login/set',
  logoutSetValue = 'session/login/logout',
  userUpdate = 'session/login/userUpdate',
}

type LoginUpdate = {
  isLoading: boolean;
  error?: Error;
} & Action<typeof SessionActions.loginUpdate>;

type LoginValue = {
  user: Users;
} & Action<typeof SessionActions.loginSetValue>;

type LogoutSetValue = {
  error?: Error;
} & Action<typeof SessionActions.logoutSetValue>;

type UserUpdate = {
  name: string;
  lastName: string;
} & Action<typeof SessionActions.userUpdate>;

export type SessionAction = LoginUpdate | LoginValue | LogoutSetValue | UserUpdate;
