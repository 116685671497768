import { Action } from 'redux';
import { Metadata, Workshop, Office, Provider } from 'types/api/responses';

export enum WorkshopActions {
  workshopUpdate = 'workshop/update',
  workshopAll = 'workshop/all',
  workshopFilter = 'workshop/filter',
  workshopSetOffices = 'workshop/offices',
  workshopSetProviders = 'workshop/set/providers',
  workshopSetAllOffciesEdit = 'workshop/set/allOffciesEdit',
  workshopUpdateItem = 'workshop/updateItem',
}

type WorkshopUpdate = {
  isLoading: boolean;
  error?: Error;
} & Action<typeof WorkshopActions.workshopUpdate>;

type WorkshopAll = {
  workshops: {
    workshops: Workshop[];
    meta: Metadata;
  };
} & Action<typeof WorkshopActions.workshopAll>;

type WorkshopFilter = {
  filter: {
    NameContains: string | null;
    Status: string | null;
    ZoneId: number | null;
    OfficeId: string | null;
    DaysBefore: number | null;
    Account: string | null;
  };
} & Action<typeof WorkshopActions.workshopFilter>;

type WorkshopSetOffices = {
  branchOffices: {
    offices: Office[];
    meta: Metadata;
  };
} & Action<typeof WorkshopActions.workshopSetOffices>;

type WorkshopSetProviders = {
  edition: {
    providers: Provider[];
  };
} & Action<typeof WorkshopActions.workshopSetProviders>;

type WorkshopSetAllOfficesEdit = {
  offices: Office[];
} & Action<typeof WorkshopActions.workshopSetAllOffciesEdit>;

type WorkshopUpdateItem = {
  workshop: Workshop;
} & Action<typeof WorkshopActions.workshopUpdateItem>;

export type WorkshopAction =
  | WorkshopUpdate
  | WorkshopAll
  | WorkshopFilter
  | WorkshopSetOffices
  | WorkshopSetProviders
  | WorkshopSetAllOfficesEdit
  | WorkshopUpdateItem;
