import { initWorkshopTypesState } from 'redux/initial.state';
import produce from 'immer';
import { setLoadable } from 'utils/state.handlers';
import { WorkshopTypesState } from 'types/redux/state/workshopTypes.state';
import {
  WorkshopTypesAction,
  WorkshopTypesActions,
} from 'types/redux/actions/workshopTypes.action';

export const workshopTypesReducer = (
  state = initWorkshopTypesState(),
  action: WorkshopTypesAction,
): WorkshopTypesState =>
  produce(state, (clone) => {
    switch (action.type) {
      case WorkshopTypesActions.workshopTypesUpdate:
        setLoadable(clone, action.isLoading, action.error);
        break;
      case WorkshopTypesActions.workshopTypesSetValue:
        setLoadable(clone, false);
        clone.workshopTypes = action.workshopTypes;
        break;
      case WorkshopTypesActions.workshopTypesSetFilter:
        setLoadable(clone, false);
        clone.filter = action.filter;
        break;
      case WorkshopTypesActions.workshopTypeNewValue:
        setLoadable(clone, false);
        clone.newWorkshopType = action.workshopType;
        break;
      case WorkshopTypesActions.workshopTypeNewValueUpdate:
        setLoadable(clone, false);
        const update = clone.workshopTypes.workshopTypes.find(
          (w) => w.id === action.workshopType.id,
        );
        if (update) {
          update.name = action.workshopType.name;
          update.active = action.workshopType.active;
        }
        break;
    }
  });
