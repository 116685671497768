import { EditWorkshopRequest } from 'types/api/request';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import React from 'react';
import { ReadOnlyField } from './ReadOnlyField';
import { FormikErrors, FormikTouched } from 'formik/dist/types';
import { FormikForm, Identifiable } from 'types/common';
import { Select, InputLabel, MenuItem } from '@material-ui/core';

export type SelectInputProps<T> = {
  fieldId: keyof EditWorkshopRequest;
  label: string;
  form: FormikForm<EditWorkshopRequest>;
  options: readonly T[];
  none?: number | string;
  keyValue: keyof T;
  readonly: boolean;
  readonlyValue?: string;
};

export const SelectInput = <T extends Identifiable>({
  label,
  fieldId,
  keyValue,
  options,
  form,
  none,
  readonly,
  readonlyValue,
}: SelectInputProps<T>) => {
  const value = form.values[fieldId] || none;
  const errors = form.errors as FormikErrors<EditWorkshopRequest>;
  const touched = form.touched as FormikTouched<EditWorkshopRequest>;
  const hasError = Boolean(errors[fieldId]) && touched[fieldId];
  const message = hasError && errors[fieldId];

  if (readonly) {
    return <ReadOnlyField label={label} value={readonlyValue} />;
  }

  return (
    <div className="form-read-only">
      <FormControl>
        <InputLabel id={`label-${fieldId}`}>{label}</InputLabel>
        <Select
          id={fieldId}
          name={fieldId}
          labelId={`label-${fieldId}`}
          value={value}
          onChange={form.handleChange}
          error={hasError}
        >
          {none !== undefined && (
            <MenuItem key="none" value={none}>
              <em>Ninguno</em>
            </MenuItem>
          )}
          {options.map((o) => (
            <MenuItem key={o.id} value={o.id}>
              {o[keyValue]}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error>{message}</FormHelperText>
      </FormControl>
    </div>
  );
};
