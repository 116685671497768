import { initWorkshopState } from 'redux/initial.state';
import produce from 'immer';
import { setLoadable } from 'utils/state.handlers';
import { WorkshopState } from 'types/redux/state/workshop.state';
import { WorkshopAction, WorkshopActions } from 'types/redux/actions/workshop.action';

export const workshopReducer = (
  state = initWorkshopState(),
  action: WorkshopAction,
): WorkshopState =>
  produce(state, (clone) => {
    switch (action.type) {
      case WorkshopActions.workshopUpdate:
        setLoadable(clone, action.isLoading, action.error);
        if (action.error) {
          clone.workshops.workshops = [];
        }
        break;
      case WorkshopActions.workshopAll:
        clone.workshops = action.workshops;
        setLoadable(clone, false);
        break;
      case WorkshopActions.workshopFilter:
        clone.filter = action.filter;
        setLoadable(clone, false);
        break;
      case WorkshopActions.workshopSetOffices:
        setLoadable(clone, false);
        clone.branchOffices = action.branchOffices;
        break;
      case WorkshopActions.workshopSetProviders:
        setLoadable(clone, false);
        break;
      case WorkshopActions.workshopSetAllOffciesEdit:
        setLoadable(clone, false);
        break;
      case WorkshopActions.workshopUpdateItem:
        setLoadable(clone, false);
        const update = clone.workshops.workshops.findIndex((w) => w.id === action.workshop.id);
        if (update >= 0) {
          clone.workshops.workshops[update] = action.workshop;
        }
        break;
    }
  });
