import classnames from 'classnames';
import { EditWorkshopRequest } from 'types/api/request';
import { FormikForm } from 'types/common';
import { Profile } from 'types/api/responses';
import React from 'react';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';
import { TextField } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { FormikErrors, FormikTouched } from 'formik/dist/types';

type ShopNumberKey = keyof {
  [P in keyof EditWorkshopRequest as EditWorkshopRequest[P] extends number | undefined | null
    ? P
    : never]: P;
};

export type NumericInputProps = {
  label: string;
  numKey: ShopNumberKey;
  className?: string;
  form: FormikForm<EditWorkshopRequest>;
  step?: number;
  profile?: Profile | null;
  inputProps?: StandardInputProps['inputProps'];
};

export const NumericInput = ({
  label,
  numKey,
  className,
  inputProps,
  step = 1,
  profile,
  form,
}: NumericInputProps) => {
  const value = form.values[numKey] || 0;
  const errors = form.errors as FormikErrors<EditWorkshopRequest>;
  const touched = form.touched as FormikTouched<EditWorkshopRequest>;
  const classes = classnames('form-read-only', className);

  const hasError = Boolean(errors[numKey]) && touched[numKey];
  const message = hasError && errors[numKey];

  const isNotSame = profile && value !== profile[numKey as keyof Profile];

  return (
    <div className={classes}>
      <TextField
        id={numKey}
        name={numKey}
        variant="outlined"
        inputProps={{ ...inputProps, type: 'number', step }}
        label={label}
        value={value}
        error={hasError}
        onChange={form.handleChange}
        InputProps={{
          endAdornment: isNotSame && <WarningIcon style={{ color: 'orange' }} />,
        }}
        helperText={message}
      />
    </div>
  );
};
