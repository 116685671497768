import persistStore from 'redux-persist/es/persistStore';
import storage from 'redux-persist/lib/storage';
import { compose, applyMiddleware, createStore, Action } from 'redux';
import { PersistConfig, persistReducer } from 'redux-persist';
import { RootState, rootReducer } from './reducers';
import thunk, { ThunkAction } from 'redux-thunk';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let storeDevTool = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
if (storeDevTool) {
  storeDevTool = storeDevTool({ serialize: true });
}
const composeEnhancer = storeDevTool || compose;

const middleware =
  process.env.NODE_ENV !== 'production'
    ? composeEnhancer(applyMiddleware(thunk))
    : applyMiddleware(thunk);

const authPersistConfig: PersistConfig<RootState> = {
  key: 'session',
  storage,
  version: 1,
  whitelist: ['session'],
};

const persistedReducer = persistReducer<RootState>(authPersistConfig, rootReducer);

export const karthusStore = createStore(persistedReducer, middleware);
export const karthusPersists = persistStore(karthusStore);

export type AppThunk<T extends Action<string> = Action<string>, R = void> = ThunkAction<
  R,
  RootState,
  unknown,
  T
>;
