type Roles = {
  level: number;
  name: string;
};

export const RolesList = [
  { level: 1, id: 'Collector', name: 'Motociclista' },
  { level: 2, id: 'StoreManager', name: 'Jefe de tienda' },
  { level: 3, id: 'ZoneManager', name: 'Gerente de Zona' },
  { level: 4, id: 'Coordinator', name: 'Coordinador' },
  { level: 5, id: 'CommercialManager', name: 'Gerente Comercial' },
  { level: 6, id: 'HelpDesk', name: 'HelpDesk' },
  { level: 7, id: 'Admin', name: 'Administrador' },
];
