import AddBoxIcon from '@material-ui/icons/AddBox';
import { Alert } from '../Tools';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditIcon from '@material-ui/icons/Edit';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import { NewWorkshopType } from './NewWorkshopType';
import Pagination from '@material-ui/lab/Pagination';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { shouldReload } from 'utils/state.handlers';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import { ThemeProvider } from '@material-ui/core/styles';
import { WorkshopType } from 'types/api/responses';
import { allWorkshopTypes, updateWorkshopType } from 'redux/actions/workshopTypes.actions';
import { useThunkDispatch, useTypedSelector } from 'hooks';
import React, { useEffect, useState } from 'react';
import { myTheme, Styles, getModalStyle, StyledTableCell, StyledTableRow } from '../Tools';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';

const SIZE = 9,
  INITPAGE = 1;

function SearchDetails() {
  const classes = Styles();

  const workshopTypes = useTypedSelector((z) => z.workshopTypes);
  //const zones = useTypedSelector((z) => z.zones);
  //const office = useTypedSelector((o) => o.branchOffices);
  const dispatch = useThunkDispatch();

  const [nameSearch, setNameSearch] = React.useState(
    workshopTypes.filter.NameContains ? workshopTypes.filter.NameContains : '',
  );
  const [openStatus, setOpenStatus] = React.useState(false);
  const [theStatus, setTheStatus] = React.useState(
    workshopTypes.filter.Status != null ? workshopTypes.filter.Status : '0',
  );
  //evento de cambio en nombre a buscar
  const handleChangeNameSearch = (event: React.ChangeEvent<{ value: unknown }>) => {
    setNameSearch(event.target.value as string);
  };

  //evento de busqueda segun nombre
  const handleSearch = () => {
    //alert(nameSearch + 'F');
    dispatch(
      allWorkshopTypes(
        { page: INITPAGE, size: SIZE },
        {
          NameContains: nameSearch,
          Status: workshopTypes.filter.Status,
        },
      ),
    );
  };

  const handleResetSearch = () => {
    setNameSearch('');
    setTheStatus('0');
    dispatch(
      allWorkshopTypes(
        { page: INITPAGE, size: SIZE },
        {
          NameContains: null,
          Status: null,
        },
      ),
    );
  };

  const handleOpenStatus = () => {
    setOpenStatus(true);
  };

  const handleClosedStatus = () => {
    setOpenStatus(false);
  };

  const handleChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;
    setTheStatus(value);
    dispatch(
      allWorkshopTypes(
        { page: INITPAGE, size: SIZE },
        {
          NameContains: workshopTypes.filter.NameContains,
          Status: value === '0' ? null : value,
        },
      ),
    );
  };

  return (
    <form className={classes.rootForm} noValidate autoComplete="off">
      {workshopTypes.isLoading ? (
        <div>
          <CircularProgress />
        </div>
      ) : (
        <div style={{ display: 'inline-flex', alignItems: 'center', marginRight: '10px' }}>
          <TextField
            id="outlined-read-only-input"
            label="Tipo Taller"
            value={nameSearch}
            inputProps={{ maxLength: 50 }}
            onChange={handleChangeNameSearch}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                ev.preventDefault();
                handleSearch();
              }
            }}
            variant="outlined"
            required
          />
          <FormControl
            variant="outlined"
            style={{ marginLeft: '10px' }}
            className={classes.formControl}
          >
            <InputLabel id="select-filter-rol">Estado</InputLabel>
            <Select
              labelId="select-filter-rol"
              id="filter-controlled-open-select-rol"
              open={openStatus}
              onClose={handleClosedStatus}
              onOpen={handleOpenStatus}
              value={theStatus}
              onChange={handleChangeStatus}
            >
              <MenuItem value={'0'}>Todos</MenuItem>
              <MenuItem value={'Disabled'}>Inactivos</MenuItem>
              <MenuItem value={'Active'}>Activos</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="outlined"
            onClick={handleResetSearch}
            className={classes.submit}
            style={{ color: 'Blue', marginRight: '5px' }}
          >
            Reset
          </Button>
        </div>
      )}
    </form>
  );
}

function ListWorkshopType() {
  const classes = Styles();
  const workshopTypes = useTypedSelector((u) => u.workshopTypes);

  //modal
  const [modalStyle] = React.useState(getModalStyle(40, 20));
  const [page, setPage] = React.useState(1);
  const [openModal, setOpenModal] = React.useState({
    open: false,
    loading: false,
  });
  const [modalActive, setmodalActive] = useState(false);
  const [theWorkshopType, setTheWorkshopType] = useState({
    id: 0,
    name: '',
    active: false,
    external: false,
  });
  const [isNew, setIsNew] = React.useState(true);
  const dispatch = useThunkDispatch();

  useEffect(() => {
    if (shouldReload(workshopTypes)) {
      dispatch(
        allWorkshopTypes({ size: SIZE, page: page }, { NameContains: null, Status: null }),
      );
    }
  }, [workshopTypes]);

  const handleOpenModal = () => {
    setOpenModal({
      open: true,
      loading: false,
    });
    setmodalActive(false);
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    dispatch(allWorkshopTypes({ size: SIZE, page: newPage }, workshopTypes.filter));
    setPage(newPage);
  };

  const updateList = () => {
    dispatch(allWorkshopTypes({ size: SIZE, page: page }, workshopTypes.filter));
  };

  const handleCloseModal = (loading: boolean | null, flag: boolean) => {
    if (loading === null && !openModal.loading) {
      setOpenModal({
        open: false,
        loading: openModal.loading,
      });
    } else if (loading) {
      setOpenModal({
        open: openModal.open,
        loading: loading,
      });
    } else if (loading === false) {
      setOpenModal({
        open: false,
        loading: loading,
      });
    }
    if (flag) {
      setmodalActive(true);
    }
  };

  const handleDesactive = ({ active, item }: { active: boolean; item: WorkshopType }) => {
    setmodalActive(false);
    setmodalActive(true);
    dispatch(
      updateWorkshopType({
        id: item.id,
        name: item.name,
        active: active,
        external: item.external,
      }),
    );
  };

  const handleChangeModal = ({
    provider,
    flag,
  }: {
    provider: WorkshopType;
    flag: boolean;
  }) => {
    setTheWorkshopType(provider);
    setIsNew(flag);
    handleOpenModal();
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setmodalActive(false);
  };

  return (
    <ThemeProvider theme={myTheme}>
      <Modal
        open={openModal.open}
        onClose={() => {
          handleCloseModal(null, false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paperModalNewProvider}>
          <div id={'modal-container'} style={{ height: '100%' }}>
            <NewWorkshopType
              workshopType={theWorkshopType}
              isNew={isNew}
              handleClose={handleCloseModal}
              updateList={updateList}
            />
          </div>
        </div>
      </Modal>
      <Snackbar open={modalActive} autoHideDuration={15000} onClose={handleClose}>
        {workshopTypes.isLoading ? (
          <Alert onClose={handleClose} severity="info">
            <div>Editando Tipo de Taller...</div>
          </Alert>
        ) : (
          <div>
            {workshopTypes.error ? (
              <Alert onClose={handleClose} severity="error">
                <div>ocurrio un error {workshopTypes.error.message}</div>
              </Alert>
            ) : (
              <Alert onClose={handleClose} severity="success">
                <div>Se edito con exito el Tipo de Taller</div>
              </Alert>
            )}
          </div>
        )}
      </Snackbar>
      <Paper className={classes.paper && classes.divider}>
        <div className={classes.flexWrap}>
          <SearchDetails />
          <BottomNavigation
            showLabels
            onClick={() => {
              handleChangeModal({
                provider: {
                  id: 0,
                  name: '',
                  active: true,
                  external: true,
                },
                flag: true,
              });
            }}
          >
            <BottomNavigationAction
              label="agregar"
              icon={<AddBoxIcon />}
              style={{ color: 'green' }}
            />
          </BottomNavigation>
        </div>
      </Paper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <TableContainer style={{ maxHeight: '75vh', maxWidth: '60vw', minWidth: '730px' }}>
          <Table className={classes.table} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Nombre Proveedor</StyledTableCell>
                <StyledTableCell align="center">Estado</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workshopTypes.workshopTypes.workshopTypes.map((item) => {
                return (
                  <StyledTableRow key={item.id}>
                    <StyledTableCell component="th" scope="row">
                      {item.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <div className={classes.flexRowCenter}>
                        <BottomNavigation
                          showLabels
                          onClick={() => {
                            handleChangeModal({ provider: item, flag: false });
                          }}
                          className={classes.buttons}
                        >
                          <BottomNavigationAction
                            label="Editar"
                            icon={<EditIcon />}
                            style={{ color: 'blue', margin: '0px' }}
                          />
                        </BottomNavigation>
                        {workshopTypes.isLoading ? (
                          <div>
                            <CircularProgress />
                          </div>
                        ) : (
                          <div>
                            {item.active ? (
                              <BottomNavigation showLabels className={classes.buttons}>
                                <BottomNavigationAction
                                  label="desactivar"
                                  onClick={() => {
                                    handleDesactive({ active: false, item: item });
                                  }}
                                  icon={<LockIcon />}
                                  style={{ color: 'red' }}
                                />
                              </BottomNavigation>
                            ) : (
                              <BottomNavigation showLabels className={classes.buttons}>
                                <BottomNavigationAction
                                  label="activar"
                                  onClick={() => {
                                    handleDesactive({ active: true, item: item });
                                  }}
                                  icon={<LockOpenIcon />}
                                  style={{ color: 'blue' }}
                                />
                              </BottomNavigation>
                            )}
                          </div>
                        )}
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {workshopTypes.isLoading ? (
          <CircularProgress />
        ) : (
          <Pagination
            count={workshopTypes.workshopTypes.meta?.pages}
            variant="outlined"
            shape="rounded"
            page={page}
            onChange={handleChangePage}
          />
        )}
      </div>
    </ThemeProvider>
  );
}

export { ListWorkshopType };
