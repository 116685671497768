import produce from 'immer';
import { setLoadable } from 'utils/state.handlers';
import { CatalogAction, CatalogActions } from 'types/redux/actions/catalog.action';
import { initCatalogState, CatalogState } from 'types/redux/state/catalog.state';

export const catalogReducer = (
  state = initCatalogState(),
  action: CatalogAction,
): CatalogState =>
  produce(state, (clone) => {
    switch (action.type) {
      case CatalogActions.officeUpdate:
        setLoadable(clone.offices, action.isLoading, action.error);
        break;
      case CatalogActions.officeValue:
        setLoadable(clone.offices, false);
        clone.offices.data = action.offices;
        break;
      case CatalogActions.providerUpdate:
        setLoadable(clone.providers, action.isLoading, action.error);
        break;
      case CatalogActions.providerValue:
        setLoadable(clone.providers, false);
        clone.providers.data = action.providers;
        break;
      case CatalogActions.typesUpdate:
        setLoadable(clone.workshopTypes, action.isLoading, action.error);
        break;
      case CatalogActions.typesValue:
        setLoadable(clone.workshopTypes, false);
        clone.workshopTypes.data = action.types;
        break;
    }
  });
