import { Action } from 'redux';
import { RootState } from 'redux/reducers';
import { ThunkDispatch } from 'redux-thunk';
import { useDispatch } from 'react-redux';

export type AppDispatch<R extends Action<string> = Action<string>> = ThunkDispatch<
  RootState,
  unknown,
  R
>;

export const useThunkDispatch: () => ThunkDispatch<
  RootState,
  unknown,
  Action<string>
> = useDispatch;
