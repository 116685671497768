export type Verification = {
  id: string;
  name: string;
};

export type Kind = Verification;

export const verifications: readonly Verification[] = [
  { id: 'NotVerified', name: 'NO VERIFICADO' },
  { id: 'Verified', name: 'VERIFICADO SIN POTENCIAL' },
  { id: 'VerifiedPotential', name: 'VERIFICADO CON POTENCIAL' },
] as const;

export const kinds: readonly Kind[] = [
  { id: 'Matrix', name: 'Matriz' },
  { id: 'Branch', name: 'Sucursal' },
] as const;
