import { initUsersState } from 'redux/initial.state';
import produce from 'immer';
import { setLoadable } from 'utils/state.handlers';
import { UsersState } from 'types/redux/state/users.state';
import { UsersAction, UsersActions } from 'types/redux/actions/users.action';

export const usersReducer = (state = initUsersState(), action: UsersAction): UsersState =>
  produce(state, (clone) => {
    switch (action.type) {
      case UsersActions.usersUpdate:
        setLoadable(clone, action.isLoading, action.error);
        break;
      case UsersActions.usersSetValue:
        setLoadable(clone, false);
        clone.users = action.users;
        break;
      case UsersActions.usersNewValue:
        setLoadable(clone, false);
        clone.newUser = action.newUser;
        break;
      case UsersActions.usersSetOffice:
        setLoadable(clone, false);
        clone.branchOffices = action.branchOffices;
        break;
      case UsersActions.usersSetFilter:
        setLoadable(clone, false);
        clone.filter = action.filter;
        break;
      case UsersActions.usersNewValueUpdate:
        setLoadable(clone, false);
        const update = clone.users.users.find((w) => w.userName === action.newUser.userName);
        if (update) {
          update.name = action.newUser.name;
          update.lastName = action.newUser.lastName;
          update.role = action.newUser.role;
          update.branchOffice = action.newUser.branchOffice;
          //device si se implementa
        }
        break;
      case UsersActions.usersStatusUpdate:
        setLoadable(clone, false);
        const updates = clone.users.users.find((w) => w.userName === action.userName);
        if (updates) {
          updates.active = action.status;
        }
        break;

      case UsersActions.userSetDevice:
        setLoadable(clone, false);
        clone.userDevice = action.userDevice;
        break;
    }
  });
