import { ApiData, ApiMetaData } from 'types/api';
import { AppThunk } from 'redux/store';
import { endpoint } from 'api/endpoints';
import { HistoryHandler } from 'utils/history.handler';
import { isSuccessResult } from 'utils/guards';
import { LocalStorageHandler } from 'utils/Local.storage.handler';
import { Users, NewUserSuccess, Office, UserDevice, Device } from 'types/api/responses';
import { Routes } from 'config/routes';
import { apiGet, apiPatch, apiPost } from 'api/karthus.client';
import { UsersAction, UsersActions } from 'types/redux/actions/users.action';

import { FilterUser } from '../../types/redux/state/users.state';
import { DevicesAction, DevicesActions } from 'types/redux/actions/devices.action';

export const userAll = (
  params: {
    page: number;
    size: number;
  },
  filter: FilterUser,
  lastSuccess?: () => void,
): AppThunk<UsersAction> => {
  return async (dispatch) => {
    dispatch({ type: UsersActions.usersUpdate, isLoading: true });
    const response = await apiGet<ApiMetaData<Users[]>>(endpoint.users, {
      ...params,
      filter,
    });
    if (isSuccessResult(response.result)) {
      dispatch({
        type: UsersActions.usersSetValue,
        users: {
          users: response.result.data,
          meta: response.result.meta,
        },
      });
      dispatch({ type: UsersActions.usersSetFilter, filter: filter });
      if (lastSuccess) {
        lastSuccess();
      }
    } else {
      dispatch({
        type: UsersActions.usersUpdate,
        isLoading: false,
        error: response.result[0],
      });
    }
  };
};

type paramsUpdate = {
  name: string;
  lastName: string;
  accessLevel: number;
  branchOfficeId: string | null;
  deviceImei: string | null;
};

export const newUserRes = (params: paramsUpdate): AppThunk<UsersAction> => {
  return async (dispatch) => {
    dispatch({ type: UsersActions.usersUpdate, isLoading: true });
    const response = await apiPost<ApiData<NewUserSuccess>>(endpoint.users, params);
    if (isSuccessResult(response.result)) {
      dispatch({
        type: UsersActions.usersNewValue,
        newUser: response.result.data,
      });
    } else {
      dispatch({
        type: UsersActions.usersUpdate,
        isLoading: false,
        error: response.result[0],
      });
    }
  };
};

export const updateUser = (
  user: string,
  params: paramsUpdate,
  handleUpdateMyProfile?: (name: string, lastName: string) => void,
): AppThunk<UsersAction> => {
  return async (dispatch) => {
    dispatch({ type: UsersActions.usersUpdate, isLoading: true });
    const response = await apiPatch<ApiData<Users>>(endpoint.users + '/' + user, {
      ...params,
    });
    if (isSuccessResult(response.result)) {
      dispatch({
        type: UsersActions.usersNewValue,
        newUser: {
          user: response.result.data,
          temporaryPassword: null,
        },
      });
      if (handleUpdateMyProfile) {
        handleUpdateMyProfile(response.result.data.name, response.result.data.lastName);
      }
      dispatch({
        type: UsersActions.usersNewValueUpdate,
        newUser: response.result.data,
      });
    } else {
      dispatch({
        type: UsersActions.usersUpdate,
        isLoading: false,
        error: response.result[0],
      });
    }
  };
};

//modificar... no tocado
export const resetUser = (): AppThunk<UsersAction> => {
  return async (dispatch) => {
    dispatch({ type: UsersActions.usersNewValue, newUser: null });
  };
};

export const userOffices = (
  filter: { ZoneId: number | null },
  selectFirst?: (id: string) => void,
): AppThunk<UsersAction> => {
  return async (dispatch) => {
    const params = {
      page: 1,
      size: 0,
    };
    dispatch({ type: UsersActions.usersUpdate, isLoading: true });
    const response = await apiGet<ApiMetaData<Office[]>>(endpoint.branchOffcie, {
      ...params,
      filter,
    });
    if (isSuccessResult(response.result)) {
      if (selectFirst) {
        selectFirst(response.result.data[0].id);
      }
      dispatch({
        type: UsersActions.usersSetOffice,
        branchOffices: {
          offices: response.result.data,
          meta: response.result.meta,
        },
      });
    } else {
      dispatch({
        type: UsersActions.usersUpdate,
        isLoading: false,
        error: response.result[0],
      });
    }
  };
};

type mePassword = {
  oldPassword: string;
  newPassword: string;
};

export const changeMePassword = (
  params: mePassword,
  handleChangeSuccess: (flag: boolean) => void,
): AppThunk<UsersAction> => {
  return async (dispatch) => {
    dispatch({ type: UsersActions.usersUpdate, isLoading: true });
    const response = await apiPost<ApiData<NewUserSuccess>>(
      endpoint.users + '/me/password',
      params,
    );
    if (isSuccessResult(response.result)) {
      handleChangeSuccess(true);
    } else {
      dispatch({
        type: UsersActions.usersUpdate,
        isLoading: false,
        error: response.result[0],
      });
    }
  };
};

export const resetPasswordUser = (
  userName: string,
  handleResetPasswordKey: (key: string) => void,
): AppThunk<UsersAction> => {
  return async (dispatch) => {
    dispatch({ type: UsersActions.usersUpdate, isLoading: true });
    const response = await apiPatch<ApiData<string>>(
      endpoint.users + '/' + userName + '/password',
      {},
    );
    if (isSuccessResult(response.result)) {
      handleResetPasswordKey(response.result.data);
      dispatch({ type: UsersActions.usersUpdate, isLoading: false });
    } else {
      dispatch({
        type: UsersActions.usersUpdate,
        isLoading: false,
        error: response.result[0],
      });
    }
  };
};

export const getDevice = (userName: string): AppThunk<UsersAction> => {
  return async (dispatch) => {
    dispatch({ type: UsersActions.usersUpdate, isLoading: true });
    const Filter = {
      Status: 'Active',
    };
    const response = await apiGet<ApiMetaData<UserDevice[]>>(
      endpoint.devices + `/${userName}`,
      {
        Filter,
      },
    );
    if (isSuccessResult(response.result)) {
      dispatch({
        type: UsersActions.userSetDevice,
        userDevice: response.result.data[0],
      });
    } else {
      dispatch({
        type: UsersActions.usersUpdate,
        isLoading: false,
        error: response.result[0],
      });
    }
  };
};

export const updateUserStatus = (user: string, enabled: boolean): AppThunk<UsersAction> => {
  return async (dispatch) => {
    dispatch({ type: UsersActions.usersUpdate, isLoading: true });
    const response = await apiPost<ApiData<Users[]>>(endpoint.users + '/' + user + '/status', {
      enabled,
    });
    if (isSuccessResult(response.result)) {
      dispatch({
        type: UsersActions.usersStatusUpdate,
        userName: user,
        status: enabled,
      });
    } else {
      dispatch({
        type: UsersActions.usersUpdate,
        isLoading: false,
        error: response.result[0],
      });
    }
  };
};

export const deviceTest = (
  params: paramsUpdate,
  user?: string,
  noResult?: () => void,
): AppThunk<DevicesAction> => {
  const Filter = {
    ImeiSW: params.deviceImei,
  };
  return async (dispatch) => {
    dispatch({ type: DevicesActions.devicesUpdate, isLoading: true });
    const response = await apiGet<ApiMetaData<Device[]>>(endpoint.devices, {
      Filter,
    });
    if (isSuccessResult(response.result)) {
      dispatch({
        type: DevicesActions.devicesNewValue,
        newDevice: response.result.data[0],
      });
      if (response.result.data[0]) {
        //console.log('result');
        dispatch(userProcess(params, user));
      } else {
        //console.log('no result');
        if (noResult) {
          noResult();
        }
      }
    } else {
      dispatch({
        type: DevicesActions.devicesUpdate,
        isLoading: false,
        error: response.result[0],
      });
    }
  };
};

export const userProcess = (params: paramsUpdate, user?: string): AppThunk<UsersAction> => {
  //console.log('creando o modificando usuario');
  return (dispatch) => {
    if (user) {
      //console.log('modificando');
      dispatch(updateUser(user, params));
    } else {
      //console.log('creando');
      dispatch(newUserRes(params));
    }
  };
};
