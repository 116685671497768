import { Action } from 'redux';
import { WorkshopType, Metadata } from 'types/api/responses';

export enum WorkshopTypesActions {
  workshopTypesUpdate = 'workshop/workshopTypes/update',
  workshopTypesSetValue = 'workshop/workshopTypes/set',
  workshopTypesSetFilter = 'workshop/workshopTypes/setFilter',
  workshopTypeNewValue = 'workshop/workshopTypes/newWorkshopType',
  workshopTypeNewValueUpdate = 'workshop/workshopTypes/updateWorkshopType',
}

type WorkshopTypesUpdate = {
  isLoading: boolean;
  error?: Error;
} & Action<typeof WorkshopTypesActions.workshopTypesUpdate>;

type WorkshopTypesSetValue = {
  workshopTypes: {
    workshopTypes: WorkshopType[];
    meta: Metadata;
  };
} & Action<typeof WorkshopTypesActions.workshopTypesSetValue>;

type WorkshopTypesSetFilter = {
  filter: {
    NameContains: string | null;
    Status: string | null;
  };
} & Action<typeof WorkshopTypesActions.workshopTypesSetFilter>;

type WorkshopTypeNew = {
  workshopType: WorkshopType | null;
  error?: Error;
} & Action<typeof WorkshopTypesActions.workshopTypeNewValue>;

type WorkshopTypeNewUpdate = {
  workshopType: WorkshopType;
} & Action<typeof WorkshopTypesActions.workshopTypeNewValueUpdate>;

export type WorkshopTypesAction =
  | WorkshopTypesUpdate
  | WorkshopTypesSetValue
  | WorkshopTypesSetFilter
  | WorkshopTypeNew
  | WorkshopTypeNewUpdate;
