import { ApiMetaData } from 'types/api';
import { AppThunk } from 'redux/store';
import { endpoint } from 'api/endpoints';
import { FilterWorkshop } from 'types/redux/state/workshop.state';
import { isSuccessResult } from 'utils/guards';
import { Json } from 'types/common';
import { Workshop } from 'types/api/responses/workshop';
import { ApiData, Office } from 'types/api/responses';
import { apiGet, apiPost, getBlobTransfer } from 'api/karthus.client';
import { EditWorkshopRequest, Pager } from 'types/api/request';
import { WorkshopActions, WorkshopAction } from 'types/redux/actions/workshop.action';

export type ShopAllAction = {
  params: Pager;
  filterN: FilterWorkshop;
  lastSuccess?: () => void;
};

export const workshopAll = ({
  params,
  filterN,
  lastSuccess,
}: ShopAllAction): AppThunk<WorkshopAction> => {
  return async (dispatch) => {
    const filter = {
      ...filterN,
      Active: 'Active',
    };
    dispatch({ type: WorkshopActions.workshopUpdate, isLoading: true });
    const response = await apiGet<ApiMetaData<Workshop[]>>(endpoint.workshop, {
      ...params,
      filter,
    });
    if (isSuccessResult(response.result)) {
      dispatch({ type: WorkshopActions.workshopFilter, filter: filterN });
      dispatch({
        type: WorkshopActions.workshopAll,
        workshops: {
          workshops: response.result.data,
          meta: response.result.meta,
        },
      });
      if (lastSuccess) {
        lastSuccess();
      }
    } else {
      dispatch({
        type: WorkshopActions.workshopUpdate,
        isLoading: false,
        error: response.result[0],
      });
    }
  };
};

export const workshopOffices = (filter: {
  ZoneId: number | null;
}): AppThunk<WorkshopAction> => {
  return async (dispatch) => {
    const myParams = {
      page: 1,
      size: 0,
    };
    dispatch({ type: WorkshopActions.workshopUpdate, isLoading: true });
    const response = await apiGet<ApiMetaData<Office[]>>(endpoint.branchOffcie, {
      ...myParams,
      filter,
    });
    if (isSuccessResult(response.result)) {
      dispatch({
        type: WorkshopActions.workshopSetOffices,
        branchOffices: {
          offices: response.result.data,
          meta: response.result.meta,
        },
      });
    } else {
      dispatch({
        type: WorkshopActions.workshopUpdate,
        isLoading: false,
        error: response.result[0],
      });
    }
  };
};

export const updateWorkshop = (
  params: EditWorkshopRequest,
): AppThunk<WorkshopAction, Promise<void>> => {
  return async (dispatch) => {
    dispatch({ type: WorkshopActions.workshopUpdate, isLoading: true });
    const response = await apiPost<ApiData<Workshop>>(endpoint.workshop, params as Json);
    if (isSuccessResult(response.result)) {
      dispatch({
        type: WorkshopActions.workshopUpdateItem,
        workshop: response.result.data,
      });
      return;
    }
    dispatch({
      type: WorkshopActions.workshopUpdate,
      isLoading: false,
      error: response.result[0],
    });
    throw response.result;
  };
};

export const generateReport = async () => {
  const response = await getBlobTransfer(endpoint.reportExcel);
  if (response.status === 200) {
    const downloadUrl = window.URL.createObjectURL(new Blob([response.result as Blob]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.target = '_blank';
    link.setAttribute('download', 'Report_workshop.xlsx');
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
};
