import { Environment } from 'utils/environment';
import { HistoryHandler } from 'utils/history.handler';
import { Json } from 'types/common';
import { LocalStorageHandler } from 'utils/Local.storage.handler';
import { stringify } from 'qs';
import { ApiResult, ApiFailure } from 'types/api';
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { LoginResponse, ApiData } from 'types/api/responses';

const karthusApi = axios.create({
  baseURL: Environment.baseUrl + '/v1',
  validateStatus: (code: number) => code !== 401,
  paramsSerializer: (params) =>
    stringify(params, {
      skipNulls: true,
      allowDots: true,
    }),
});

let isRefreshing = false;
const failedQueue: {
  resolve: (value: unknown) => void;
  reject: (reason: unknown) => void;
}[] = [];

const retry = (request: AxiosRequestConfig) => {
  return new Promise((resolve, reject) => {
    failedQueue.push({ resolve, reject });
  })
    .then((token) => {
      // @ts-ignore
      request.headers.Authorization = `Bearer  ${token}`;
      return karthusApi(request);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const processQueue = (error: unknown, token: string) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue.length = 0;
};

karthusApi.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${LocalStorageHandler.getToken()}`;
  return config;
});

karthusApi.interceptors.response.use(
  (config) => config,
  (error) => {
    const req = error.config;
    if (error.response.status === 401 && !req.retry) {
      if (isRefreshing) {
        return retry(req);
      }
      const refresh = {
        refreshToken: LocalStorageHandler.getRefreshToken(),
      };
      req.retry = true;
      isRefreshing = true;
      return new Promise((resolve, reject) => {
        axios
          .post<ApiData<LoginResponse>>(`${Environment.baseUrl}/v1/auth/refresh`, refresh, {
            headers: {
              Authorization: `Bearer ${LocalStorageHandler.getToken()}`,
            },
          })
          .then((res) => {
            const { accessToken, refreshToken } = res.data.data;
            LocalStorageHandler.setToken(accessToken);
            LocalStorageHandler.setRefreshToken(refreshToken);
            req.headers.Authorization = `Bearer ${accessToken}`;
            resolve(karthusApi(req));
            processQueue(null, accessToken);
          })
          .catch((err) => {
            LocalStorageHandler.clear();
            HistoryHandler.goTo('/');
            reject(err);
          });
      }).finally(() => {
        isRefreshing = false;
      });
    }
    return Promise.reject(error);
  },
);

const mapResponse = <T extends Json>(res: AxiosResponse<T>): T | Error[] => {
  // this check is because no matter what there should be at least 1 error if something fails
  if (res.data && res.data.hasOwnProperty('errors')) {
    const error = (res.data as unknown) as ApiFailure;
    if (error.errors) {
      return error.errors.map((e) => ({
        name: e.code.toString(),
        message: e.message,
      }));
    }
  }

  if (res.status >= 200 && res.status < 300) {
    return res.data;
  }

  switch (res.status) {
    case 500:
      return [{ name: 'Server error', message: 'Server error' }];
    case 401:
      return [{ name: 'Not authorize', message: 'Not authorize' }];
    case 404:
      return [{ name: 'Resource not found', message: 'Resource not found' }];
    default:
      return [{ name: 'Default error', message: 'Ups... something went wrong' }];
  }
};

const unwrapResponse = <T extends Json>(response: AxiosResponse<T>): ApiResult<T> => {
  return {
    status: response.status,
    statusText: response.statusText,
    result: mapResponse(response),
  };
};

const createApiFailure = <T>(e: Error): ApiResult<T> => {
  return {
    status: -1,
    statusText: 'REACT APP ERROR',
    result: [e],
  };
};

export const apiGet = async <T extends Json>(
  endpoint: string,
  params?: Json,
): Promise<ApiResult<T>> => {
  try {
    const res = await karthusApi.get<T>(endpoint, { params });
    return unwrapResponse(res);
  } catch (e) {
    return createApiFailure(e);
  }
};

export const apiPut = async <T extends Json>(
  endpoint: string,
  data: Json,
): Promise<ApiResult<T>> => {
  try {
    const res = await karthusApi.put<T>(endpoint, data);
    return unwrapResponse(res);
  } catch (e) {
    return createApiFailure(e);
  }
};

export const apiPost = async <T extends Json>(
  endpoint: string,
  data: Json,
): Promise<ApiResult<T>> => {
  try {
    const res = await karthusApi.post<T>(endpoint, data);
    return unwrapResponse(res);
  } catch (e) {
    return createApiFailure(e);
  }
};

export const apiPatch = async <T extends Json>(
  endpoint: string,
  data: Json,
): Promise<ApiResult<T>> => {
  try {
    const res = await karthusApi.patch<T>(endpoint, data);
    return unwrapResponse(res);
  } catch (e) {
    return createApiFailure(e);
  }
};

export const apiDelete = async <T extends Json>(
  endpoint: string,
  data: Json,
): Promise<ApiResult<T>> => {
  try {
    const res = await karthusApi.delete<T>(endpoint, { data });
    return unwrapResponse(res);
  } catch (e) {
    return createApiFailure(e);
  }
};

export const getBlobTransfer = async (endPoint: string): Promise<ApiResult<Blob>> => {
  const headers: AxiosRequestConfig = {
    responseType: 'blob',
  };
  try {
    const response = await karthusApi.get<Blob>(endPoint, headers);
    return {
      status: response.status,
      statusText: response.statusText,
      result: response.data,
    };
  } catch (e) {
    return createApiFailure(e);
  }
};
