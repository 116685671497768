import { ThemeProvider } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import React, { useEffect } from 'react';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { allDevices, updateDevice } from 'redux/actions/devices.actions';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import Snackbar from '@material-ui/core/Snackbar';

import { useThunkDispatch, useTypedSelector } from 'hooks';

import { myTheme, Styles, getModalStyle, StyledTableCell, StyledTableRow } from '../Tools';
import { NewDevice } from './NewDevice';
import Modal from '@material-ui/core/Modal';
import { Device } from '../../types/api/responses';
import { shouldReload } from '../../utils/state.handlers';
import Pagination from '@material-ui/lab/Pagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert } from '../Tools';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';

const SIZE = 10,
  INITPAGE = 1;

function SearchDetails() {
  const classes = Styles();

  const devices = useTypedSelector((z) => z.devices);
  const dispatch = useThunkDispatch();

  const [nameSearch, setNameSearch] = React.useState(
    devices.filter.ImeiSW ? devices.filter.ImeiSW : '',
  );

  const [openStatus, setOpenStatus] = React.useState(false);
  const [theStatus, setTheStatus] = React.useState(
    devices.filter.Status != null ? devices.filter.Status : '0',
  );

  //evento de cambio en nombre a buscar
  const handleChangeNameSearch = (event: React.ChangeEvent<{ value: unknown }>) => {
    setNameSearch(event.target.value as string);
  };

  //evento de busqueda segun nombre
  const handleSearch = () => {
    //alert(nameSearch + 'F');
    dispatch(
      allDevices(
        { page: INITPAGE, size: SIZE },
        {
          Status: devices.filter.Status,
          ImeiSW: nameSearch,
          NumberSW: devices.filter.NumberSW,
        },
      ),
    );
  };

  const handleResetSearch = () => {
    setTheStatus('0');
    setNameSearch('');
    dispatch(
      allDevices(
        { page: INITPAGE, size: SIZE },
        {
          Status: null,
          ImeiSW: null,
          NumberSW: null,
        },
      ),
    );
  };

  const handleOpenStatus = () => {
    setOpenStatus(true);
  };

  const handleClosedStatus = () => {
    setOpenStatus(false);
  };

  const handleChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;
    setTheStatus(value);
    dispatch(
      allDevices(
        { page: INITPAGE, size: SIZE },
        {
          ImeiSW: devices.filter.ImeiSW,
          NumberSW: devices.filter.NumberSW,
          Status: value === '0' ? null : value,
        },
      ),
    );
  };

  return (
    <form className={classes.rootForm} noValidate autoComplete="off">
      {devices.isLoading ? (
        <div>
          <CircularProgress />
        </div>
      ) : (
        <div style={{ display: 'inline-flex', alignItems: 'center', marginRight: '10px' }}>
          <TextField
            id="outlined-read-only-input"
            label="IMEI"
            value={nameSearch}
            inputProps={{ maxLength: 16 }}
            onChange={handleChangeNameSearch}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                ev.preventDefault();
                handleSearch();
              }
            }}
            variant="outlined"
            required
          />
          <FormControl
            variant="outlined"
            style={{ marginLeft: '10px' }}
            className={classes.formControl}
          >
            <InputLabel id="select-filter-rol">Estado</InputLabel>
            <Select
              labelId="select-filter-rol"
              id="filter-controlled-open-select-rol"
              open={openStatus}
              onClose={handleClosedStatus}
              onOpen={handleOpenStatus}
              value={theStatus}
              onChange={handleChangeStatus}
            >
              <MenuItem value={'0'}>Todos</MenuItem>
              <MenuItem value={'Disabled'}>Inactivos</MenuItem>
              <MenuItem value={'Active'}>Activos</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="outlined"
            onClick={handleResetSearch}
            className={classes.submit}
            style={{ color: 'Blue', marginRight: '5px' }}
          >
            Reset
          </Button>
        </div>
      )}
    </form>
  );
}

export function ListDevice() {
  const classes = Styles();
  const devices = useTypedSelector((u) => u.devices);
  const [page, setPage] = React.useState(1);

  //modal
  const [modalStyle] = React.useState(getModalStyle(40, 20));
  const [openModal, setOpenModal] = React.useState({
    open: false,
    loading: false,
  });
  const [newDevice, setnewDevice] = React.useState(false);
  const [modalActive, setmodalActive] = React.useState(false);
  const [theDevice, setTheDevice] = React.useState({
    imei: '',
    manufacture: '',
    model: '',
    enable: false,
    number: '',
    assigned: null,
  });
  const dispatch = useThunkDispatch();

  const [fixInitComponent, setFixInitComponent] = React.useState(true);

  useEffect(() => {
    if (shouldReload(devices) || (fixInitComponent && devices.devices.devices.length == 0)) {
      setFixInitComponent(false);
      dispatch(
        allDevices(
          { size: SIZE, page: INITPAGE },
          {
            Status: null,
            ImeiSW: null,
            NumberSW: null,
          },
        ),
      );
    }
  }, [devices]);

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    dispatch(allDevices({ page: newPage, size: SIZE }, devices.filter));
    setPage(newPage);
  };

  const handleOpenModal = () => {
    setOpenModal({
      open: true,
      loading: false,
    });
  };

  const handleCloseModal = (loading: boolean | null, flag: boolean) => {
    if (loading === null && !openModal.loading) {
      setOpenModal({
        open: false,
        loading: openModal.loading,
      });
    } else if (loading) {
      setOpenModal({
        open: openModal.open,
        loading: loading,
      });
    } else if (loading === false) {
      setOpenModal({
        open: false,
        loading: loading,
      });
    }
    if (flag) {
      setmodalActive(true);
    }
  };

  const handleChangeModal = ({ device, isNew }: { device: Device; isNew: boolean }) => {
    setnewDevice(isNew);
    setTheDevice({
      imei: device.imei,
      manufacture: device.manufacture ? device.manufacture : '',
      model: device.model ? device.model : '',
      enable: device.enable,
      number: device.number ? device.number : '',
      assigned: null,
    });
    handleOpenModal();
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setmodalActive(false);
  };

  const handleDesactive = ({ active, item }: { active: boolean; item: Device }) => {
    setmodalActive(false);
    setmodalActive(true);
    dispatch(
      updateDevice({
        imei: item.imei,
        manufacture: item.manufacture,
        model: item.model,
        enable: active,
        number: item.number,
        assigned: null,
      }),
    );
  };

  return (
    <ThemeProvider theme={myTheme}>
      <Modal
        open={openModal.open}
        onClose={() => {
          handleCloseModal(null, false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paperModalNewUser}>
          <div id={'modal-container'} style={{ height: '100%' }}>
            <NewDevice device={theDevice} isNew={newDevice} handleClose={handleCloseModal} />
          </div>
        </div>
      </Modal>
      <Snackbar open={modalActive} autoHideDuration={15000} onClose={handleClose}>
        {devices.isLoading ? (
          <Alert onClose={handleClose} severity="info">
            <div>Editando Dispositivo...</div>
          </Alert>
        ) : (
          <div>
            {devices.error ? (
              <Alert onClose={handleClose} severity="error">
                <div>ocurrio un error {devices.error.message}</div>
              </Alert>
            ) : (
              <Alert onClose={handleClose} severity="success">
                <div>Se edito con exito el Dispositivo</div>
              </Alert>
            )}
          </div>
        )}
      </Snackbar>
      <div className={classes.divider}>
        <div style={{ width: '100%', display: 'flex', alignContent: 'center' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div id={'tableHead'} className={classes.centerer}>
              <Paper className={classes.paper}>
                <div className={classes.flexWrap}>
                  <SearchDetails />
                  <BottomNavigation
                    showLabels
                    onClick={() => {
                      handleChangeModal({
                        device: {
                          imei: '',
                          manufacture: '',
                          model: '',
                          enable: false,
                          number: '',
                          assigned: null,
                        },
                        isNew: true,
                      });
                    }}
                  >
                    <BottomNavigationAction
                      label="agregar"
                      icon={<AddToQueueIcon />}
                      style={{ color: 'green' }}
                    />
                  </BottomNavigation>
                </div>
              </Paper>
              <TableContainer
                style={{
                  maxHeight: '75vh',
                  minHeight: '60vh',
                  maxWidth: '95vw',
                  minWidth: '1000px',
                }}
              >
                <Table className={classes.table} stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell> IMEI Dispositivo</StyledTableCell>
                      <StyledTableCell align="center">Marca</StyledTableCell>
                      <StyledTableCell align="center">Modelo</StyledTableCell>
                      <StyledTableCell align="center">Telefono</StyledTableCell>
                      <StyledTableCell align="center">Asignado</StyledTableCell>
                      <StyledTableCell align="center">Estado</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {devices.devices.devices.map((item) => {
                      return (
                        <StyledTableRow key={item.imei}>
                          <StyledTableCell component="th" scope="row">
                            {item.imei}
                          </StyledTableCell>
                          <StyledTableCell align="center">{item.manufacture}</StyledTableCell>
                          <StyledTableCell align="center">{item.model}</StyledTableCell>
                          <StyledTableCell align="center">{item.number}</StyledTableCell>
                          <StyledTableCell align="center">
                            {item.assigned?.userName}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <div className={classes.flexRowCenter}>
                              <BottomNavigation
                                showLabels
                                onClick={() => {
                                  handleChangeModal({ device: item, isNew: false });
                                }}
                                className={classes.buttons}
                              >
                                <BottomNavigationAction
                                  label="Editar"
                                  icon={<EditIcon />}
                                  style={{ color: 'blue', margin: '0px' }}
                                />
                              </BottomNavigation>
                              {devices.isLoading ? (
                                <div>
                                  <CircularProgress />
                                </div>
                              ) : (
                                <div>
                                  {item.enable ? (
                                    <BottomNavigation showLabels className={classes.buttons}>
                                      <BottomNavigationAction
                                        label="bloquear"
                                        onClick={() => {
                                          handleDesactive({ active: false, item: item });
                                        }}
                                        icon={<LockIcon />}
                                        style={{ color: 'red' }}
                                      />
                                    </BottomNavigation>
                                  ) : (
                                    <BottomNavigation showLabels className={classes.buttons}>
                                      <BottomNavigationAction
                                        label="desbloquear"
                                        onClick={() => {
                                          handleDesactive({ active: true, item: item });
                                        }}
                                        icon={<LockOpenIcon />}
                                        style={{ color: 'blue' }}
                                      />
                                    </BottomNavigation>
                                  )}
                                </div>
                              )}
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {devices.isLoading ? (
                  <CircularProgress />
                ) : (
                  <Pagination
                    count={devices.devices.meta?.pages}
                    variant="outlined"
                    shape="rounded"
                    page={page}
                    onChange={handleChangePage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
