import { ApiData, ApiMetaData } from 'types/api';
import { AppThunk } from 'redux/store';
import { endpoint } from 'api/endpoints';
import { HistoryHandler } from 'utils/history.handler';
import { isSuccessResult } from 'utils/guards';
import { LocalStorageHandler } from 'utils/Local.storage.handler';
import { Profile } from 'types/api/responses';
import { Routes } from 'config/routes';
import { apiGet } from 'api/karthus.client';
import { ProfileAction, ProfileActions } from 'types/redux/actions/profile.action';

export const getProfile = (account: string): AppThunk<ProfileAction> => {
  return async (dispatch) => {
    dispatch({ type: ProfileActions.profileUpdate, isLoading: true });
    const response = await apiGet<ApiData<Profile>>(endpoint.profile + '/' + account, '');
    if (isSuccessResult(response.result)) {
      dispatch({
        type: ProfileActions.profileSetValue,
        profile: response.result.data,
      });
    } else {
      dispatch({
        type: ProfileActions.profileUpdate,
        isLoading: false,
        error: response.result[0],
      });
    }
  };
};

export const resetProfile = (): AppThunk<ProfileAction> => {
  return (dispatch) => {
    dispatch({
      type: ProfileActions.profileSetValue,
    });
    dispatch({
      type: ProfileActions.resetProfile,
      isLoading: false,
    });
  };
};
