import departmentListSV from 'config/departments.json';
import departmentListHN from 'config/departments_hn.json';
import { initLoadable } from 'utils/state.handlers';
import { Loadable2 } from './index';
import { Municipio } from 'types/api/responses';
import { Environment } from 'utils/environment';

export type DepartmentWithMunicipio = {
  id: string;
  name: string;
  municipios: Loadable2<Municipio[]>;
};

export type DepartmentState = {
  departments: DepartmentWithMunicipio[];
  currentId: string;
};

interface IDepartment {
  id: string;
  name: string;
  country: string;
}

export const initDepartmentState = (): DepartmentState => {
  let list: IDepartment[] = [];
  switch (Environment.Country) {
    case 'SV':
      list = departmentListSV;
      break;
    case 'HN':
      list = departmentListHN;
      break;
    default:
      list = [];
      break;
  }
  return {
    currentId: list[1].id,
    departments: list.map((d) => ({
      ...d,
      municipios: initLoadable([]),
    })),
  };
};
