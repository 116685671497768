import { AccessLevels } from '../../config/access.levels';
import React, { useEffect } from 'react';
import { useThunkDispatch, useTypedSelector } from 'hooks';
import { Styles, getModalStyle } from '../Tools';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

import Select from '@material-ui/core/Select';
import { NewUser, Users } from '../../types/api/responses';
import Button from '@material-ui/core/Button';
import {
  newUserRes,
  resetUser,
  updateUser,
  userOffices,
  resetPasswordUser,
  userProcess,
  deviceTest,
} from 'redux/actions/users.actions';
import { newDevice, resetNewDevice } from 'redux/actions/devices.actions';
import { RolesList } from '../Tools';

import Container from '@material-ui/core/Container';

import { FilterZoneOffice } from '../ListTaller/FilterZoneOffice';
import Checkbox from '@material-ui/core/Checkbox';
import GridList from '@material-ui/core/GridList';
import CircularProgress from '@material-ui/core/CircularProgress';
import { NameRole } from './ListUser';

export type NewUserParams = {
  isNew: boolean;
  user: NewUser;
  handleClose: (loading: boolean | null, error?: boolean) => void;
};

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .min(3, 'username should be of minimum 3 characters length')
    .max(150, 'username should be of maximun 50 characters length')
    .required('username is required'),
  lastName: yup
    .string()
    .trim()
    .min(3, 'username should be of minimum 3 characters length')
    .max(150, 'username should be of maximun 50 characters length')
    .required('username is required'),
  imei: yup
    .string()
    .min(16, 'El IMEI debe tener 16 caracteres')
    .max(16, 'El IMEI debe tener 16 caracteres')
    .matches(/[0-9]{16}/),
  number: yup
    .string()
    .trim()
    .min(8, 'el numero debe contener 8 caracteres')
    .max(8, 'el numero debe contener 8 caracteres')
    .matches(/[0-9]{8}/, 'Solo se permiten números'),
  manufacture: yup.string().trim().max(35, 'Maximo 35 caracteres'),
  model: yup.string().trim().max(100, 'Maximo 100 caracteres'),
});

export function NewUsers(params: NewUserParams) {
  const classes = Styles();
  const dispatch = useThunkDispatch();
  const users = useTypedSelector((z) => z.users);
  const devices = useTypedSelector((z) => z.devices);
  //verificar rol de usuario actual
  const myRole = useTypedSelector((z) => z.session).user.role.accessLevel;
  //control de open/close de selec rol
  const [openRol, setOpenRol] = React.useState(false);
  //comprobar si el usuario tiene o no dispositivo
  const [isDevice, setIsDevice] = React.useState<boolean | null>(null); //si tiene dispositivo
  //variable para ver si el usuario se le asigna un dispositivo
  const [isAsigned, setIsAsigned] = React.useState(false); //quiero asignar o no dispositivo
  //si al usuario se el asigna dispositivo se verifica si es nuevo
  const [isDeviceNew, setIsDeviceNew] = React.useState(false); //si el dispsitivo sera nuevo o no
  //mensaje personalizado para la sucursal
  const [errorMensage, setErrorMensage] = React.useState('');

  //rol a asignar al usuuario que se creara
  const [rol, setRol] = React.useState(params.user.accessLevel);
  //sirve para ver si al usuario se le asignara o no sucursal (visualmente ya que siempre se le asigna)
  const [zoneManager, setZoneManger] = React.useState<number | undefined>(
    params.user.accessLevel,
  );
  //variablke que guarda el id de la sucursal seleccionada
  const [office, setOffice] = React.useState(
    params.user.branchOfficeId ? params.user.branchOfficeId : '0',
  );
  //variable que guarda la zona seleccionada
  const [theZone, setTheZone] = React.useState(params.user.zoneId ? params.user.zoneId : 0);
  //bandera para cargar zonas y sucursales al entrar a la creacion edicion de usuario
  const [init, setInit] = React.useState(true);
  //variable que guarda la contraseña a asignar
  const [passwordKey, setPasswordKey] = React.useState('');
  const [messageErrorImei, setMessageErrorImei] = React.useState('');

  useEffect(() => {
    if (isDevice === null && !users.isLoading) {
      if (users.userDevice?.device.imei) {
        setIsDevice(true);
        formik.values.imei = users.userDevice?.device.imei;
      } else {
        setIsDevice(false);
      }
    }
  }, [users.userDevice]);

  const formik = useFormik({
    initialValues: {
      name: params.user.name,
      lastName: params.user.lastName,
      imei: '',
      manufacture: '',
      model: '',
      number: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (office == '0') {
        //no ha asignado sucursal, no hace nada
      } else {
        setMessageErrorImei('');
        if (isAsigned) {
          //console.log('asigno dispositivo');
          if (values.imei.trim() == '') {
            //validar imei vacio
            setMessageErrorImei('Ingrese Imei');
          } else {
            if (isDeviceNew) {
              console.log('creo dispositivo');
              dispatch(
                newDevice(
                  {
                    imei: values.imei,
                    manufacture: values.manufacture ? values.manufacture.trim() : null,
                    model: values.model ? values.model.trim() : null,
                    enable: true,
                    number: values.number ? values.number.trim() : null,
                    assigned: null,
                  },
                  handleSaveUser,
                ),
              );
            } else {
              console.log('asignado dispositivo existente');
              dispatch(
                deviceTest(
                  {
                    name: values.name.trim(),
                    lastName: values.lastName.trim(),
                    accessLevel: rol,
                    branchOfficeId: office,
                    deviceImei: values.imei ? values.imei : null,
                  },
                  params.isNew ? undefined : params.user.userName,
                  () => {
                    //mensaje cuando no exista dispositivo
                    setErrorMensage('El Imei ingresado no esta registrado');
                    //alert('dispositivo no existe');
                  },
                ),
              );
            }
          }
        } else {
          handleSaveUser();
        }
      }
    },
  });

  const handleSaveUser = () => {
    params.handleClose(true);
    dispatch(
      userProcess(
        {
          name: formik.values.name.trim(),
          lastName: formik.values.lastName.trim(),
          accessLevel: rol,
          branchOfficeId: office,
          deviceImei: formik.values.imei ? formik.values.imei : null,
        },
        params.isNew ? undefined : params.user.userName,
      ),
    );
  };

  if (params.user.zoneId !== users.filter.ZoneId && init) {
    setInit(false);
    dispatch(
      userOffices({
        ZoneId: params.user.zoneId == 0 ? null : params.user.zoneId,
      }),
    );
  }

  const handleAcepting = () => {
    if (users.error?.name || devices.error?.name) {
      params.handleClose(false, true);
    } else {
      setPasswordKey('');
      params.handleClose(false);
    }
    dispatch(resetUser());
    dispatch(resetNewDevice());
  };

  const removeDevice = () => {
    //desvincular
    formik.values.imei = '';
    setIsDevice(false);
  };

  const saveIn = (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      className={classes.submit}
    >
      Guardar
    </Button>
  );

  const aceptIn = (
    <div>
      {users.error?.name || users.newUser || passwordKey != '' ? (
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleAcepting}
        >
          Aceptar
        </Button>
      ) : (
        <div>{saveIn}</div>
      )}
    </div>
  );

  const handleResetPasswordKey = (key: string) => {
    setPasswordKey(key);
  };

  const handleResetPassword = () => {
    dispatch(resetPasswordUser(params.user.userName, handleResetPasswordKey));
  };

  const resetPassword = (
    <div className={classes.flexRowCenter}>
      {!params.isNew ? (
        <div className={classes.flexColumnCenter}>
          {passwordKey != '' ? (
            <Paper className={classes.paper}>
              <div style={{ color: 'blue', margin: '10px' }}>
                la contraseña nueva es: {passwordKey}
              </div>
            </Paper>
          ) : (
            <Button
              //type="submit"
              variant="contained"
              style={{
                margin: '10px',
                backgroundColor: 'red',
                color: 'white',
                width: 'fit-content',
              }}
              onClick={handleResetPassword}
            >
              Resetear Contraseña
            </Button>
          )}
        </div>
      ) : (
        <div />
      )}
    </div>
  );

  const handleChangeNewDevice = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDeviceNew(event.target.checked);
  };

  const handleChangeAsignedDevice = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAsigned(event.target.checked);
    if (!event.target.checked) {
      setIsDeviceNew(false);
      formik.values.imei = '';
    }
  };

  const handleOpenRol = () => {
    setOpenRol(true);
  };

  const handleCloseRol = () => {
    setOpenRol(false);
  };

  const handleChangeRol = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as number;
    setRol(value);
    if (value < 3) {
      setZoneManger(undefined);
    } else {
      setZoneManger(value);
      if (value > 2) {
        changeZone({ reset: false, value: 1, flag: true });
      }
    }
  };

  const changeOffice = ({ reset, value }: { reset: boolean; value: string }) => {
    setOffice(value);
  };

  const changeZone = ({
    reset,
    value,
    flag,
  }: {
    reset: boolean;
    value: number;
    flag?: boolean;
  }) => {
    if (!reset) {
      dispatch(userOffices({ ZoneId: value }));
      if ((zoneManager && zoneManager > 2) || flag) {
        dispatch(
          userOffices({ ZoneId: value }, (id: string) => {
            setOffice(id);
          }),
        );
      }
    } else {
      setOffice('0');
      dispatch(userOffices({ ZoneId: null }));
    }
  };

  const pagineOffice = ({ page, zone }: { page: number; zone: number | null }) => {
    //dispatch(workshopOffices({ size: 100, page: page }, { ZoneId: zone }));
  };

  return (
    <Container style={{ height: '100%' }}>
      <form onSubmit={formik.handleSubmit} className={classes.form} style={{ height: '100%' }}>
        <Paper className={classes.paper}>
          {params.isNew ? 'Crear Usuario' : `Editar Usuario: ${params.user.userName}`}
        </Paper>
        <GridList className={classes.gridListUser}>
          <div style={{ width: '100%' }}>
            <div className={classes.flexColumnCenter}>
              {users.newUser ? (
                <div className={classes.flexColumnCenter} style={{ width: '100%' }}>
                  <Paper className={classes.paper}>Nombre: {users.newUser.user.name}</Paper>
                  <Paper className={classes.paper}>
                    Apellido: {users.newUser.user.lastName}
                  </Paper>
                  {zoneManager && zoneManager > 3 ? null : (
                    <Paper className={classes.paper}>
                      {zoneManager && zoneManager === 3
                        ? `Zona: ${users.newUser.user.branchOffice?.zone.name}`
                        : `Sucursal: ${users.newUser.user.branchOffice?.name}`}
                    </Paper>
                  )}
                  <Paper className={classes.paper}>
                    rol: <NameRole name={users.newUser.user.role.name} />
                  </Paper>
                  {users.newUser.user.role.accessLevel < 4 ? (
                    <Paper className={classes.paper}>Imei: {formik.values.imei}</Paper>
                  ) : null}
                </div>
              ) : (
                <div className={classes.flexColumnCenter}>
                  <TextField
                    onChange={formik.handleChange}
                    label="nombre"
                    margin="normal"
                    fullWidth
                    id="name"
                    inputProps={{ maxLength: 150 }}
                    name="name"
                    autoFocus
                    value={formik.values.name}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    variant="outlined"
                  />
                  <TextField
                    onChange={formik.handleChange}
                    label="Apellido"
                    margin="normal"
                    inputProps={{ maxLength: 150 }}
                    fullWidth
                    id="lastName"
                    name="lastName"
                    autoFocus
                    value={formik.values.lastName}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    helperText={formik.touched.lastName && formik.errors.lastName}
                    variant="outlined"
                  />
                  <div className={classes.flexWrap}>
                    {zoneManager && zoneManager > 3 ? null : (
                      <Paper className={classes.paper}>
                        {office === '0' ? (
                          <div
                            style={{ color: 'red ', paddingBottom: '10px' }}
                            className={classes.flexRowCenter}
                          >
                            Debe Asignar una
                            {zoneManager && zoneManager == 3 ? 'Zona' : 'Sucursal'}
                          </div>
                        ) : (
                          <div />
                        )}
                        <div className={classes.flexWrap}>
                          <FilterZoneOffice
                            changeZone={changeZone}
                            changeOffice={changeOffice}
                            complexUser={zoneManager}
                            filter={{
                              zone: theZone,
                              office: office,
                            }}
                            offices={users.branchOffices}
                            pagineOffice={pagineOffice}
                          />
                        </div>
                      </Paper>
                    )}
                    {rol < myRole ? (
                      <FormControl
                        variant="outlined"
                        style={{ margin: '10px' }}
                        className={classes.formControl}
                      >
                        <InputLabel id="select-filter-rol">Rol</InputLabel>
                        <Select
                          labelId="select-filter-rol"
                          id="filter-controlled-open-select-rol"
                          open={openRol}
                          onClose={handleCloseRol}
                          onOpen={handleOpenRol}
                          value={rol}
                          onChange={handleChangeRol}
                        >
                          {RolesList.map((item) => {
                            if (
                              item.level < myRole &&
                              !(
                                myRole == AccessLevels.HelpDesk &&
                                item.level == AccessLevels.CommercialManager
                              )
                            ) {
                              return <MenuItem value={item.level}>{item.name}</MenuItem>;
                            }
                          })}
                        </Select>
                      </FormControl>
                    ) : (
                      <div />
                    )}
                  </div>
                  {rol < AccessLevels.Coordinator ? (
                    <div className={classes.paper}>
                      {isDevice ? (
                        <div>
                          <Paper className={classes.paper}>{formik.values.imei}</Paper>
                          <Button
                            variant="contained"
                            style={{
                              marginLeft: '10px',
                              backgroundColor: 'red',
                              color: 'white',
                            }}
                            className={classes.submit}
                            onClick={removeDevice}
                          >
                            Desvincular
                          </Button>
                        </div>
                      ) : (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                            }}
                          >
                            <Checkbox
                              onChange={handleChangeAsignedDevice}
                              //checked={true}
                              name="checkbox-newdevice"
                              color="primary"
                            />
                            <InputLabel
                              id="checkbox-newdevice"
                              style={{ alignSelf: 'center' }}
                            >
                              Agregar Dispositivo
                            </InputLabel>
                          </div>
                          {isAsigned ? (
                            <div>
                              {myRole != AccessLevels.CommercialManager ? (
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    height: 'fit-content',
                                  }}
                                >
                                  <Checkbox
                                    onChange={handleChangeNewDevice}
                                    //checked={true}
                                    name="checkbox-newdevice"
                                    color="primary"
                                  />
                                  <InputLabel
                                    id="checkbox-newdevice"
                                    style={{ alignSelf: 'center' }}
                                  >
                                    Nuevo Dispositivo
                                  </InputLabel>
                                </div>
                              ) : null}
                              <p style={{ color: 'red' }}>{messageErrorImei}</p>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <TextField
                                  onChange={formik.handleChange}
                                  label="Dispositivo (IMEI)"
                                  margin="normal"
                                  inputProps={{ maxLength: 16 }}
                                  id="imei"
                                  name="imei"
                                  autoFocus
                                  value={formik.values.imei}
                                  error={formik.touched.imei && Boolean(formik.errors.imei)}
                                  helperText={formik.touched.imei && formik.errors.imei}
                                  variant="outlined"
                                />
                                <span style={{ color: 'red' }}>{errorMensage}</span>
                                {isDeviceNew ? (
                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <TextField
                                      onChange={formik.handleChange}
                                      label="Marca"
                                      margin="normal"
                                      id="manufacture"
                                      inputProps={{ maxLength: 35 }}
                                      name="manufacture"
                                      autoFocus
                                      value={formik.values.manufacture}
                                      error={
                                        formik.touched.manufacture &&
                                        Boolean(formik.errors.manufacture)
                                      }
                                      helperText={
                                        formik.touched.manufacture && formik.errors.manufacture
                                      }
                                      variant="outlined"
                                    />
                                    <TextField
                                      onChange={formik.handleChange}
                                      label="Modelo"
                                      margin="normal"
                                      id="model"
                                      name="model"
                                      autoFocus
                                      inputProps={{ maxLength: 150 }}
                                      value={formik.values.model}
                                      error={
                                        formik.touched.model && Boolean(formik.errors.model)
                                      }
                                      helperText={formik.touched.model && formik.errors.model}
                                      variant="outlined"
                                    />
                                    <TextField
                                      onChange={formik.handleChange}
                                      label="Numero"
                                      margin="normal"
                                      id="number"
                                      name="number"
                                      autoFocus
                                      inputProps={{ maxLength: 8 }}
                                      value={formik.values.number}
                                      error={
                                        formik.touched.number && Boolean(formik.errors.number)
                                      }
                                      helperText={
                                        formik.touched.number && formik.errors.number
                                      }
                                      variant="outlined"
                                    />
                                  </div>
                                ) : (
                                  <div />
                                )}
                              </div>
                            </div>
                          ) : (
                            <div />
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              )}
              {resetPassword}
              {isAsigned ? (
                <div>
                  {devices.error ? (
                    <div className={classes.flexColumnCenter}>
                      <p>A ocurrido un error</p>
                      {devices.error?.name === '1009' ? (
                        <div>Dispositivo ya esta Registrado</div>
                      ) : (
                        <div>
                          <p>{devices.error.message}</p>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      {devices.isLoading || users.isLoading ? (
                        <div className={classes.flexRowCenter}>
                          <CircularProgress />
                        </div>
                      ) : (
                        <div>
                          {devices.newDevice && !users.error ? (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <p style={{ textAlign: 'center' }}>
                                Dispositivo {isDeviceNew ? 'Creado' : 'Asignado'} con exito
                              </p>
                            </div>
                          ) : (
                            <div />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div />
              )}
              {users.error ? (
                <div className={classes.flexColumnCenter}>
                  {users.error?.name === '1009' ? (
                    <div />
                  ) : (
                    <div className={classes.flexColumnCenter} style={{ alignItems: 'center' }}>
                      <p style={{ width: 'fit-content' }}>A ocurrido un error</p>
                      <p style={{ width: 'fit-content' }}>{users.error.message}</p>
                    </div>
                  )}
                </div>
              ) : (
                <div />
              )}
              <div>
                {users.isLoading ? (
                  <div className={classes.flexRowCenter}>
                    <CircularProgress />
                  </div>
                ) : (
                  <div>
                    {!users.newUser ? (
                      <div />
                    ) : (
                      <div>
                        <p style={{ textAlign: 'center' }}>
                          Usuario {users.newUser.user.userName}
                          {params.isNew ? '  Creado' : '  Editado'} con exito
                        </p>
                        {params.isNew ? (
                          <p style={{ textAlign: 'center' }}>
                            Contraseña por defecto: {users.newUser.temporaryPassword}
                          </p>
                        ) : (
                          <div />
                        )}
                      </div>
                    )}
                    {aceptIn}
                  </div>
                )}
              </div>
            </div>
          </div>
        </GridList>
      </form>
    </Container>
  );
}
