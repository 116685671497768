export const Routes = {
  login: '/login',
  workshop: '/talleres',
  users: '/users',
  providers: '/providers',
  devices: '/devices',
  workshopTypes: '/workshopTypes',
  help: '/help',
  profile: '/profile',
  home: '/home',
} as const;
