import { initZonesState } from 'redux/initial.state';
import produce from 'immer';
import { setLoadable } from 'utils/state.handlers';
import { ZonesState } from 'types/redux/state/zones.state';
import { ZonesAction, ZonesActions } from 'types/redux/actions/zones.action';

export const zonesReducer = (state = initZonesState(), action: ZonesAction): ZonesState =>
  produce(state, (clone) => {
    switch (action.type) {
      case ZonesActions.zonesUpdate:
        setLoadable(clone, action.isLoading, action.error);
        break;
      case ZonesActions.zonesSetValue:
        setLoadable(clone, false);
        clone.zones = action.zones;
        clone.meta = action.meta;
        break;
    }
  });
