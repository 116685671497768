import { allZones } from 'redux/actions/zones.actions';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { shouldReload } from 'utils/state.handlers';
import { Styles } from '../Tools';
import { workshopOffices } from '../../redux/actions/workshop.actions';
import { BranchOffices, Office } from '../../types/api/responses';
import React, { useEffect, ChangeEvent, useState } from 'react';
import { useThunkDispatch, useTypedSelector } from 'hooks';

export function FilterZoneOffice({
  changeZone,
  changeOffice,
  pagineOffice,
  filter,
  offices,
  complexUser,
}: {
  changeZone: ({ reset, value }: { reset: boolean; value: number }) => void;
  pagineOffice: ({ page, zone }: { page: number; zone: number | null }) => void;
  changeOffice: ({
    reset,
    value,
    page,
  }: {
    reset: boolean;
    value: string;
    page?: number;
  }) => void;
  filter: {
    zone: number;
    office: string;
  };
  offices: BranchOffices;
  complexUser?: number; //1. auto-zona, 2. solo zona, 3. mandar sucursal anonima
}) {
  //const workshop = useTypedSelector((z) => z.workshop);
  const classes = Styles();
  const zones = useTypedSelector((z) => z.zones);
  const [filterZona, setFilterZona] = React.useState(filter.zone);
  const [openZona, setOpenZona] = React.useState(false);
  const [filterOffice, setFilterOffice] = React.useState(filter.office);
  const [openOffice, setOpenOffice] = React.useState(false);
  const dispatch = useThunkDispatch();

  useEffect(() => {
    if (shouldReload(zones)) {
      dispatch(allZones());
    }
  }, [zones]);

  const handleChangeFilterZona = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as number;
    setFilterZona(val);
    setFilterOffice('0');
    if (val != 0) {
      changeZone({ reset: false, value: val });
    } else {
      changeZone({ reset: true, value: val });
    }
  };

  const handleCloseFilterZona = () => {
    setOpenZona(false);
  };

  const handleOpenFilterZona = () => {
    setOpenZona(true);
  };

  const handleChangeFilterOffice = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setFilterOffice(val);
    if (val != '0') {
      if (complexUser && complexUser < 3) {
        const update = offices.offices.find((w) => w.id === val);
        if (update) {
          setFilterZona(update.zone.id);
          changeZone({ reset: false, value: update.zone.id });
        }
      }
      changeOffice({ reset: false, value: val });
      //seleccionando zona al elegir sucursal
    } else {
      changeOffice({ reset: true, value: val });
    }
  };

  const handleCloseFilterOffice = () => {
    setOpenOffice(false);
  };

  const handleOpenFilterOffice = () => {
    if (offices.offices.length == 0 && filterZona == 0) {
      changeOffice({ reset: true, value: '' });
    }
    setOpenOffice(true);
  };

  return (
    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
      <FormControl
        variant="outlined"
        style={{ marginRight: '10px' }}
        className={classes.formControl}
      >
        <InputLabel id="select-filterZona">Zona</InputLabel>
        <Select
          labelId="select-filterZona"
          id="filterZona-controlled-open-select"
          open={openZona}
          onClose={handleCloseFilterZona}
          onOpen={handleOpenFilterZona}
          value={filterZona}
          onChange={handleChangeFilterZona}
        >
          <MenuItem value={0}>Sin Filtrar</MenuItem>
          {zones.zones.map((item) => {
            return (
              <MenuItem value={item.id} key={item.id}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {offices.offices.length !== 0 ? (
        complexUser && complexUser > 2 ? null : (
          <FormControl
            variant="outlined"
            style={{ marginRight: '10px' }}
            className={classes.formControl}
          >
            <InputLabel id="select-filter">Sucursal</InputLabel>
            <Select
              labelId="select-filter"
              id="filter-controlled-open-select"
              open={openOffice}
              onClose={handleCloseFilterOffice}
              onOpen={handleOpenFilterOffice}
              value={filterOffice}
              onChange={handleChangeFilterOffice}
            >
              <MenuItem value={0}>Sin Filtrar</MenuItem>
              {offices.offices.map((item) => {
                return (
                  <MenuItem value={item.id} key={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )
      ) : (
        <div />
      )}
    </div>
  );
}

export type FilterOfficeProps = {
  onChange: (zone: number | null, office: string | null) => void;
  offices: Office[];
  zoneId: number;
};

export const FilterOffice = (p: FilterOfficeProps) => {
  const zones = useTypedSelector((z) => z.zones);
  const dispatch = useThunkDispatch();
  const zone = p.zoneId;
  const [office, setOffice] = useState('0');

  useEffect(() => {
    if (shouldReload(zones)) {
      dispatch(allZones());
    }
  }, [zones]);

  const handleChangeFilterZona = (event: ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as number;
    setOffice('0');
    if (val !== 0) {
      p.onChange(val, null);
      dispatch(workshopOffices({ ZoneId: val }));
    } else {
      p.onChange(null, null);
    }
  };

  const handleChangeFilterOffice = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setOffice(val);
    if (val !== '0') {
      const update = p.offices.find((w) => w.id === val);
      if (update) {
        p.onChange(update.zone.id, val);
      }
      return;
    }
    p.onChange(zone, null);
  };

  const displayOffices = p.offices.length > 0 && Boolean(zone);

  return (
    <>
      <FormControl variant="standard" style={{ marginRight: '10px' }}>
        <InputLabel id="select-filter-zone-label">Zona</InputLabel>
        <Select
          labelId="select-filter-zone-label"
          id="select-filter-zone"
          value={zone}
          onChange={handleChangeFilterZona}
        >
          <MenuItem value={0}>Sin Filtrar</MenuItem>
          {zones.zones.map((item) => (
            <MenuItem value={item.id} key={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {displayOffices && (
        <FormControl style={{ marginRight: '10px' }}>
          <InputLabel id="branch-select-filter">Sucursal</InputLabel>
          <Select
            labelId="branch-select-filter"
            id="branch-select-filter-select"
            value={office}
            onChange={handleChangeFilterOffice}
          >
            <MenuItem value={0}>Sin Filtrar</MenuItem>
            {p.offices.map((item) => (
              <MenuItem value={item.id} key={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};
