enum LocalKeys {
  karthusToken = 'karthusToken',
  karthusRefresh = 'karthusRefresh',
}

export const LocalStorageHandler = {
  getToken: () => localStorage.getItem(LocalKeys.karthusToken),
  setToken: (token: string) => localStorage.setItem(LocalKeys.karthusToken, token),
  getRefreshToken: () => localStorage.getItem(LocalKeys.karthusRefresh),
  setRefreshToken: (refresh: string) =>
    localStorage.setItem(LocalKeys.karthusRefresh, refresh),
  clear: () => localStorage.clear(),
};
