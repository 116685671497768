import AppBar from '@material-ui/core/AppBar';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HelpIcon from '@material-ui/icons/Help';
import HomeIcon from '@material-ui/icons/Home';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PeopleIcon from '@material-ui/icons/People';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import ViewListIcon from '@material-ui/icons/ViewList';
import { AccessLevels } from '../../config/access.levels';
import { AccountCircle } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { Routes } from 'config/routes';
import { createStyles, makeStyles, Theme, ThemeProvider } from '@material-ui/core/styles';
import { logout } from 'redux/actions/session.actions';
import { myTheme } from '../Tools';
import { resetStore } from '../../redux/actions/types.actions';
import { useThunkDispatch, useTypedSelector } from 'hooks';
import { Environment } from 'utils/environment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    buttons: {
      width: '100%',
      backgroundColor: 'inherit',
      color: 'inherit',
    },
    myroot: {
      width: '100%',
      height: '56px',
      align: screenLeft,
    },
    sectionDesktop: {
      display: 'flex',
    },
    grow: {
      flexGrow: 1,
    },
    divider: {
      minWidth: '1000px',
    },
  }),
);

function Navigation() {
  const classes = useStyles();
  const dispatch = useThunkDispatch();
  const role = useTypedSelector((s) => s.session.user).role.accessLevel;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const onCLick = () => {
    dispatch(resetStore());
    dispatch(logout());
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Link to={Routes.profile}>
        <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      </Link>
      <Link to={Routes.login}>
        <MenuItem onClick={onCLick}>
          <ExitToAppIcon />
          Salir
        </MenuItem>
      </Link>
    </Menu>
  );

  return (
    <ThemeProvider theme={myTheme}>
      <div className={classes.divider}>
        <AppBar position="static">
          <Toolbar>
            {!Environment.hideMarks && (
              <img src={process.env.PUBLIC_URL + 'Logo.png'} height="56px" />
            )}
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <Link to={Routes.home}>
                <BottomNavigation showLabels>
                  <BottomNavigationAction label="Home" color="inherit" icon={<HomeIcon />} />
                </BottomNavigation>
              </Link>
              {role != AccessLevels.HelpDesk ? (
                <Link to={Routes.workshop}>
                  <BottomNavigation showLabels>
                    <BottomNavigationAction
                      label="Talleres"
                      color="inherit"
                      icon={<ViewListIcon />}
                    />
                  </BottomNavigation>
                </Link>
              ) : null}
              {role > AccessLevels.Coordinator ? (
                <Link to={Routes.users}>
                  <BottomNavigation showLabels>
                    <BottomNavigationAction label="Usuarios" icon={<PeopleIcon />} />
                  </BottomNavigation>
                </Link>
              ) : (
                <div />
              )}
              {role > AccessLevels.CommercialManager ? (
                <Link to={Routes.providers}>
                  <BottomNavigation showLabels>
                    <BottomNavigationAction label="Proveedores" icon={<PeopleIcon />} />
                  </BottomNavigation>
                </Link>
              ) : (
                <div />
              )}
              {role > AccessLevels.CommercialManager ? (
                <Link to={Routes.devices}>
                  <BottomNavigation showLabels>
                    <BottomNavigationAction
                      label="Dispositivos"
                      icon={<PhonelinkSetupIcon />}
                    />
                  </BottomNavigation>
                </Link>
              ) : (
                <div />
              )}
              {role > AccessLevels.CommercialManager ? (
                <Link to={Routes.workshopTypes}>
                  <BottomNavigation showLabels>
                    <BottomNavigationAction
                      label="Tipos Taller"
                      icon={<PlaylistAddCheckIcon />}
                    />
                  </BottomNavigation>
                </Link>
              ) : (
                <div />
              )}
              <Link to={Routes.help}>
                <BottomNavigation showLabels>
                  <BottomNavigationAction label="Ayuda" icon={<HelpIcon />} />
                </BottomNavigation>
              </Link>
              <BottomNavigation showLabels>
                <BottomNavigationAction
                  onClick={handleProfileMenuOpen}
                  label="Profile"
                  icon={<AccountCircle />}
                />
              </BottomNavigation>
              {renderMenu}
            </div>
          </Toolbar>
        </AppBar>
      </div>
    </ThemeProvider>
  );
}

export { Navigation };
