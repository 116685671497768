import Button from '@material-ui/core/Button';
import React from 'react';
import { Styles } from '../Tools';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Device } from '../../types/api/responses';
import Container from '@material-ui/core/Container';
import { newDevice, resetNewDevice, updateDevice } from 'redux/actions/devices.actions';
import { useThunkDispatch, useTypedSelector } from 'hooks';
import CircularProgress from '@material-ui/core/CircularProgress';

const validationSchema = yup.object({
  imei: yup
    .string()
    .trim()
    .required('IMEI es obligatorio')
    .min(16, 'El IMEI debe tener 16 caracteres')
    .max(16, 'El IMEI debe tener 16 caracteres')
    .matches(/[0-9]{16}/),
  number: yup
    .string()
    .trim()
    .min(8, 'el numero debe contener 8 caracteres')
    .max(8, 'el numero debe contener 8 caracteres')
    .matches(/[0-9]{8}/, 'Solo se permiten números'),
  manufacture: yup.string().trim().max(35, 'Maximo 35 caracteres'),
  model: yup.string().trim().max(100, 'Maximo 100 caracteres'),
});

export function NewDevice({
  device,
  isNew,
  handleClose,
}: {
  device: Device;
  isNew: boolean;
  handleClose: (loading: boolean | null, flag: boolean) => void;
}) {
  const classes = Styles();
  const devices = useTypedSelector((z) => z.devices);
  const dispatch = useThunkDispatch();

  const formik = useFormik({
    initialValues: {
      imei: device.imei,
      manufacture: device.manufacture,
      model: device.model,
      number: device.number,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (isNew) {
        handleClose(true, false);
        dispatch(
          newDevice({
            imei: values.imei.trim(),
            manufacture: values.manufacture ? values.manufacture.trim() : null,
            model: values.model ? values.model.trim() : null,
            enable: true,
            number: values.number ? values.number.trim() : null,
            assigned: null,
          }),
        );
      } else {
        handleClose(false, true);
        dispatch(
          updateDevice({
            imei: values.imei.trim(),
            manufacture: values.manufacture ? values.manufacture.trim() : null,
            model: values.model ? values.model.trim() : null,
            enable: true,
            number: values.number ? values.number.trim() : null,
            assigned: null,
          }),
        );
      }
    },
  });

  const handleAcepting = () => {
    dispatch(resetNewDevice());
    handleClose(false, false);
  };

  const saveIn = (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      className={classes.submit}
    >
      Guardar
    </Button>
  );

  const aceptIn = (
    <Button
      fullWidth
      variant="contained"
      color="primary"
      className={classes.submit}
      onClick={handleAcepting}
    >
      Aceptar
    </Button>
  );

  return (
    <Container className={classes.paper}>
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <Paper className={classes.paper}>
          {isNew ? 'Creando Dispositivo' : 'Editando Dispositivo: ' + device.imei}
        </Paper>
        {isNew ? (
          <TextField
            onChange={formik.handleChange}
            label="IMEI"
            margin="normal"
            fullWidth
            id="imei"
            name="imei"
            autoFocus
            inputProps={{ maxLength: 16 }}
            value={formik.values.imei}
            error={
              (formik.touched.imei && Boolean(formik.errors.imei)) ||
              Boolean(devices.error?.name === '1009')
            }
            helperText={
              (formik.touched.imei && formik.errors.imei) ||
              (Boolean(devices.error?.name === '1009') && 'IMEI ya registrado')
            }
            variant="outlined"
          />
        ) : (
          <div />
        )}
        <TextField
          onChange={formik.handleChange}
          label="Marca"
          margin="normal"
          inputProps={{ maxLength: 35 }}
          fullWidth
          id="manufacture"
          name="manufacture"
          value={formik.values.manufacture}
          error={formik.touched.manufacture && Boolean(formik.errors.manufacture)}
          helperText={formik.touched.manufacture && formik.errors.manufacture}
          variant="outlined"
        />
        <TextField
          onChange={formik.handleChange}
          label="modelo"
          margin="normal"
          inputProps={{ maxLength: 150 }}
          fullWidth
          id="model"
          name="model"
          value={formik.values.model}
          error={formik.touched.model && Boolean(formik.errors.model)}
          helperText={formik.touched.model && formik.errors.model}
          variant="outlined"
        />
        <TextField
          onChange={formik.handleChange}
          label="Numero"
          margin="normal"
          fullWidth
          id="number"
          inputProps={{ maxLength: 8 }}
          name="number"
          value={formik.values.number}
          error={formik.touched.number && Boolean(formik.errors.number)}
          helperText={formik.touched.number && formik.errors.number}
          variant="outlined"
        />
        {devices.error ? (
          <div>
            {devices.error?.name === '1009' ? (
              <div>{aceptIn}</div>
            ) : (
              <div>
                <p>A ocurrido un error</p>
                <p>{devices.error.message}</p>
                {aceptIn}
              </div>
            )}
          </div>
        ) : (
          <div>
            {devices.isLoading ? (
              <div>
                <CircularProgress />
              </div>
            ) : (
              <div>
                {!devices.newDevice ? (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>{saveIn}</div>
                ) : (
                  <div>
                    <p>
                      Dispositivo {devices.newDevice.imei} {isNew ? 'Creado' : 'Editado'} con
                      exito
                    </p>
                    {aceptIn}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </form>
    </Container>
  );
}
