import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export const HistoryHandler = {
  goTo: (to: string) => {
    if (history.location.pathname !== to) {
      history.push(to);
    }
  },
};
