import Container from '@material-ui/core/Container';
import notFound from 'assets/img/404error.jpg';
import { Styles } from 'components/Tools';
import { useTypedSelector } from 'hooks';

export const PageNoFound = () => {
  const classes = Styles();
  const mySession = useTypedSelector((s) => s.session);

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <p>No se ha podido encontrar la pagina solicitada</p>
        <div className={classes.avatar}>
          <img src={notFound} id="outlined-basic" alt="not found" />
        </div>
        {mySession.error?.name && <p>Error {mySession.error.message}</p>}
      </div>
    </Container>
  );
};
