import Container from '@material-ui/core/Container';
import { Environment } from '../../utils';
import { Styles } from 'components/Tools';
//import Typography from '@material-ui/core/Typography';

/*function Helppy() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Link Documentacion '}
    </Typography>
  );
}*/

export const Help = () => {
  const classes = Styles();

  return (
    <Container className={classes.divider} component="main" maxWidth="xs">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          height: '10vh',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <a href={Environment.baseUrl + 'images/manual.pdf'}>Manual de Usuario</a>
      </div>
    </Container>
  );
};
