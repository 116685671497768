import { initProvidersState } from 'redux/initial.state';
import produce from 'immer';
import { ProvidersState } from 'types/redux/state/providers.state';
import { setLoadable } from 'utils/state.handlers';
import { ProvidersAction, ProvidersActions } from 'types/redux/actions/providers.action';

export const providersReducer = (
  state = initProvidersState(),
  action: ProvidersAction,
): ProvidersState =>
  produce(state, (clone) => {
    switch (action.type) {
      case ProvidersActions.providersUpdate:
        setLoadable(clone, action.isLoading, action.error);
        break;
      case ProvidersActions.providersSetValue:
        setLoadable(clone, false);
        clone.providers = action.providers;
        break;
      case ProvidersActions.providersSetFilter:
        setLoadable(clone, false);
        clone.filter = action.filter;
        break;
      case ProvidersActions.providersSetNew:
        setLoadable(clone, false);
        clone.newProvider = action.newProvider;
        break;
      case ProvidersActions.providersSetUpdate:
        setLoadable(clone, false);
        const update = clone.providers.providers.find((w) => w.id === action.newProvider.id);
        if (update) {
          update.name = action.newProvider.name;
          update.active = action.newProvider.active;
        }
        break;
    }
  });
