import { Provider } from './provider';
import { WorkshopType } from './workshopTypes';

export type Department = {
  id: string;
  name: string;
};

export type WorkshopKind = 'Matrix' | 'Branch';

export type Workshop = {
  id: number;
  name: string;
  latitude: number;
  longitude: number;
  pictureUrl: string;
  municipio: {
    id: string;
    name: string;
    department: Department;
  } | null;
  address: string | null; //direccion taller
  workShopType: WorkshopType | null;
  branchOffice: {
    id: string;
    name: string; //sucursal
    zone: {
      id: number;
      name: string; //zona
    };
  };
  mechanics: number | null;
  capacity: number | null;
  provider: Provider | null;
  buyer: string | null;
  buyerLastName: string | null;
  buyerCellphone: string | null; //celular comprador
  ownerCellphone: string | null; //celular propietario
  email: string | null;
  potential: string;
  tools: boolean;
  diagnostics: boolean;
  status: string;
  lastSync: string | null;
  //license: string | null; //carnet | cuentaTA => TA
  clientAccount: string | null; //cliente
  //event: string; //evento???? ***no es
  seller: string | null;
  program: string | null; // program TA
  weeklyCars: number;
  matrix: boolean;
  kind: WorkshopKind | null;
};

export function getWorkshopStatus(status: string) {
  switch (status) {
    case 'Created':
      return { color: 'white', type: 'Taller', fix: 'inerit' };
    case 'Migrated':
      return { color: 'orange', type: 'Taller Migrado', fix: 'white' };
    case 'Profiled':
      return { color: 'gray', type: 'Taller Perfilado', fix: 'white' };
    case 'ProfiledCompleted':
      return { color: 'black', type: 'Taller Perfilado Completo', fix: 'white' };
    case 'TaCreated':
      return { color: 'red', type: 'TA', fix: 'white' };
    case 'TaProfiled':
      return { color: 'yellow', type: 'TA Perfilado', fix: 'inerit' };
    case 'TaProfiledCompleted':
      return { color: 'green', type: 'TA Perfilado Completo', fix: 'white' };
  }
}

export type FilterWorkshop = {
  NameContains: string | null;
  Status: string | null;
  ZoneId: number | null;
  OfficeId: string | null;
  DaysBefore: number | null;
  Account: string | null;
};
