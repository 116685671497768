import { initProfileState } from 'redux/initial.state';
import produce from 'immer';
import { ProfileState } from 'types/redux/state/profile.state';
import { ProfileAction, ProfileActions } from 'types/redux/actions/profile.action';
import { setLoadable, resetLoadable } from 'utils/state.handlers';

export const profileReducer = (
  state = initProfileState(),
  action: ProfileAction,
): ProfileState =>
  produce(state, (clone) => {
    switch (action.type) {
      case ProfileActions.profileUpdate:
        setLoadable(clone, action.isLoading, action.error);
        break;
      case ProfileActions.profileSetValue:
        setLoadable(clone, false);
        clone.profile = action.profile ? action.profile : null;
        break;
      case ProfileActions.resetProfile:
        resetLoadable(clone, false);
        clone.profile = action.profile ? action.profile : null;
        break;
    }
  });
