import { Action } from 'redux';
import { Zone, Metadata } from 'types/api/responses';

export enum ZonesActions {
  zonesUpdate = 'session/zones/update',
  zonesSetValue = 'session/zones/set',
}

type ZonesUpdate = {
  isLoading: boolean;
  error?: Error;
} & Action<typeof ZonesActions.zonesUpdate>;

type ZonesSetValue = {
  zones: Zone[];
  meta: Metadata;
} & Action<typeof ZonesActions.zonesSetValue>;

export type ZonesAction = ZonesUpdate | ZonesSetValue;
