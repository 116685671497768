import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import { Environment } from 'utils/environment';
import { RolesList } from '../Tools';
import { Styles } from 'components/Tools';
import { useTypedSelector } from 'hooks';

export const Home = () => {
  const classes = Styles();
  const mySession = useTypedSelector((s) => s.session);

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <div className={classes.avatar}>
          {!Environment.hideMarks && (
            <img
              src={process.env.PUBLIC_URL + 'Logo2.png'}
              id="outlined-basic"
              width={'100%'}
              alt=""
            />
          )}
        </div>
        {mySession.isLoading && <CircularProgress />}
        <p>
          Bienvenido {mySession.user.name} {mySession.user.lastName}
        </p>
        <p>
          Su rol actual es de {RolesList.find((w) => w.id === mySession.user.role.name)?.name}
        </p>

        {mySession.error?.name && <p>Error {mySession.error.message}</p>}
      </div>
    </Container>
  );
};
