import { AccessLevels } from '../config/access.levels';
import { Help } from 'components/Help/Help';
import { history } from 'utils/history.handler';
import { Home } from 'components/Home';
import { ListDevice } from 'components/ListDevice';
import { ListProvider } from 'components/ListProvider';
import { ListTaller } from 'components/ListTaller';
import { ListUser } from 'components/ListUser';
import { ListWorkshopType } from 'components/ListWorkshopType';
import { Login } from 'components/Login';
import { PageNoFound } from 'components/PageNoFound';
import { PersistGate } from 'redux-persist/integration/react';
import { Profile } from 'components/Profile';
import { Provider } from 'react-redux';
import React from 'react';
import { Routes } from 'config/routes';
import { AppRoute, LoginAcces, AppRouteList } from 'components/appRoute/AppRoute';
import { karthusPersists, karthusStore } from 'redux/store';
import { Route, Router, Switch } from 'react-router-dom';
import 'index.css';

export const App = () => {
  return (
    <Provider store={karthusStore}>
      <PersistGate loading={null} persistor={karthusPersists}>
        <Router history={history}>
          <Switch>
            <LoginAcces exact path={Routes.login}>
              <Login />
            </LoginAcces>
            <AppRoute exact path={'/'} accessLevel={AccessLevels.Coordinator}>
              <Home />
            </AppRoute>
            <AppRoute exact path={Routes.home} accessLevel={AccessLevels.Coordinator}>
              <Home />
            </AppRoute>
            <AppRouteList
              exact
              path={Routes.workshop}
              accessLevel={[
                AccessLevels.Coordinator,
                AccessLevels.CommercialManager,
                AccessLevels.Admin,
              ]}
            >
              <ListTaller />
            </AppRouteList>
            <AppRoute exact path={Routes.users} accessLevel={AccessLevels.CommercialManager}>
              <ListUser />
            </AppRoute>
            <AppRoute exact path={Routes.providers} accessLevel={AccessLevels.HelpDesk}>
              <ListProvider />
            </AppRoute>
            <AppRoute exact path={Routes.devices} accessLevel={AccessLevels.HelpDesk}>
              <ListDevice />
            </AppRoute>
            <AppRoute exact path={Routes.workshopTypes} accessLevel={AccessLevels.HelpDesk}>
              <ListWorkshopType />
            </AppRoute>
            <AppRoute exact path={Routes.help} accessLevel={AccessLevels.Coordinator}>
              <Help />
            </AppRoute>
            <AppRoute exact path={Routes.profile} accessLevel={AccessLevels.Coordinator}>
              <Profile />
            </AppRoute>
            <Route>
              <PageNoFound />
            </Route>
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
};
