import { AccessLevels } from '../../config/access.levels';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { debounce } from 'lodash';
import { FilterOffice } from '../ListTaller/FilterZoneOffice';
import { FilterWorkshop } from 'types/api/responses/workshop';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Pager } from 'types/api/request';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { workshopValues } from 'utils';
import React, { useState, KeyboardEvent, ChangeEvent, useCallback } from 'react';
import { Styles, WorkshopTypesList } from '../Tools';
import { useTypedSelector, useThunkDispatch } from '../../hooks';
import { workshopAll, generateReport } from 'redux/actions/workshop.actions';

const params: Pager = {
  size: 10,
  page: 1,
} as const;

const rex = /\d{7}/;

export const Filters = () => {
  const { filter, error, isLoading, branchOffices } = useTypedSelector((z) => z.workshop);
  const myRole = useTypedSelector((s) => s.session.user.role.accessLevel);
  const dispatch = useThunkDispatch();
  const [fState, setFilter] = useState<FilterWorkshop>(filter);
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState('');
  const classes = Styles();
  const isCoordinator = myRole !== AccessLevels.HelpDesk;

  const onChangeFilter = <T extends keyof FilterWorkshop>(
    key: T,
    value: FilterWorkshop[T],
  ) => {
    setFilter({ ...fState, [key]: value });
  };

  const handleSearch = (s: FilterWorkshop): void => {
    if (s.Account) {
      const valid = rex.test(s.Account);
      if (!valid) {
        setValidation('Debe tener exactamente 7 digitos');
        return;
      }
    }
    setValidation('');
    dispatch(workshopAll({ params, filterN: s }));
  };

  const debouncedAccount = useCallback(debounce(handleSearch, 500), [dispatch]);

  const onAccount = (evt: ChangeEvent<HTMLInputElement>) => {
    onChangeFilter('Account', evt.target.value);
    debouncedAccount.cancel();
    debouncedAccount({ ...fState, Account: evt.target.value });
  };

  const onName = (evt: ChangeEvent<HTMLInputElement>) => {
    onChangeFilter('NameContains', evt.target.value);
    debouncedAccount.cancel();
    debouncedAccount({ ...fState, NameContains: evt.target.value });
  };

  const onKeyPress = (evt: KeyboardEvent<unknown>) => {
    if (evt.key === 'Enter') {
      evt.preventDefault();
      debouncedAccount.cancel();
      handleSearch(fState);
    }
  };

  function officeChange(ZoneId: number | null, OfficeId: string | null) {
    const filterN = { ...fState, ZoneId, OfficeId };
    setFilter(filterN);
    dispatch(workshopAll({ params, filterN }));
  }

  const handleResetSearch = () => {
    const filterN = {
      NameContains: null,
      Status: null,
      ZoneId: null,
      OfficeId: null,
      DaysBefore: null,
      Account: null,
    };

    setFilter(filterN);
    setValidation('');
    dispatch(workshopAll({ params, filterN }));
  };

  const exportExcel = () => {
    setLoading(true);
    generateReport().finally(() => setLoading(false));
  };

  return (
    <Paper className={`${classes.paper} ${classes.flexWrap}`}>
      <TextField
        id="Account"
        label="Cuenta"
        error={Boolean(error) || Boolean(validation)}
        helperText={validation || error?.message}
        value={fState.Account || ''}
        onChange={onAccount}
        onKeyPress={onKeyPress}
        variant="outlined"
        inputProps={{ maxLength: workshopValues.clientAccount, readOnly: isLoading }}
      />
      <TextField
        id="name"
        label="Nombre Comercial"
        inputProps={{ maxLength: workshopValues.name, readOnly: isLoading }}
        value={fState.NameContains || ''}
        onChange={onName}
        onKeyPress={onKeyPress}
        variant="outlined"
      />
      <FormControl style={{ marginRight: '10px' }}>
        <InputLabel id="state-id-label">Estado</InputLabel>
        <Select
          labelId="state-id-label"
          id="state-id"
          value={fState.Status ?? '0'}
          onChange={(evt) => onChangeFilter('Status', evt.target.value as string)}
        >
          <MenuItem value="0">Sin Filtrar</MenuItem>
          {WorkshopTypesList.map((item) => {
            return (
              <MenuItem value={item.number} key={item.number}>
                <Paper
                  className={classes.paper}
                  style={{ backgroundColor: item.color, color: item.fix }}
                >
                  {item.type}
                </Paper>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FilterOffice
        offices={branchOffices.offices}
        onChange={officeChange}
        zoneId={fState.ZoneId || 0}
      />
      <FormControl style={{ marginRight: '10px' }}>
        <InputLabel id="days-controlled-open-select-label">Ultima modificacion</InputLabel>
        <Select
          labelId="days-controlled-open-select-label"
          id="days-controlled-open-select"
          value={fState.DaysBefore ?? 0}
          onChange={(evt) =>
            onChangeFilter('DaysBefore', (evt.target.value as number) || null)
          }
        >
          <MenuItem value={0}>Sin Filtrar</MenuItem>
          <MenuItem value={5}>ultimos 5 dias</MenuItem>
          <MenuItem value={15}>ultimos 15 dias</MenuItem>
          <MenuItem value={30}>ultimos 30 dias</MenuItem>
        </Select>
      </FormControl>
      <div style={{ width: '100%' }}>
        <Button
          variant="outlined"
          onClick={handleResetSearch}
          className={classes.submit}
          style={{ color: 'Blue', marginRight: '5px', height: 'fit-content' }}
        >
          Reset
        </Button>
        <Button
          variant="outlined"
          onClick={() => handleSearch(fState)}
          className={classes.submit}
          style={{ color: 'Blue', marginRight: '5px', height: 'fit-content' }}
        >
          Update
        </Button>
        {isCoordinator && (
          <Button
            variant="outlined"
            className={classes.submit}
            disabled={loading}
            style={{ color: 'Blue', marginRight: '5px', height: 'fit-content' }}
            onClick={exportExcel}
          >
            Generar Excel {loading && <CircularProgress />}
          </Button>
        )}
      </div>
    </Paper>
  );
};
