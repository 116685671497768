import { DevicesState } from 'types/redux/state/devices.state';
import { WorkshopTypesState } from 'types/redux/state/workshopTypes.state';
import { ZonesState } from 'types/redux/state/zones.state';
import {
  ProvidersState,
  UsersState,
  ProfileState,
  SessionState,
  WorkshopState,
} from 'types/redux/state';

export const initWorkshopTypesState = (): WorkshopTypesState => ({
  workshopTypes: {
    workshopTypes: [],
    meta: null,
  },
  filter: {
    NameContains: null,
    Status: null,
  },
  newWorkshopType: null,
  isLoading: false,
  isDirty: false,
});

export const initProfileState = (): ProfileState => ({
  profile: null,
  isLoading: false,
  isDirty: false,
});

export const initDevicesState = (): DevicesState => ({
  devices: {
    devices: [],
    meta: null,
  },
  filter: {
    Status: null,
    ImeiSW: null,
    NumberSW: null,
  },
  newDevice: null,
  isLoading: false,
  isDirty: false,
});

export const initProvidersState = (): ProvidersState => ({
  providers: {
    providers: [],
    meta: null,
  },
  filter: {
    NameContains: null,
    Status: null,
  },
  newProvider: null,
  isLoading: false,
  isDirty: false,
});

export const initZonesState = (): ZonesState => ({
  zones: [],
  meta: null,
  isLoading: false,
  isDirty: false,
});

export const initWorkshopState = (): WorkshopState => ({
  workshops: {
    workshops: [],
    meta: null,
  },
  branchOffices: {
    offices: [],
    meta: null,
  },
  filter: {
    NameContains: null,
    Status: null,
    ZoneId: null,
    OfficeId: null,
    DaysBefore: null,
    Account: null,
  },
  isLoading: false,
  isDirty: false,
});

export const initUsersState = (): UsersState => ({
  users: {
    users: [],
    meta: null,
  },
  filter: {
    NameContains: null,
    Status: null,
    ZoneId: null,
    OfficeId: null,
    //Device: null,
  },
  branchOffices: {
    offices: [],
    meta: null,
  },
  newUser: null,
  userDevice: null,
  isLoading: false,
  isDirty: false,
});

export const initSessionState = (): SessionState => ({
  device: {
    enable: false,
    imei: '',
    manufacture: null,
    model: null,
    number: null,
    assigned: null,
  },
  user: {
    lastName: '',
    name: '',
    userName: '',
    active: true,
    role: {
      name: '',
      id: 0,
      accessLevel: 0,
    },
    branchOffice: null,
  },
  isDirty: false,
  isLoading: false,
});
