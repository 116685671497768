import React from 'react';
import { useThunkDispatch, useTypedSelector } from 'hooks';
import { Styles, getModalStyle } from '../Tools';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { WorkshopType } from '../../types/api/responses';
import {
  newWorkshopType,
  resetNewWorkshopType,
  //resetNewWorkshopType,
  updateWorkshopType,
} from 'redux/actions/workshopTypes.actions';
import Container from '@material-ui/core/Container';

import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .min(3, 'username should be of minimum 3 characters length')
    .max(50, 'username should be of maximun 50 characters length')
    .required('username is required'),
});

export function NewWorkshopType({
  workshopType,
  isNew,
  handleClose,
  updateList,
}: {
  workshopType: WorkshopType;
  isNew: boolean;
  handleClose: (loading: boolean | null, flag: boolean) => void;
  updateList: () => void;
}) {
  const classes = Styles();
  //const [modalStyle] = React.useState(getModalStyle(40, 20));
  const dispatch = useThunkDispatch();
  const workshopTypes = useTypedSelector((u) => u.workshopTypes);

  //const [editUser, setEditUser] = React.useState(true);
  //const [accesControl, seAccesControl] = React.useState(0);

  const formik = useFormik({
    initialValues: {
      name: workshopType.name,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (isNew) {
        handleClose(true, false);
        dispatch(
          newWorkshopType(
            {
              id: 0,
              name: values.name.trim(),
              active: true,
              external: false,
            },
            updateList,
          ),
        );
      } else {
        handleClose(false, true);
        dispatch(
          updateWorkshopType({
            id: workshopType.id,
            name: formik.values.name.trim(),
            active: workshopType.active,
            external: workshopType.external,
          }),
        );
      }
    },
  });

  const handleAcepting = () => {
    dispatch(resetNewWorkshopType());
    handleClose(false, false);
  };

  const saveIn = (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      className={classes.submit}
    >
      Guardar
    </Button>
  );

  const aceptIn = (
    <Button
      fullWidth
      variant="contained"
      color="primary"
      className={classes.submit}
      onClick={handleAcepting}
    >
      Aceptar
    </Button>
  );

  const handleExit = () => {
    //dispatch(resetNewWorkshopType());
    handleClose(null, false);
  };

  return (
    <Container className={classes.paper}>
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        {!isNew ? (
          <Paper className={classes.paper}>Editando Tipo de Taller: {workshopType.name}</Paper>
        ) : (
          <Paper className={classes.paper}>Creando Tipo de Taller</Paper>
        )}

        <TextField
          onChange={formik.handleChange}
          label="nombre"
          margin="normal"
          inputProps={{ maxLength: 50 }}
          required
          fullWidth
          id="name"
          name="name"
          autoFocus
          value={formik.values.name}
          error={
            (formik.touched.name && Boolean(formik.errors.name)) ||
            Boolean(workshopTypes.error?.name === '1009')
          }
          helperText={
            (formik.touched.name && formik.errors.name) ||
            (Boolean(workshopTypes.error?.name === '1009') && 'Nombre ya registrado')
          }
          variant="outlined"
        />
        {workshopTypes.error ? (
          <div>
            {workshopTypes.error?.name === '1009' ? (
              <div>{aceptIn}</div>
            ) : (
              <div>
                <p>A ocurrido un error</p>
                <p>{workshopTypes.error.message}</p>
                {aceptIn}
              </div>
            )}
          </div>
        ) : (
          <div>
            {workshopTypes.isLoading ? (
              <div>
                <CircularProgress />
              </div>
            ) : (
              <div>
                {!workshopTypes.newWorkshopType ? (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>{saveIn}</div>
                ) : (
                  <div>
                    <p>
                      Dispositivo {workshopTypes.newWorkshopType.name}
                      {isNew ? 'Creado' : 'Editado'} con exito
                    </p>
                    {aceptIn}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </form>
    </Container>
  );
}
