import classnames from 'classnames';
import React from 'react';
import { FormLabel, Typography } from '@material-ui/core';

export type FormFieldProps = {
  value?: string | number;
  label: string;
  as400?: boolean;
  className?: string;
};

export const ReadOnlyField = ({ value, label, as400, className }: FormFieldProps) => {
  const classes = classnames('form-read-only', className);
  return (
    <div className={classes}>
      <FormLabel>
        {label}
        {as400 && ' (AS400)'}
      </FormLabel>
      <Typography className="form-value">{value}</Typography>
    </div>
  );
};
