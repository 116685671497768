import { DateTime } from 'luxon';

export * from './environment';
export * from './guards';
export * from './history.handler';

export const getVerification = (status?: string | null): string => {
  switch (status) {
    case 'NotVerified':
      return 'NO VERIFICADO';
    case 'VerifiedPotential':
      return 'VERIFICADO CON POTENCIAL';
    case 'Verified':
      return 'VERIFICADO SIN POTENCIAL';
    default:
      return '';
  }
};

export const toIsoDate = (date: string | null): string => {
  return DateTime.fromISO(date || '')
    .toISODate()
    .toString();
};

export const workshopValues = {
  address: 105,
  name: 35,
  email: 60,
  clientAccount: 7,
  buyer: 40,
  mechanics: {
    min: 1,
  },
  phone: 8,
} as const;
