import { ThemeProvider } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import React, { useEffect } from 'react';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { allProviders, updateProvider } from 'redux/actions/providers.actions';

import { useThunkDispatch, useTypedSelector } from 'hooks';

import { myTheme, Styles, getModalStyle, StyledTableCell, StyledTableRow } from '../Tools';
import { NewProvider } from './NewProvider';
import Modal from '@material-ui/core/Modal';
import { Provider } from '../../types/api/responses';
import { shouldReload } from '../../utils/state.handlers';
import Pagination from '@material-ui/lab/Pagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert } from '../Tools';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import AddBoxIcon from '@material-ui/icons/AddBox';

const SIZE = 10,
  INITPAGE = 1;

function SearchDetails() {
  const classes = Styles();

  const providers = useTypedSelector((z) => z.providers);
  const dispatch = useThunkDispatch();

  const [nameSearch, setNameSearch] = React.useState(
    providers.filter.NameContains ? providers.filter.NameContains : '',
  );

  const [openStatus, setOpenStatus] = React.useState(false);
  const [theStatus, setTheStatus] = React.useState(
    providers.filter.Status != null ? providers.filter.Status : '0',
  );

  const handleResetSearch = () => {
    setNameSearch('');
    setTheStatus('0');
    dispatch(
      allProviders(
        { page: INITPAGE, size: SIZE },
        {
          NameContains: null,
          Status: null,
        },
      ),
    );
  };

  //evento de cambio en nombre a buscar
  const handleChangeNameSearch = (event: React.ChangeEvent<{ value: unknown }>) => {
    setNameSearch(event.target.value as string);
  };

  //evento de busqueda segun nombre
  const handleSearch = () => {
    //alert(nameSearch + 'F');
    dispatch(
      allProviders(
        { page: INITPAGE, size: SIZE },
        {
          NameContains: nameSearch,
          Status: providers.filter.Status,
        },
      ),
    );
  };

  const handleOpenStatus = () => {
    setOpenStatus(true);
  };

  const handleClosedStatus = () => {
    setOpenStatus(false);
  };

  const handleChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;
    setTheStatus(value);
    dispatch(
      allProviders(
        { page: INITPAGE, size: SIZE },
        {
          NameContains: providers.filter.NameContains,
          Status: value === '0' ? null : value,
        },
      ),
    );
  };

  return (
    <form className={classes.rootForm} noValidate autoComplete="off">
      <Paper className={classes.paper}>
        <div style={{ display: 'inline-flex', alignItems: 'center', marginRight: '10px' }}>
          <TextField
            id="outlined-read-only-input"
            label="Nombre Proveedor"
            inputProps={{ maxLength: 50 }}
            value={nameSearch}
            onChange={handleChangeNameSearch}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                ev.preventDefault();
                handleSearch();
              }
            }}
            variant="outlined"
            required
          />
          <FormControl
            variant="outlined"
            style={{ marginLeft: '10px' }}
            className={classes.formControl}
          >
            <InputLabel id="select-filter-rol">Estado</InputLabel>
            <Select
              labelId="select-filter-rol"
              id="filter-controlled-open-select-rol"
              open={openStatus}
              onClose={handleClosedStatus}
              onOpen={handleOpenStatus}
              value={theStatus}
              onChange={handleChangeStatus}
            >
              <MenuItem value={'0'}>Todos</MenuItem>
              <MenuItem value={'Disabled'}>Inactivos</MenuItem>
              <MenuItem value={'Active'}>Activos</MenuItem>
            </Select>
          </FormControl>
          {providers.isLoading ? (
            <div>
              <CircularProgress />
            </div>
          ) : (
            <div />
          )}
          <Button
            variant="outlined"
            onClick={handleResetSearch}
            className={classes.submit}
            style={{ color: 'Blue', marginRight: '5px' }}
          >
            Reset
          </Button>
        </div>
      </Paper>
    </form>
  );
}

function ListProvider() {
  const classes = Styles();
  const providers = useTypedSelector((u) => u.providers);

  //modal
  const [modalStyle] = React.useState(getModalStyle(40, 20));
  const [modalActive, setmodalActive] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [thePorvider, setTheProvider] = React.useState({ id: 0, name: '', active: false });
  const dispatch = useThunkDispatch();
  const [isNew, setIsNew] = React.useState(true);

  useEffect(() => {
    if (shouldReload(providers)) {
      dispatch(allProviders({ size: SIZE, page: page }, { NameContains: null, Status: null }));
    }
  }, [providers]);

  const handleOpenModal = () => {
    setOpenModal(true);
    setmodalActive(false);
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    dispatch(allProviders({ size: SIZE, page: newPage }, providers.filter));
    setPage(newPage);
  };

  const updateList = () => {
    dispatch(allProviders({ size: SIZE, page: page }, providers.filter));
  };

  const handleCloseModal = (flag: boolean) => {
    setOpenModal(false);
    if (flag) {
      setmodalActive(true);
    }
  };

  const handleChangeModal = ({ provider, flag }: { provider: Provider; flag: boolean }) => {
    setTheProvider(provider);
    setIsNew(flag);
    handleOpenModal();
  };

  const handleDesactive = ({ active, item }: { active: boolean; item: Provider }) => {
    setmodalActive(false);
    setmodalActive(true);
    dispatch(
      updateProvider({
        id: item.id,
        name: item.name,
        active: active,
      }),
    );
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setmodalActive(false);
  };

  return (
    <ThemeProvider theme={myTheme}>
      <Modal
        open={openModal}
        onClose={() => {
          handleCloseModal(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paperModalNewProvider}>
          <div id={'modal-container'} style={{ height: '100%' }}>
            <NewProvider
              provider={thePorvider}
              isNew={isNew}
              handleClose={handleCloseModal}
              updateList={updateList}
            />
          </div>
        </div>
      </Modal>
      <Snackbar open={modalActive} autoHideDuration={15000} onClose={handleClose}>
        {providers.isLoading ? (
          <Alert onClose={handleClose} severity="info">
            <div>Editando Proveedor...</div>
          </Alert>
        ) : (
          <div>
            {providers.error ? (
              <Alert onClose={handleClose} severity="error">
                {providers.error?.name === '1009' ? (
                  <div>Nombre ya registrado</div>
                ) : (
                  <div>ocurrio un error {providers.error.message}</div>
                )}
              </Alert>
            ) : (
              <Alert onClose={handleClose} severity="success">
                <div>Se edito con exito el Proveedor</div>
              </Alert>
            )}
          </div>
        )}
      </Snackbar>
      <div className={classes.divider}>
        <Paper className={classes.paper}>
          <div className={classes.flexWrap}>
            <SearchDetails />
            <BottomNavigation
              showLabels
              onClick={() => {
                handleChangeModal({
                  provider: {
                    id: 0,
                    name: '',
                    active: true,
                  },
                  flag: true,
                });
              }}
            >
              <BottomNavigationAction
                label="agregar"
                icon={<AddBoxIcon />}
                style={{ color: 'green' }}
              />
            </BottomNavigation>
          </div>
        </Paper>
        <div style={{ width: '100%', display: 'flex', alignContent: 'center' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <TableContainer style={{ maxHeight: '75vh', maxWidth: '60vw', minWidth: '700px' }}>
              <Table className={classes.table} stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Nombre Proveedor</StyledTableCell>
                    <StyledTableCell align="center">Estado</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {providers.providers.providers.map((item) => {
                    return (
                      <StyledTableRow key={item.id}>
                        <StyledTableCell component="th" scope="row">
                          {item.name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <div className={classes.flexRowCenter}>
                            <BottomNavigation
                              showLabels
                              onClick={() => {
                                handleChangeModal({ provider: item, flag: false });
                              }}
                              className={classes.buttons}
                            >
                              <BottomNavigationAction
                                label="Editar"
                                icon={<EditIcon />}
                                style={{ color: 'blue', margin: '0px' }}
                              />
                            </BottomNavigation>
                            {providers.isLoading ? (
                              <div>
                                <CircularProgress />
                              </div>
                            ) : (
                              <div>
                                {item.active ? (
                                  <BottomNavigation showLabels className={classes.buttons}>
                                    <BottomNavigationAction
                                      label="bloquear"
                                      onClick={() => {
                                        handleDesactive({ active: false, item: item });
                                      }}
                                      icon={<LockIcon />}
                                      style={{ color: 'red' }}
                                    />
                                  </BottomNavigation>
                                ) : (
                                  <BottomNavigation showLabels className={classes.buttons}>
                                    <BottomNavigationAction
                                      label="desbloquear"
                                      onClick={() => {
                                        handleDesactive({ active: true, item: item });
                                      }}
                                      icon={<LockOpenIcon />}
                                      style={{ color: 'blue' }}
                                    />
                                  </BottomNavigation>
                                )}
                              </div>
                            )}
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {providers.isLoading ? (
                <CircularProgress />
              ) : (
                <Pagination
                  count={providers.providers.meta?.pages}
                  variant="outlined"
                  shape="rounded"
                  page={page}
                  onChange={handleChangePage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export { ListProvider };
