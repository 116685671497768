import { DevicesState } from 'types/redux/state/devices.state';
import { initDevicesState } from 'redux/initial.state';
import produce from 'immer';
import { setLoadable } from 'utils/state.handlers';
import { DevicesAction, DevicesActions } from 'types/redux/actions/devices.action';

export const devicesReducer = (
  state = initDevicesState(),
  action: DevicesAction,
): DevicesState =>
  produce(state, (clone) => {
    switch (action.type) {
      case DevicesActions.devicesUpdate:
        setLoadable(clone, action.isLoading, action.error);
        break;
      case DevicesActions.devicesSetValue:
        setLoadable(clone, false);
        clone.devices = action.devices;
        break;
      case DevicesActions.devicesNewValue:
        setLoadable(clone, false);
        clone.newDevice = action.newDevice;
        break;
      case DevicesActions.devicesSetFilter:
        setLoadable(clone, false);
        clone.filter = action.filter;
        break;
      case DevicesActions.devicesNewValueUpdate:
        setLoadable(clone, false);
        const update = clone.devices.devices.find((w) => w.imei === action.newDevice.imei);
        if (update) {
          update.imei = action.newDevice.imei;
          update.number = action.newDevice.number;
          update.manufacture = action.newDevice.manufacture;
          update.model = action.newDevice.model;
          update.enable = action.newDevice.enable;
        }
        break;
    }
  });
